import React, { FC } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetProjectStagesParams, GetProjectStagesStorage } from '@vkph/common/store/tasks';
import { useElementSize } from '@vkph/ui/hooks';

import { getDaySizeInYear } from '../helpers/gantt-date-helper';
import { GanttCompactContent } from './content/GanttCompactContent';
import { GanttContentHeader } from './header/GanttContentHeader';

export interface DisplayOptions {
  year: NonNullable<GetProjectStagesParams['year']>;
  daySize: number;
  isSmall: boolean;
}

export enum GanttIntervalType {
  Year,
  Years,
  Months,
  Weeks,
}

const SMALL_HEADER_WIDTH = 1100;

interface GanttCompactProps {
  year: DisplayOptions['year'];
  storage: GetProjectStagesStorage['storage'];
}

export const GanttCompact: FC<GanttCompactProps> = (props) => {
  const { storage, year } = props;
  const { data: projectStagesData, loading: isProjectStagesLoading } = useAbstractStorage(storage);

  const { ref, width } = useElementSize([isProjectStagesLoading, projectStagesData]);
  const daySize = getDaySizeInYear(year, width);
  const isSmall = Boolean(width <= SMALL_HEADER_WIDTH);

  const displayOptions: DisplayOptions = {
    year,
    daySize,
    isSmall,
  } as const;

  return (
    <div ref={ref} style={{ overflow: 'hidden', width: '100%' }}>
      <GanttContentHeader displayOptions={displayOptions} />
      <GanttCompactContent displayOptions={displayOptions} stages={projectStagesData} />
    </div>
  );
};
