import { UiIcon, UiSpinner, UiTypography } from '@vkph/ui';
import classnames from 'classnames';
import React, { useEffect } from 'react';

import SuccessSvg from '@vkph/ui/svg/success.svg';

import styles from './SavedDraftNotify.scss';

const TIMEOUT_DELAY = 4000;

const DraftSavingIcon = <UiSpinner spinning />;
const DraftSavedIcon = (
  <UiIcon className={styles.savedDraft__icon} width={20} height={20} component={SuccessSvg} />
);

const DRAFT_SAVED_TEXT = 'Черновик сохранен';
const DRAFT_SAVING_TEXT = 'Сохранение черновика...';

type Props = {
  isVisible: boolean;
  isPending: boolean;
  onVisibilityChange?: (isVisible: boolean) => void;
  className?: string;
  draftSavedText?: string;
  draftSavingText?: string;
};

export const SavedDraftNotify: React.FC<Props> = (props) => {
  const {
    isVisible,
    onVisibilityChange,
    isPending,
    className,
    draftSavedText = DRAFT_SAVED_TEXT,
    draftSavingText = DRAFT_SAVING_TEXT,
  } = props;

  useEffect(() => {
    let fadeTimer: number | null = null;

    if (isVisible && !isPending && onVisibilityChange) {
      fadeTimer = window.setTimeout(() => {
        onVisibilityChange(false);
      }, TIMEOUT_DELAY);
    }

    return () => {
      if (fadeTimer) {
        window.clearTimeout(fadeTimer);
      }
    };
  }, [isVisible, isPending]);

  return (
    <div
      className={classnames(styles.savedDraft, className, {
        [styles.savedDraft_appeared]: isVisible || isPending,
      })}
    >
      {isPending ? DraftSavingIcon : DraftSavedIcon}
      <UiTypography.Text className={styles.savedDraft__text}>
        {isPending ? draftSavingText : draftSavedText}
      </UiTypography.Text>
    </div>
  );
};
