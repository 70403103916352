import { UiIcon, UiIconProps } from '@vkph/ui';
import React, { FC } from 'react';

import { variables } from '@vkph/ui/providers/theme';

export const EmployeeContactsLinkIcon: FC<Pick<UiIconProps, 'component' | 'height'>> = (props) => {
  const { component, height = 20 } = props;

  return <UiIcon component={component} color={variables.themePalette.colorIcon} width={20} height={height} />;
};
