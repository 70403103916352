import { UiSpace } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

import { NotificationStatuses } from '@vkph/common/types/models';

import styles from './Notification.scss';
import {
  NotificationBody,
  NotificationBodyProps as NotificationBodyPropsType,
} from './body/NotificationBody';
import { NotificationBodyFooterProps as NotificationBodyFooterPropsType } from './body/footer/NotificationBodyFooter';
import {
  NotificationHeader,
  NotificationHeaderProps as NotificationHeaderPropsType,
} from './header/NotificationHeader';

export type NotificationHeaderProps = NotificationHeaderPropsType;
export type NotificationBodyProps = NotificationBodyPropsType;
export type NotificationBodyFooterProps = NotificationBodyFooterPropsType;

export type NotificationComposition = {
  Body: typeof NotificationBody;
  Header: typeof NotificationHeader;
};

export type NotificationProps = {
  status: NotificationStatuses;
};

export const Notification: FC<PropsWithChildren<NotificationProps>> & NotificationComposition = (props) => {
  const { status, children } = props;

  return (
    <UiSpace
      direction="vertical"
      size={4}
      className={classNames(styles.notification, {
        [styles.notification_unread]: status === NotificationStatuses.Unread,
      })}
    >
      {children}
    </UiSpace>
  );
};

Notification.Header = NotificationHeader;
Notification.Body = NotificationBody;
