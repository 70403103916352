import { UiCard, UiCol, UiRow, UiSkeleton, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { GANTT_BASE_NAVIGATION_WIDTH, GANTT_PREVIEW_CONTAINER_SIZE } from '../constants';
import { GanttCompactLoading } from './GanttCompactLoading';

export const GanttPreviewLoading: FC = () => {
  return (
    <UiCard size="default" emptyPadding style={{ marginBottom: 20 }}>
      <UiRow wrap={false}>
        <UiRow padding={[32, 20]} gutter={[24, 24]} style={{ width: GANTT_BASE_NAVIGATION_WIDTH }}>
          <UiCol span={24}>
            <UiSpace full direction="vertical" align="center">
              <UiSkeleton loading width={110} height={20} />
            </UiSpace>
          </UiCol>
          <UiCol span={24}>
            <UiRow justify="space-between">
              <UiSkeleton loading width={20} height={20} />
              <UiSkeleton loading width={56} height={20} />
              <UiSkeleton loading width={20} height={20} />
            </UiRow>
          </UiCol>
          <UiCol span={24}>
            <UiSpace full direction="vertical" align="center">
              <UiSkeleton loading width={130} height={32} />
            </UiSpace>
          </UiCol>
        </UiRow>
        <UiRow style={GANTT_PREVIEW_CONTAINER_SIZE}>
          <GanttCompactLoading />
        </UiRow>
      </UiRow>
    </UiCard>
  );
};
