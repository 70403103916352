import { UiBadgePanel, UiBadgePanelOption, UiInput, UiInputProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { PropsWithChildren, FC } from 'react';

import { SurveyQuestionSection, SurveyQuestionSectionProps } from '../section/SurveyQuestionSection';
import styles from './SurveyQuestionEdit.scss';

export type SurveyEditProps = SurveyQuestionSectionProps &
  UiInputProps & {
    controls: UiBadgePanelOption[];
  };

export const SurveyQuestionEdit: FC<PropsWithChildren<SurveyEditProps>> = (props) => {
  const { controls, className, children, index, onFocus, ...titleInputProps } = props;

  return (
    <SurveyQuestionSection
      onFocus={onFocus}
      index={index}
      className={classNames(styles.surveyQuestionEdit, className)}
    >
      <UiBadgePanel options={controls} className={styles.surveyQuestionEdit__controls} />
      <UiInput
        {...titleInputProps}
        bordered={false}
        placeholder="Текст вопроса"
        className={styles.surveyQuestionEdit__title}
      />
      {children}
    </SurveyQuestionSection>
  );
};
