import { UiButton, UiRow, UiIcon, UiSpace, UiTypography, UiUploadDraggerProps, UiUpload } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import UploadSvg from '@vkph/ui/svg/upload.svg';

import styles from './UploadDraggerArea.scss';

export interface UploadDraggerAreaProps extends Omit<UiUploadDraggerProps, 'customRequest'> {
  onUploadAttachment?: UiUploadDraggerProps['customRequest'];
  loading?: boolean;
  wrapperClassName?: string;
  description?: string;
  isHiddenUploader?: boolean;
  compact?: boolean;
}

const DEFAULT_DESCRIPTION = 'PNG или JPEG весом до 10 Мб';
const DEFAULT_COMPACT_DESCRIPTION = 'для загрузки';

export const UploadDraggerArea: FC<UploadDraggerAreaProps> = (props) => {
  const {
    onUploadAttachment,
    description,
    loading,
    disabled,
    wrapperClassName,
    showUploadList = false,
    isHiddenUploader = false,
    compact = false,
    ...uploadProps
  } = props;
  const isDisabled = loading || disabled;

  return (
    <div
      className={classNames(styles.uploadDraggerArea, wrapperClassName, {
        [styles.uploadDraggerArea_showUploadList]: showUploadList,
        [styles.uploadDraggerArea_hideUploader]: isHiddenUploader,
      })}
    >
      <UiUpload.Dragger {...uploadProps} disabled={isDisabled} customRequest={onUploadAttachment}>
        {compact && (
          <UiRow justify="center" padding={12}>
            <UiSpace size={4}>
              <UiTypography.Link strong>Нажмите</UiTypography.Link>
              <UiTypography.Text>
                или перетащите файл {description || DEFAULT_COMPACT_DESCRIPTION}
              </UiTypography.Text>
            </UiSpace>
          </UiRow>
        )}
        {!compact && (
          <UiRow padding={20} align="middle" justify="center">
            <UiSpace direction="vertical" align="center">
              <UiButton
                round
                size="large"
                type="primary"
                disabled={isDisabled}
                icon={<UiIcon component={UploadSvg} width={20} height={20} />}
              />
              <UiTypography.Title level={4}>Кликните или перетащите файл сюда</UiTypography.Title>
              <UiTypography.Text>{description || DEFAULT_DESCRIPTION}</UiTypography.Text>
            </UiSpace>
          </UiRow>
        )}
      </UiUpload.Dragger>
    </div>
  );
};
