import { UiOptionData, UiFormItemProps } from '@vkph/ui';
import React, { useCallback } from 'react';

import { tagsDictsStorage } from '@vkph/common/store/tags';
import { normalizeValueRemoveWhitespaces, MAX_LENGTH_INPUT_50 } from '@vkph/common/utils';

import { CreatableMultiSelect } from '../creatable-multi-select';

export const TagsField: React.FC<UiFormItemProps<UiOptionData[]>> = (props) => {
  const { value = [], onChange } = props;
  const onTagsChange = useCallback((tags: UiOptionData[]) => onChange?.(tags), [onChange]);
  const normalizeSearchTags = useCallback(normalizeValueRemoveWhitespaces, []);

  return (
    <CreatableMultiSelect
      value={value}
      caseInsensitive
      maxTagItems={6}
      maxLength={MAX_LENGTH_INPUT_50}
      onChange={onTagsChange}
      optionsStorage={tagsDictsStorage.storage}
      normalizeSearchValue={normalizeSearchTags}
      createOptionEffect={tagsDictsStorage.createTagEffect}
    />
  );
};
