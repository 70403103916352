import { GetTasksParams } from '@vkph/common/store/tasks';
import { abstractStoreFactory } from '@vkph/common/utils/effector';

export enum GanttViewType {
  Month,
  Year,
}

interface GanttFilterStore extends GetTasksParams {
  viewType?: GanttViewType;
}

export const ganttFilterStore = abstractStoreFactory<GanttFilterStore>({
  viewType: GanttViewType.Month,
});
