import { Plugin } from '@ckeditor/ckeditor5-core';

import { FileUploadEditingPlugin } from './FileUploadEditingPlugin';
import { FileUploadUiPlugin } from './FileUploadUiPlugin';

export class FileUploadPlugin extends Plugin {
  static get requires() {
    return [FileUploadEditingPlugin, FileUploadUiPlugin];
  }

  static get pluginName() {
    return 'fileUpload';
  }
}
