import { UiButton, UiCollapse, UiIcon, UiTypography } from '@vkph/ui';
import React, { PropsWithChildren, FC, useState } from 'react';

import DownloadSVG from '@vkph/ui/svg/download.svg';

import styles from './SurveyQuestionTableCollapse.scss';

type Props = {
  onDownloadQuestionReport: () => void;
};

export const SurveyQuestionTableCollapse: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onDownloadQuestionReport } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className={styles.surveyQuestionTableCollapse}>
      <UiCollapse ghost onChange={() => setIsCollapsed(!isCollapsed)}>
        <UiCollapse.Panel
          key="panel"
          header={
            <UiTypography.Text className={styles.surveyQuestionTableCollapse__header}>
              {isCollapsed ? 'Подробнее' : 'Свернуть'}
            </UiTypography.Text>
          }
        >
          {children}
        </UiCollapse.Panel>
      </UiCollapse>
      <UiButton
        className={styles.surveyQuestionTableCollapse__suffixButton}
        type="link"
        icon={<UiIcon component={DownloadSVG} width={20} height={20} />}
        onClick={onDownloadQuestionReport}
      >
        Экспорт
      </UiButton>
    </div>
  );
};
