import React from 'react';

import { SurveyFreeAnswerFormatType, SurveyQuestionType } from '@vkph/common/types/models';

import { AVAILABLE_QUESTION_TYPES } from './constants';
import { SpecificAnswerTypeProps, SurveyAnswerMode } from './types';

export interface FreeAnswerProps extends Omit<SpecificAnswerTypeProps, 'type' | 'mode' | 'freeAnswerFormat'> {
  type?: SurveyQuestionType;
  freeAnswerFormat?: SurveyFreeAnswerFormatType;
  mode?: SurveyAnswerMode;
}

export const SurveyAnswer: React.FC<FreeAnswerProps> = (props) => {
  const {
    type = SurveyQuestionType.FREE_PARAGRAPH,
    mode = SurveyAnswerMode.Response,
    freeAnswerFormat,
    ...rest
  } = props;

  const Answer =
    mode === SurveyAnswerMode.Edit
      ? AVAILABLE_QUESTION_TYPES[type].editComponent
      : AVAILABLE_QUESTION_TYPES[type].responseComponent;

  if (type === SurveyQuestionType.FREE_ANSWER) {
    return <Answer formatString={freeAnswerFormat} {...rest} />;
  }

  return <Answer {...rest} />;
};
