import { UiImage, UiImageProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { EntityContentType, SurveyModel } from '@vkph/common/types/models';
import { getActualEditorFormat } from '@vkph/common/utils';

import { Editor } from '../../editor';
import { SurveyPreviewSkeleton } from './skeleton/SurveyPreviewSkeleton';

export interface SurveyPreviewProps extends PropsWithChildren {
  src: UiImageProps['src'];
  description?: SurveyModel['description'];
}

export interface SurveyPreviewComposition {
  Skeleton: typeof SurveyPreviewSkeleton;
}

export const SurveyPreview: SurveyPreviewComposition & FC<SurveyPreviewProps> = (props) => {
  const { src, description = { data: '', version: EntityContentType.CKEditor5 }, children } = props;
  const editorData = getActualEditorFormat(description).data;

  return (
    <>
      <UiImage src={src} height={432} style={{ marginBottom: 12 }} />
      <Editor.Viewer html={editorData} />
      {children}
    </>
  );
};

SurveyPreview.Skeleton = SurveyPreviewSkeleton;
