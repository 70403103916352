import { UiCard, UiCollapse } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { ContactLink } from '@vkph/common/types/models';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { MainContactItem, MainContactsList } from '../contacts-list/main';
import { OtherContactsList } from '../contacts-list/others';
import { ExternalLinkIcon, SocialLinksList } from '../contacts-list/social';

const PREVIEW_COUNT = 2;

type Props = {
  mainContacts: MainContactItem[];
  otherContacts: ContactLink[];
  socialLinks: (ContactLink | ExternalLinkContact)[];
  messengerIcons?: Map<string, string>;
  externalLinkIcons?: Map<string, ExternalLinkIcon>;
};

export const ContactsInfoMobile: FC<PropsWithChildren<Props>> = (props) => {
  const { mainContacts, otherContacts, socialLinks, messengerIcons, externalLinkIcons, children } = props;

  const { spaceM } = useSpace();
  const cardContentPadding = usePaddingStyle([0, spaceM]);

  const hasSocialLinks = socialLinks.length > 0;

  const isCollapsable = mainContacts.length + otherContacts.length + Number(hasSocialLinks) > PREVIEW_COUNT;

  const nonCollapseMainContacts = mainContacts.slice(0, PREVIEW_COUNT);
  const nonCollapseOtherContacts = otherContacts.slice(0, PREVIEW_COUNT - nonCollapseMainContacts.length);
  const collapseMainContacts = mainContacts.slice(PREVIEW_COUNT);
  const collapseOtherContacts = otherContacts.slice(nonCollapseOtherContacts.length);

  return (
    <>
      <UiCard.Content style={cardContentPadding}>
        {nonCollapseMainContacts.length > 0 && <MainContactsList constactsItems={nonCollapseMainContacts} />}

        {nonCollapseOtherContacts.length > 0 && (
          <OtherContactsList contactsItems={nonCollapseOtherContacts} messengerIcons={messengerIcons} />
        )}
        {!isCollapsable && hasSocialLinks && (
          <SocialLinksList socialLinksItems={socialLinks} externalLinkIcons={externalLinkIcons} />
        )}
      </UiCard.Content>

      {!isCollapsable && children}

      {isCollapsable && (
        <UiCollapse.Content height={0} compact>
          <UiCard.Content style={cardContentPadding}>
            {collapseMainContacts.length > 0 && <MainContactsList constactsItems={collapseMainContacts} />}
            {collapseOtherContacts.length > 0 && (
              <OtherContactsList contactsItems={collapseOtherContacts} messengerIcons={messengerIcons} />
            )}
            {hasSocialLinks && (
              <SocialLinksList socialLinksItems={socialLinks} externalLinkIcons={externalLinkIcons} />
            )}
          </UiCard.Content>
          {children}
        </UiCollapse.Content>
      )}
    </>
  );
};
