import { BadgeColors, UiBadge, UiImageBackground, UiTypography, UiOverlay, UiList } from '@vkph/ui';
import React, { FC } from 'react';

import { BlogAlbumModel } from '@vkph/common/types/models';

import styles from './GalleryAlbumList.scss';

type Props = {
  albumList: BlogAlbumModel[];
  onSelectAlbum: (album: BlogAlbumModel) => void;
};

export const GalleryAlbumList: FC<Props> = (props) => {
  const { albumList, onSelectAlbum } = props;

  return (
    <UiList
      grid={{ gutter: 8, column: 3 }}
      dataSource={albumList}
      renderItem={(album) => (
        <UiList.Item className={styles.galleryAlbumList__albumSingle} onClick={() => onSelectAlbum(album)}>
          <UiOverlay
            style={{ borderRadius: 4 }}
            content={
              <div className={styles.galleryAlbumList__albumCover}>
                <UiTypography.Text className={styles.galleryAlbumList__albumCover__title}>
                  {album.title}
                </UiTypography.Text>
                <UiBadge
                  color={BadgeColors.Primary}
                  className={styles.galleryAlbumList__albumCover__imagesCount}
                  count={album.imagesCount}
                />
              </div>
            }
          >
            {album.fileStorageCoverUrl && (
              <UiImageBackground
                className={styles.galleryAlbumList__albumCoverImageBackground}
                url={album.fileStorageCoverUrl}
              />
            )}
          </UiOverlay>
        </UiList.Item>
      )}
    />
  );
};
