import { UiSkeleton } from '@vkph/ui';
import React, { FC } from 'react';

import styles from '../UserLinkCell.scss';

interface Props {
  size: number;
}

export const UserLinkCellLoading: FC<Props> = (props) => {
  const { size } = props;

  return (
    <>
      <div className={styles.userLinkCell}>
        <UiSkeleton circle loading width={size} height={size} />
        <UiSkeleton loading width={150} height={20} className={styles.userLinkCell__name} />
      </div>
    </>
  );
};
