import { useBreakpoint, UiButton, UiSkeleton } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getReactionsTypesListStorage } from '@vkph/common/store/reactions';
import { EmojiUuid, ReactionsCountInfo, ReactionModel } from '@vkph/common/types/models';
import { getTotalSumByKey } from '@vkph/common/utils';

import styles from './ReactionsLikes.scss';
import { ReactionsLikesAnimatedIcons } from './animated-icons/ReactionsLikesAnimatedIcons';
import { reactionsIcons } from './constants';
import { getTotalReactions } from './helpers';
import { ReactionsLikesIcon } from './icon/ReactionsLikesIcon';
import { ReactionsLikesPopover } from './popover/ReactionsLikesPopover';

const RATE_BTN_MARGIN = 8;
const RATE_BTN_DEFAULT_WIDTH = 61 + RATE_BTN_MARGIN;
const REACTION_WIDTH = 16;

export type ReactionHandler = (emojiUuid?: EmojiUuid) => void;

export interface ReactionsLikesProps extends Pick<HTMLAttributes<HTMLDivElement>, 'style'> {
  reactions: ReactionModel[];
  onReaction?: ReactionHandler;
}

export const ReactionsLikes: FC<ReactionsLikesProps> = (props) => {
  const { reactions, onReaction, style } = props;
  const [updatedReactions, setUpdatedReactions] = useState<ReactionsCountInfo[]>([]);
  const [actualMyReactionId, setActualMyReactionId] = useState<EmojiUuid | null>(null);
  const [emojiPopoverShown, setEmojiPopoverShown] = useState(false);
  const [rateBtnSize, setRateBtnSize] = useState(onReaction ? RATE_BTN_DEFAULT_WIDTH : 0);
  const rateBtnTextRef = useRef<HTMLSpanElement>(null);
  const { lg: isLayoutLarge } = useBreakpoint();

  const reactionsTypesStorage = useMemo(getReactionsTypesListStorage, []);

  const { data: reactionsData, loading } = useAbstractStorage(reactionsTypesStorage.storage, {
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
  });

  const totalCount = useMemo(() => {
    return getTotalSumByKey(updatedReactions, 'count');
  }, [updatedReactions]);

  const rateBtnText = useMemo(() => {
    return reactionsData.find((o) => o.id === actualMyReactionId)?.name || 'Оценить';
  }, [reactionsData, actualMyReactionId]);

  useEffect(() => {
    setUpdatedReactions(getTotalReactions(reactions, reactionsData));
  }, [reactions, reactionsData]);

  useEffect(() => {
    setActualMyReactionId(reactions.find((r) => r.isReacted)?.reactionId || null);
  }, [reactions]);

  const doReact = (reactionId?: EmojiUuid) => {
    if (!onReaction) {
      return;
    }

    const isLike = actualMyReactionId !== reactionId;

    setEmojiPopoverShown(false);

    setUpdatedReactions((currentUpdatedReactions) => {
      const result = [...currentUpdatedReactions];

      const current = result.find(({ id }) => id === reactionId);
      const previous = result.find(({ id }) => id === actualMyReactionId);

      if (current) {
        current.count += isLike ? 1 : -1;
      }

      if (previous && isLike) {
        previous.count -= 1;
      }

      return result;
    });

    setActualMyReactionId(isLike && reactionId ? reactionId : null);
    onReaction(isLike ? reactionId : undefined);
  };

  const animatedReactionIcons = () => {
    return reactionsData
      .filter(({ iconName, id }, index) => {
        return (
          Boolean(
            updatedReactions.find((updatedReaction) => updatedReaction.id === id)?.count &&
              reactionsIcons.find((icon) => icon.name === iconName),
          ) ||
          (index === 0 && totalCount === 0)
        );
      })
      .map(({ iconName, id }) => {
        return {
          icon: <ReactionsLikesIcon id={id} iconName={iconName} onReact={doReact} />,
          key: id,
          width: REACTION_WIDTH,
        };
      });
  };

  useEffect(() => {
    if (!rateBtnTextRef?.current?.clientWidth) {
      return;
    }

    setRateBtnSize(rateBtnTextRef.current.clientWidth + RATE_BTN_MARGIN);
  }, [actualMyReactionId, rateBtnTextRef.current]);

  const iconsBarItems = useMemo(animatedReactionIcons, [reactionsData, updatedReactions, rateBtnSize]);

  const getRateButton = () => {
    const rateButton = (
      <UiButton
        type="link"
        className={classNames([
          styles.reactionsLikes__rateBtn,
          { [styles.reactionsLikes__rateBtn_liked]: actualMyReactionId !== null },
        ])}
        onClick={(e) => {
          e.stopPropagation();
          doReact(actualMyReactionId !== null ? actualMyReactionId : reactionsData[0]?.id);
        }}
      >
        <span ref={rateBtnTextRef}>{rateBtnText}</span>
      </UiButton>
    );

    if (actualMyReactionId === null) {
      return (
        <ReactionsLikesPopover
          reactionsData={reactionsData}
          onReact={doReact}
          open={emojiPopoverShown}
          onOpenChange={setEmojiPopoverShown}
        >
          {rateButton}
        </ReactionsLikesPopover>
      );
    }

    return rateButton;
  };

  return (
    <div className={styles.reactionsLikes} style={style}>
      <UiSkeleton loading={loading} height={20} width={100} style={{ display: 'block' }}>
        {onReaction && isLayoutLarge && getRateButton()}
        <div className={styles.reactionsLikes__icons}>
          <ReactionsLikesAnimatedIcons items={iconsBarItems} rateBtnSize={rateBtnSize} />
          <span className={styles.reactionsLikes__totalCount}>{totalCount}</span>
        </div>
      </UiSkeleton>
    </div>
  );
};
