// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9af5946b_image-actions{position:absolute;top:0;right:0;display:flex}._9af5946b_image-actions :last-child{border-radius:0 2px 0 0}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/image-actions/ImageActions.scss"],"names":[],"mappings":"AACA,yBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,YAAA,CAEA,qCACE,uBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.image-actions {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: flex;\n\n  :last-child {\n    border-radius: 0 2px 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-actions": "_9af5946b_image-actions",
	"imageActions": "_9af5946b_image-actions"
};
export default ___CSS_LOADER_EXPORT___;
