import classNames from 'classnames';
import React, { FC, MouseEventHandler } from 'react';
import ReactMarkdown, { Options as ReactMarkdownOptions } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import styles from './Markdown.scss';
import { MarkdownMentionLink } from './mention-link/MarkdownMentionLink';

export type MarkdownComposition = {
  MentionLink: typeof MarkdownMentionLink;
};

export interface MarkdownProps extends ReactMarkdownOptions {
  mentionLinkTrigger?: string;
  htmlMarkup?: boolean;
}

export const Markdown: FC<MarkdownProps> & MarkdownComposition = (props) => {
  const { mentionLinkTrigger = '@', remarkPlugins = [], htmlMarkup, className, ...markdownProps } = props;

  return (
    <ReactMarkdown
      className={classNames(styles.markdown, className)}
      rehypePlugins={htmlMarkup ? [rehypeRaw] : []}
      remarkPlugins={[remarkGfm, ...remarkPlugins]}
      components={{
        a: ({ node, children, ...linkProps }) => {
          const href = node?.properties?.href || '';
          const onClick: MouseEventHandler = (e) => e.stopPropagation();

          if (typeof href !== 'string') {
            return <a {...linkProps}>{children}</a>;
          }

          const isMentionPath = href.startsWith(mentionLinkTrigger);
          const pathname = isMentionPath ? href.substring(1) : href;
          const userId = pathname.split('/').at(-1);

          if (isMentionPath && userId) {
            return (
              <Markdown.MentionLink userId={userId} to={{ pathname }} onClick={onClick} {...linkProps}>
                {children}
              </Markdown.MentionLink>
            );
          }

          return (
            <a href={href} onClick={onClick} rel="noopener noreferrer" target="_blank" {...linkProps}>
              {children}
            </a>
          );
        },
      }}
      {...markdownProps}
    />
  );
};

Markdown.MentionLink = MarkdownMentionLink;
