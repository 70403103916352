import { Plugin } from '@ckeditor/ckeditor5-core';
import downloadIcon from '@ckeditor/ckeditor5-core/theme/icons/align-bottom.svg';
import { FileDialogButtonView } from '@ckeditor/ckeditor5-upload';
import { Config } from '@ckeditor/ckeditor5-utils';

import { CkEditorConfig } from '../../config/editor-config';
import { FileUploadConfig } from '../../types';

/**
 * The file upload button plugin.
 *
 * Adds the `'fileUpload'` button to the {@link module:ui/componentfactory~ComponentFactory UI component factory}.
 */
export class FileUploadUiPlugin extends Plugin {
  /**
   * @inheritDoc
   */
  init() {
    const { editor } = this;

    // Setup `fileUpload` button.
    editor.ui.componentFactory.add('fileUpload', (locale) => {
      const view = new FileDialogButtonView(locale);
      const command = editor.commands.get('fileUpload');
      const config = editor.config as unknown as Config<CkEditorConfig>;
      const allowedTypes = config.get('fileUpload.allowedTypes') as FileUploadConfig['allowedTypes'];

      view.set({
        acceptedType: allowedTypes.join(','),
        allowMultipleFiles: false,
      });

      view.buttonView.set({
        label: 'Прикрепить файл',
        tooltip: true,
        icon: downloadIcon,
      });

      if (command) {
        view.buttonView.bind('isEnabled').to(command);
      }

      view.on('done', (_, file) => {
        const fileToUpload = file;

        if (fileToUpload.length) {
          editor.execute('fileUpload', { file: fileToUpload });
        }
      });

      return view;
    });
  }
}
