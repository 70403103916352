import { UiIcon, UiSpace, UiTooltip, UiTypography, UiTypographySymbolName } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { BaseTimeInterval, ProjectsStageModel } from '@vkph/common/types/models';
import { getFormattedDate } from '@vkph/common/utils';
import { variables, useTheme } from '@vkph/ui/providers/theme';
import CalendarSvg from '@vkph/ui/svg/calendar.svg';

interface Props extends BaseTimeInterval {
  compact?: boolean;
  title?: ProjectsStageModel['title'];
}

export const GanttRangeTooltip: FC<PropsWithChildren<Props>> = (props) => {
  const { title, timeStart, timeEnd, children, compact } = props;
  const [{ variables: themeVariables }] = useTheme();
  const rangeFormat = 'd MMMM yyyy';

  const RangeDate: FC = () => (
    <UiIcon.Label component={CalendarSvg} style={{ color: variables.themePalette.colorStrokeDark }} shift={4}>
      <UiSpace size={2}>
        {getFormattedDate(timeStart, rangeFormat)}
        <UiTypography.Symbol name={UiTypographySymbolName.EmDash} />
        {getFormattedDate(timeEnd, rangeFormat)}
      </UiSpace>
    </UiIcon.Label>
  );

  const Title: FC = () => (
    <UiSpace direction="vertical" size={4}>
      <UiTypography.Text strong style={{ color: themeVariables.colorBgPrimary }}>
        {title}
      </UiTypography.Text>
      <RangeDate />
    </UiSpace>
  );

  return (
    <UiTooltip placement="topRight" title={compact ? <RangeDate /> : <Title />}>
      {children}
    </UiTooltip>
  );
};
