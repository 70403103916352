import { UiIcon, UiIconLabelProps, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

export type SurveyQuestionInfoItem = Omit<UiIconLabelProps, 'className'>;

export type SurveyQuestionInfoProps = {
  questionInfo: SurveyQuestionInfoItem[];
  direction?: Directions;
};

export enum Directions {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export const SurveyQuestionInfo: FC<SurveyQuestionInfoProps> = (props) => {
  const { questionInfo, direction = Directions.Horizontal } = props;

  return (
    <UiSpace size="large" direction={direction}>
      {questionInfo.map((info) => (
        <UiIcon.Label {...info} key={info.key} strong />
      ))}
    </UiSpace>
  );
};
