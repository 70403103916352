import { useStore } from 'effector-react';
import React, { forwardRef } from 'react';

import { ProjectsStageModel } from '@vkph/common/types/models';

import { GanttFilter } from '../../filter/GanttFilter';
import { ganttFilterStore, GanttViewType } from '../../store';
import { DisplayOptionsFull } from '../GanttFull';
import styles from './GanttFullHeader.scss';
import { GanttFullHeaderMonths } from './months/GanttFullHeaderMonths';
import { GanttFullHeaderYears } from './years/GanttFullHeaderYears';

interface Props {
  displayOptions: DisplayOptionsFull;
  stages: ProjectsStageModel[];
}

export const GanttFullHeader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { viewType } = useStore(ganttFilterStore.store);
  const isActive = (type: GanttViewType) => type === viewType;

  return (
    <div ref={ref} className={styles.ganttFullHeader}>
      <GanttFilter />
      {isActive(GanttViewType.Year) && <GanttFullHeaderYears {...props} />}
      {isActive(GanttViewType.Month) && <GanttFullHeaderMonths {...props} />}
    </div>
  );
});
