import {
  UiRadio,
  UiRadioChangeEvent,
  UiRadioGroupProps,
  UiCheckbox,
  UiCheckboxGroupProps,
  UiCheckboxValueType,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, useMemo, PropsWithChildren } from 'react';

import { Poll } from '../Poll';
import { PollButtonTypes, PollButtonTypesProps, POLL_BUTTON_TYPE_DEFAULT } from '../poll-button/PollButton';
import styles from './PollGroup.scss';

export type PollGroupGroupTypeProps = UiRadioGroupProps | UiCheckboxGroupProps;

export type PollGroupRadio = {
  value?: string;
  type?: PollButtonTypes.Single;
};
export type PollGroupCheckbox = {
  value?: string[];
  type: PollButtonTypes.Multiple;
};
export type PollGroupOption = {
  value?: string;
  label?: React.ReactNode;
};
export type PollGroupValue = (PollGroupCheckbox | PollGroupRadio)['value'];
export type PollGroupBaseProps = PollButtonTypesProps & {
  options?: PollGroupOption[];
  onChange?: (value: PollGroupValue) => void;
  className?: string;
};
type PollGroupTypeProps = PollGroupRadio | PollGroupCheckbox;
type ChangeHandlerValue = UiCheckboxValueType[] | UiRadioChangeEvent;
export type PollGroupProps = PollGroupTypeProps & PollGroupBaseProps & PropsWithChildren;

export const PollGroup: FC<PollGroupProps> = (pollProps) => {
  const { children, ...props } = pollProps;

  const { type = POLL_BUTTON_TYPE_DEFAULT, options, onChange, className } = props as PollGroupBaseProps;
  const { value: singleValue } = props as PollGroupRadio;
  const { value: multipleValue } = props as PollGroupCheckbox;

  const isType = (typeValue: PollButtonTypes) => type === typeValue;
  const changeHandler = (selectValue: ChangeHandlerValue) => {
    let newValue = selectValue;

    if (isType(PollButtonTypes.Single) && !Array.isArray(selectValue)) {
      newValue = (newValue as UiRadioChangeEvent)?.target?.value;
    }

    onChange?.(newValue as PollGroupValue);
  };

  const childrenOptions = useMemo(
    () =>
      children ||
      options?.map((option) => (
        <Poll.Button key={option.value} type={type} {...option}>
          {option.label}
        </Poll.Button>
      )),
    [],
  );

  const groupProps = {
    onChange: changeHandler,
    className: classNames(styles.pollGroup, className),
  };

  if (isType(PollButtonTypes.Single)) {
    return (
      <UiRadio.Group value={singleValue} {...groupProps}>
        {childrenOptions}
      </UiRadio.Group>
    );
  }

  if (isType(PollButtonTypes.Multiple)) {
    return (
      <UiCheckbox.Group value={multipleValue} {...groupProps}>
        {childrenOptions}
      </UiCheckbox.Group>
    );
  }

  return <>{childrenOptions}</>;
};
