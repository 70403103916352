import { UiSpinner } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { VariableSizeList } from 'react-window';

import styles from './ImageGrid.scss';
import { getImagesForGrid, PreparedImage } from './helpers';
import { ImageGridItem } from './item';

export type ImageGridProps = {
  images: PreparedImage[];
  width: number;
  height: number;
  gap?: number;
  preferredRowHeight: number;
  isLoading?: boolean;
  onSelectImage: (image: PreparedImage) => void;
};

type ImageGridComposition = {
  Item: typeof ImageGridItem;
};

type ImageGridComponent = ImageGridComposition & FC<ImageGridProps>;

export const ImageGrid: ImageGridComponent = (props) => {
  const {
    images: imagesProp,
    gap: gapProp,
    onSelectImage,
    width,
    height,
    preferredRowHeight,
    isLoading,
  } = props;

  const gap = useMemo(() => {
    return typeof gapProp === 'undefined' ? 4 : gapProp / 2;
  }, [gapProp]);

  const imageRows = getImagesForGrid(imagesProp, {
    preferredHeight: preferredRowHeight,
    containerWidth: width - 24,
    gapBetweenImages: gap * 2,
  });

  if (isLoading) {
    return <UiSpinner spinning />;
  }

  return (
    <VariableSizeList
      overscanCount={imageRows.length}
      itemCount={imageRows.length}
      height={height}
      itemSize={(idx) => imageRows[idx].height + gap * 2}
      width={width}
      className={styles.imageGrid}
      style={{ overflow: 'visible' }}
    >
      {({ style, index }) => {
        return (
          <div style={style} className={styles.imageGrid__row}>
            {imageRows[index].images.map((img) => (
              <ImageGridItem key={img.id} gap={gap} image={img} onClick={onSelectImage} />
            ))}
          </div>
        );
      }}
    </VariableSizeList>
  );
};

ImageGrid.Item = ImageGridItem;
