import { UiSpinner } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';
import { Scrollbars, positionValues } from 'react-custom-scrollbars';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getProjectStagesStorage } from '@vkph/common/store/tasks';
import { ProjectId } from '@vkph/common/types/models';
import { useElementSize } from '@vkph/ui/hooks';

import { DisplayOptions } from '..';
import { ganttFilterStore, GanttViewType } from '../store';
import { GanttFullContent } from './content/GanttFullContent';
import { GanttFullHeader } from './header/GanttFullHeader';

export interface DisplayOptionsFull extends Pick<DisplayOptions, 'daySize'> {
  scrollHeight: number;
  headerHeight: number;
}

const DAY_SIZE = {
  [GanttViewType.Year]: 140 / 30,
  [GanttViewType.Month]: 84 / 7,
};

interface GanttFullSizeProps {
  projectId: ProjectId;
}

export const GanttFull: FC<GanttFullSizeProps> = (props) => {
  const { projectId } = props;
  const { viewType = GanttViewType.Month } = useStore(ganttFilterStore.store);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const { ref, height, width } = useElementSize([scrollHeight, viewType]);
  const { ref: headerRef, height: headerHeight } = useElementSize([viewType]);

  const { storage: projectStagesStorage } = useMemo(getProjectStagesStorage, [projectId]);
  const { data: projectStagesData, loading: isProjectStagesLoading } = useAbstractStorage(
    projectStagesStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        projectId,
      },
      cancelPendingRequestOnUnmount: true,
    },
  );

  const displayOptions: DisplayOptionsFull = {
    daySize: DAY_SIZE[viewType],
    scrollHeight,
    headerHeight,
  } as const;

  const onUpdateScroll = (values: positionValues) => setScrollHeight(values.scrollHeight);

  return (
    <div ref={ref} style={{ height: '100%' }}>
      <UiSpinner spinning={isProjectStagesLoading}>
        <Scrollbars style={{ height, width }} onUpdate={onUpdateScroll}>
          <GanttFullHeader ref={headerRef} displayOptions={displayOptions} stages={projectStagesData} />
          <GanttFullContent displayOptions={displayOptions} stages={projectStagesData} />
        </Scrollbars>
      </UiSpinner>
    </div>
  );
};
