import { UiCol, UiRow, UiRowProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { PostFooterActions } from './actions/PostFooterActions';

type PostFooterComposition = {
  Actions: typeof PostFooterActions;
};

type PostFooterProps = UiRowProps;

type PostFooterComponent = FC<PropsWithChildren<PostFooterProps>> & PostFooterComposition;

export const PostFooter: PostFooterComponent = (props) => {
  const { children, ...rest } = props;

  return (
    <UiRow {...rest}>
      <UiCol span={24}>{children}</UiCol>
    </UiRow>
  );
};

PostFooter.Actions = PostFooterActions;
