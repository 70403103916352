// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._13c891ad_achievement-icon{display:flex;position:relative}._13c891ad_achievement-icon_disabled{opacity:.4}._13c891ad_achievement-icon_selected{border-radius:50%;box-shadow:0 0 0 4px var(--default-themeVariables-colorBrand)}._13c891ad_achievement-icon__badge_center{display:flex;justify-content:center}._13c891ad_achievement-icon__badge_center .ant-badge-count{top:unset;right:unset;bottom:-8px;margin-left:auto;margin-right:auto}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/achievement/icon/AchievementIcon.scss"],"names":[],"mappings":"AACA,4BACE,YAAA,CACA,iBAAA,CAEA,qCACE,UAAA,CAGF,qCACE,iBAAA,CACA,6DAAA,CAGF,0CACE,YAAA,CACA,sBAAA,CAEA,2DACE,SAAA,CACA,WAAA,CACA,WAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.achievement-icon {\n  display: flex;\n  position: relative;\n\n  &_disabled {\n    opacity: 0.4;\n  }\n\n  &_selected {\n    border-radius: 50%;\n    box-shadow: $shadow4pxSelect;\n  }\n\n  &__badge_center {\n    display: flex;\n    justify-content: center;\n\n    :global(.ant-badge-count) {\n      top: unset;\n      right: unset;\n      bottom: -$spaceXS;\n      margin-left: auto;\n      margin-right: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"achievement-icon": "_13c891ad_achievement-icon",
	"achievementIcon": "_13c891ad_achievement-icon",
	"achievement-icon_disabled": "_13c891ad_achievement-icon_disabled",
	"achievementIcon_disabled": "_13c891ad_achievement-icon_disabled",
	"achievement-icon_selected": "_13c891ad_achievement-icon_selected",
	"achievementIcon_selected": "_13c891ad_achievement-icon_selected",
	"achievement-icon__badge_center": "_13c891ad_achievement-icon__badge_center",
	"achievementIcon__badge_center": "_13c891ad_achievement-icon__badge_center"
};
export default ___CSS_LOADER_EXPORT___;
