import isBoolean from 'lodash/isBoolean';

import { isBlogPost } from '@vkph/common/types/guards';
import { BlogFullModel, PostModel } from '@vkph/common/types/models';

export const getPostBlogsMap = (post: PostModel): BlogFullModel[] => {
  if (!isBlogPost(post)) {
    return [];
  }

  if (post.blogsFull ?? Array.isArray(post.blogs)) {
    return Array.isArray(post.blogs) ? post.blogs : post.blogsFull;
  }

  if (post.blogs && !Array.isArray(post.blogs)) {
    return [post.blogs];
  }

  return [];
};

type BlogFullModelKey = keyof Pick<BlogFullModel, 'isComments' | 'isReacted'>;

export const isSomePostBlogsNegativeKey = (post: PostModel, key: BlogFullModelKey): boolean => {
  return getPostBlogsMap(post).some((blog) => isBoolean(blog[key]) && !blog[key]);
};
