import { GanttCompact } from './compact/GanttCompact';
import { GanttFull } from './full/GanttFull';
import { GanttPreview } from './preview/GanttPreview';

export * from './compact/GanttCompact';
export * from './full/GanttFull';
export * from './preview/GanttPreview';

export type GanttComposition = {
  Compact: typeof GanttCompact;
  Full: typeof GanttFull;
  Preview: typeof GanttPreview;
};

export const Gantt = {} as GanttComposition;

Gantt.Compact = GanttCompact;
Gantt.Full = GanttFull;
Gantt.Preview = GanttPreview;
