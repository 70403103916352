import { Plugin } from '@ckeditor/ckeditor5-core';
import { Notification } from '@ckeditor/ckeditor5-ui';
import { FileRepository } from '@ckeditor/ckeditor5-upload';

import { FileUploadCommand } from './FileUploadCommand';
import { dataFileIdAttributeModelName, dataFileIdAttributeName } from './const';

/**
 * The editing part of the file upload feature. It registers the `'fileUpload'` command.
 */
export class FileUploadEditingPlugin extends Plugin {
  static get requires() {
    return [FileRepository, Notification];
  }

  static get pluginName() {
    return 'FileUploadEditing';
  }

  init() {
    const { editor } = this;
    const { conversion, model, editing } = editor;
    const { schema } = model;

    // Register fileUpload command.
    editor.commands.add('fileUpload', new FileUploadCommand(editor));

    // Prevents from the browser redirecting to the dropped file.
    editing.view.document.on('dragover', (_, data) => {
      data.preventDefault();
    });

    // Расширение аттрибутов абстрактного элемента текст
    schema.extend('$text', {
      allowAttributes: [dataFileIdAttributeModelName],
    });

    // Upcast (Конвертация из разметки в модель)
    conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'a',
        key: dataFileIdAttributeName,
      },
      model: dataFileIdAttributeModelName,
      converterPriority: 'low',
    });

    // Downcast (Конвертация из модели в разметку)
    editor.conversion.for('downcast').attributeToElement({
      model: dataFileIdAttributeModelName,
      view: (value, { writer }) => {
        return writer.createAttributeElement(
          'a',
          { [dataFileIdAttributeName]: value, target: '_blank' },
          { priority: 5 },
        );
      },
      converterPriority: 'low',
    });
  }

  destroy() {
    return super.destroy();
  }
}
