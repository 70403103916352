import { UiAvatarSize } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { UiUser, UiUserInfoSkeletonProps } from '../../user';
import styles from '../LinkListItem.scss';

export const LinkListItemSkeleton: FC<UiUserInfoSkeletonProps> = (props) => {
  const { className, size = UiAvatarSize.M, ...restProps } = props;

  return (
    <UiUser.Info.Skeleton className={classNames(styles.linkListItem, className)} size={size} {...restProps} />
  );
};
