import { UiFile, UiCol, UiColProps, UiRow, UiRowProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { FileActions } from '../actions/FileActions';
import { BasicFileProps } from '../types';

export interface FileRowProps
  extends BasicFileProps,
    Pick<UiRowProps, 'style' | 'justify'>,
    Pick<UiColProps, 'flex'> {
  stretch?: boolean;
}

export const FileRow: FC<PropsWithChildren<FileRowProps>> = (props) => {
  const { children, style, stretch, justify, flex = '1 0 auto', actions, ...fileProps } = props;

  return (
    <UiRow
      align="middle"
      wrap={false}
      justify={justify}
      gutter={16}
      style={{ ...style, flexGrow: stretch ? 1 : 0 }}
    >
      <UiCol flex={flex}>
        <UiFile {...fileProps} />
      </UiCol>
      {Boolean(children) && <UiCol flex="1 1 auto">{children}</UiCol>}
      {actions?.length > 0 && (
        <UiCol>
          <FileActions actions={actions} />
        </UiCol>
      )}
    </UiRow>
  );
};
