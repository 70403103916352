import { UiButton, UiSpace, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, useCallback } from 'react';

import { AvailableAlphabetsPointers, Alphabets } from '../constants';
import styles from './AlphabetPointers.scss';

export type AlphabetSymbol = string;

export interface AlphabetPointersProps {
  value: AlphabetSymbol;
  onChange?: (value: AlphabetSymbol) => void;
  data: Alphabets;
}

export const AlphabetPointers: FC<AlphabetPointersProps> = (props) => {
  const { data, value, onChange } = props;
  const alphabet = AvailableAlphabetsPointers[data];
  const heading = `${alphabet[0]}-${alphabet[alphabet.length - 1]}`;

  const onSymbolChange = useCallback(
    (symbol: AlphabetSymbol) => {
      onChange?.(value === symbol ? '' : symbol);
    },
    [onChange, value],
  );

  return (
    <UiSpace full wrap size={0} align="center">
      <UiTypography.Text strong className={styles.alphabetPointers__heading}>
        {heading}
      </UiTypography.Text>

      {alphabet.map((symbol) => {
        const isActive = value === symbol;

        return (
          <UiButton
            type="link-secondary"
            key={symbol}
            onClick={() => onSymbolChange(symbol)}
            className={classNames(styles.alphabetPointers__symbol, {
              [styles.alphabetPointers__symbol_active]: isActive,
            })}
            label={symbol}
          />
        );
      })}
    </UiSpace>
  );
};
