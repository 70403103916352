import { UiTable, UiTableColumnFilterItem, UiTableColumnFilterProps } from '@vkph/ui';
import React, { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { DictPaginated } from '@vkph/common/types/api';
import { UserProfileModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames, AbstractStorage } from '@vkph/common/utils';

import { UiUser } from '../../user';

interface Props<T extends Record<string, unknown>>
  extends Pick<UiTableColumnFilterProps, 'filterDropdownProps'> {
  storage: AbstractStorage<DictPaginated<UserProfileModel>, UserProfileModel[], UserProfileModel[], T>;
  params: T;
  onChange: (values: string[]) => void;
}

export const TableColumnFilterUsers = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { storage, params, ...restProps } = props;

  const [search, setSearch] = useState('');

  const { data, loading, error } = useAbstractStorage(storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { ...params, pageSize: 12, search }, // TODO https://gitlab.digital-insider.ru/di/main-frontend/-/issues/197
    cancelPendingRequestOnUnmount: true,
  });

  const onSearch = useDebouncedCallback(setSearch, 400);

  const options = useMemo<UiTableColumnFilterItem[]>(() => {
    return data.map((user) => {
      const { id, fullName } = user;

      return {
        value: id,
        label: (
          <UiUser.Info
            to={getRoutePath(RouteNames.Profile, { id })}
            title={getFullNameWithoutPatronymic(fullName)}
            avatarProps={{ size: 32 }}
          />
        ),
      };
    });
  }, [data]);

  return (
    <UiTable.ColumnFilter
      loading={loading}
      options={options}
      error={Boolean(error)}
      onSearch={onSearch}
      {...restProps}
    />
  );
};
