import { UiUserInfo, UiUserInfoProps as InfoProps } from './info/UiUserInfo';

export type { UiUserInfoSkeletonProps } from './info/skeleton/UiUserInfoSkeleton';

type UserComposition = {
  Info: typeof UiUserInfo;
};

export const UiUser: UserComposition = {
  Info: UiUserInfo,
};

export type UiUserInfoProps = InfoProps;
