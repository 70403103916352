import { UiCol, UiRow, UiIconProps, UiTruncateMarkup, UiTruncateMarkupProps, UiTypography } from '@vkph/ui';
import React, { FC, PropsWithChildren, CSSProperties } from 'react';

import { formatPhoneNumber, PhoneMask, RegExpPattern } from '@vkph/common/utils';
import ContactWorkPhoneSvg from '@vkph/ui/svg/case.svg';
import ContactMailSvg from '@vkph/ui/svg/contact-mail.svg';
import ContactPhoneSvg from '@vkph/ui/svg/contact-phone.svg';
import ContactLocationSvg from '@vkph/ui/svg/location.svg';
import PrinterOneSvg from '@vkph/ui/svg/printer-one.svg';
import WebsiteSvg from '@vkph/ui/svg/website.svg';

import { ClipboardButton } from '../../clipboard-button';
import { EmployeeContactsLinkIcon } from './icon/EmployeeContactsLinkIcon';

export enum EmployeeContactType {
  MobilePhone = 'mobilePhone',
  PersonalEmail = 'personalEmail',
  WorkEmail = 'workEmail',
  WorkPhone = 'workPhone',
  ContactLocation = 'contactLocation',
  Fax = 'fax',
  Website = 'website',
}

export type EmployeeContactsType = 'phone' | 'email' | 'address' | 'fax' | 'site';

export const EmployeeContactsLinkIcons: Record<EmployeeContactType, SvgrComponent> = {
  [EmployeeContactType.MobilePhone]: ContactPhoneSvg,
  [EmployeeContactType.PersonalEmail]: ContactMailSvg,
  [EmployeeContactType.WorkEmail]: ContactMailSvg,
  [EmployeeContactType.WorkPhone]: ContactWorkPhoneSvg,
  [EmployeeContactType.ContactLocation]: ContactLocationSvg,
  [EmployeeContactType.Fax]: PrinterOneSvg,
  [EmployeeContactType.Website]: WebsiteSvg,
};

export interface EmployeeContactsLinkType extends Partial<Pick<UiTruncateMarkupProps, 'truncate'>> {
  value: string;
  label: string;
  href?: string;
  icon?: UiIconProps['component'];
}

export const employeeContactsLinkTypes: Record<
  EmployeeContactsType,
  (value: string) => EmployeeContactsLinkType
> = {
  phone: (value) => ({
    value,
    href: `tel:${value}`,
    label: formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu),
    icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkPhone],
  }),
  fax: (value) => ({
    value,
    href: `tel:${value}`,
    label: formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu),
    icon: EmployeeContactsLinkIcons[EmployeeContactType.Fax],
  }),
  email: (value) => ({
    value,
    label: value,
    href: `mailto:${value}`,
    icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkEmail],
  }),
  site: (value) => ({
    value,
    label: value,
    href: value,
    icon: EmployeeContactsLinkIcons[EmployeeContactType.Website],
  }),
  address: (value) => ({
    value,
    label: value,
    icon: EmployeeContactsLinkIcons[EmployeeContactType.ContactLocation],
    truncate: false,
  }),
};

type EmployeeContactsLinkComposition = {
  Icon: typeof EmployeeContactsLinkIcon;
};

const labelStyle: CSSProperties = { marginLeft: 8 };

export const EmployeeContactsLink: FC<PropsWithChildren<EmployeeContactsLinkType>> &
  EmployeeContactsLinkComposition = (props) => {
  const { value, icon, href, label, children: image, truncate = true } = props;
  const truncatedLabel = <UiTruncateMarkup truncate={truncate}>{label}</UiTruncateMarkup>;

  return (
    <UiRow wrap={false} align="middle" gutter={8}>
      <UiCol flex="auto">
        <UiRow wrap={false}>
          {icon ? <EmployeeContactsLinkIcon component={icon} /> : image}
          {href && (
            <UiTypography.Link href={href} style={labelStyle}>
              {truncatedLabel}
            </UiTypography.Link>
          )}
          {!href && (
            <UiTypography.Text strong style={labelStyle}>
              {truncatedLabel}
            </UiTypography.Text>
          )}
        </UiRow>
      </UiCol>
      <UiCol>
        <ClipboardButton value={value} />
      </UiCol>
    </UiRow>
  );
};

EmployeeContactsLink.Icon = EmployeeContactsLinkIcon;
