import classNames from 'classnames';
import React, { PropsWithChildren, FC } from 'react';

import styles from './Sidebar.scss';
import { SidebarMenuComponent, SidebarMenu } from './menu/SidebarMenu';

export enum SidebarPlacement {
  Left = 'left',
  Right = 'right',
}

export type SidebarProps = {
  className?: string;
  placement?: SidebarPlacement;
};

type SidebarComposition = {
  Menu: SidebarMenuComponent;
};

export const Sidebar: FC<PropsWithChildren<SidebarProps>> & SidebarComposition = (props) => {
  const { children, placement = SidebarPlacement.Left, className } = props;

  const sidebarClassname = classNames(
    styles.sidebar,
    {
      [styles.sidebar_withBorderLeft]: placement === SidebarPlacement.Right,
      [styles.sidebar_withBorderRight]: placement === SidebarPlacement.Left,
    },
    className,
  );

  return <div className={sidebarClassname}>{children}</div>;
};

Sidebar.Menu = SidebarMenu;
