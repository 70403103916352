import { UiImage, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';
import { Link } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NewsArticle, PostTypes } from '@vkph/common/types/models';
import { getFormattedDate } from '@vkph/common/utils';

import styles from './NewsListItem.scss';

type Props = {
  newsArticle: NewsArticle;
};

const borderRadius: CSSProperties = { borderRadius: 2 };

export const NewsListItem: FC<Props> = (props) => {
  const { newsArticle } = props;
  const { cover, title, publishedAt, id, type } = newsArticle;
  const { to, state } = useLongreadLink({ type: LongreadTypes.News, id, newsType: type || PostTypes.Simple });

  return (
    <Link className={styles.newsListItem} to={to} state={state}>
      <UiSpace align="start" style={{ marginRight: 16 }}>
        <UiImage src={cover} width={90} style={borderRadius} wrapperStyle={borderRadius} />
      </UiSpace>

      <div className={styles.newsListItem__body}>
        <UiTypography.Text className={styles.newsListItem__title}>
          <UiTruncateMarkup truncate lines={3}>
            {title}
          </UiTruncateMarkup>
        </UiTypography.Text>

        <UiTypography.Text className={styles.newsListItem__date}>
          {getFormattedDate(publishedAt, 'dd MMMM в H:mm')}
        </UiTypography.Text>
      </div>
    </Link>
  );
};
