import { UiIcon } from '@vkph/ui';
import classNames from 'classnames';
import React, { HTMLAttributes, KeyboardEvent } from 'react';

import { EmojiUuid } from '@vkph/common/types/models';

import { ReactionHandler } from '../..';
import { reactionsIcons } from '../constants';
import styles from './ReactionsLikesIcon.scss';

interface Props extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  id: EmojiUuid;
  iconName: string;
  onReact: ReactionHandler;
  size?: number;
}

export const ReactionsLikesIcon: React.FC<Props> = (props) => {
  const { id, iconName, onReact, size = 20, className } = props;

  const onIconKeyPress = ({ key }: KeyboardEvent, emojiUuid?: EmojiUuid) => {
    if (key === 'Enter') {
      onReact(emojiUuid);
    }
  };

  const Icon = reactionsIcons.find((icon) => icon.name === iconName)?.icon;

  return (
    <div
      tabIndex={-1}
      className={classNames(styles.reactionsIcon, className)}
      onClick={(e) => {
        e.stopPropagation();
        onReact(id);
      }}
      role="button"
      onKeyPress={(event) => onIconKeyPress(event, id)}
    >
      {Icon && <UiIcon width={size} height={size} component={Icon} />}
    </div>
  );
};
