// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bdf816cc_ck-content .bdf816cc_video{display:table;clear:both;text-align:center;margin:.9em auto;min-width:50px}.bdf816cc_ck-content .bdf816cc_video video{display:block;margin:0 auto;max-width:100%;min-width:100%;height:auto}.bdf816cc_ck.bdf816cc_ck-editor__editable .bdf816cc_video>figcaption.bdf816cc_ck-placeholder::before{padding-left:inherit;padding-right:inherit;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.bdf816cc_ck.bdf816cc_ck-editor__editable .bdf816cc_video{z-index:1}.bdf816cc_ck.bdf816cc_ck-editor__editable .bdf816cc_video.bdf816cc_ck-widget_selected{z-index:2}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/editor/plugins/video/lib/theme/video.scss"],"names":[],"mappings":"AAEE,qCACE,aAAA,CACA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CAEA,2CACE,aAAA,CACA,aAAA,CACA,cAAA,CACA,cAAA,CACA,WAAA,CAMJ,qGACE,oBAAA,CACA,qBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,0DACE,SAAA,CAEA,sFACE,SAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ck-content {\n  & .video {\n    display: table;\n    clear: both;\n    text-align: center;\n    margin: 0.9em auto;\n    min-width: 50px;\n\n    & video {\n      display: block;\n      margin: 0 auto;\n      max-width: 100%;\n      min-width: 100%;\n      height: auto;\n    }\n  }\n}\n\n.ck.ck-editor__editable {\n  & .video > figcaption.ck-placeholder::before {\n    padding-left: inherit;\n    padding-right: inherit;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  & .video {\n    z-index: 1;\n\n    &.ck-widget_selected {\n      z-index: 2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ck-content": "bdf816cc_ck-content",
	"ckContent": "bdf816cc_ck-content",
	"video": "bdf816cc_video",
	"ck": "bdf816cc_ck",
	"ck-editor__editable": "bdf816cc_ck-editor__editable",
	"ckEditor__editable": "bdf816cc_ck-editor__editable",
	"ck-placeholder": "bdf816cc_ck-placeholder",
	"ckPlaceholder": "bdf816cc_ck-placeholder",
	"ck-widget_selected": "bdf816cc_ck-widget_selected",
	"ckWidget_selected": "bdf816cc_ck-widget_selected"
};
export default ___CSS_LOADER_EXPORT___;
