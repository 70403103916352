import { UiEmpty, UiTypography } from '@vkph/ui';
import isString from 'lodash/isString';
import React, { FC, useMemo, ReactNode } from 'react';
import { FallbackProps } from 'react-error-boundary';

export interface ErrorBoundaryFallbackProps extends FallbackProps {
  description?: ReactNode;
}

export const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = (props) => {
  const { error, description } = props;

  const descriptionError = useMemo<ReactNode>(() => {
    if (description) {
      return description;
    }

    if (isString(error?.message)) {
      return error.message;
    }

    if (isString(error)) {
      return error;
    }

    return '';
  }, [error]);

  return (
    <UiEmpty.Feed
      emptyMessage={{
        header: 'Произошла ошибка',
        description: isString(descriptionError) ? (
          <UiTypography.Text type="secondary">{descriptionError}</UiTypography.Text>
        ) : (
          descriptionError
        ),
      }}
    />
  );
};
