import { UiTypographyTextTypes, UiIcon, UiIconLabelProps } from '@vkph/ui';
import React, { FC } from 'react';

import { useTheme, variables } from '@vkph/ui/providers/theme';
import CoinSvg from '@vkph/ui/svg/coin.svg';

type IconColorType = 'primary' | 'secondary' | 'negative';

export interface AchievementCoinsProps extends Omit<UiIconLabelProps, 'component'> {
  iconType?: IconColorType;
}

const baseProps: Partial<AchievementCoinsProps> = {
  width: 20,
  height: 20,
  type: UiTypographyTextTypes.Secondary,
};

const iconTypeDefault: IconColorType = 'primary';

export const AchievementCoins: FC<AchievementCoinsProps> = (props) => {
  const { iconType = iconTypeDefault, ...iconLabelProps } = props;
  const [{ variables: themeVariables }] = useTheme();

  const color: Record<IconColorType, UiIconLabelProps['color']> = {
    primary: themeVariables.colorBrand,
    secondary: variables.themePalette.colorIcon,
    negative: variables.themePalette.colorNegative,
  };

  return <UiIcon.Label {...baseProps} color={color[iconType]} {...iconLabelProps} component={CoinSvg} />;
};
