import { UiImage, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { capitalizeFirstLetter } from '@vkph/common/utils';

import styles from './SocialLink.scss';

type Props = {
  name?: string;
  value: string;
  iconUrl?: string;
  prefixUrl?: string;
};

const createSocialLink = (prefixUrl: string, userName: string): string => `https://${prefixUrl}${userName}`;

const SIZE = 32;

export const SocialLink: FC<Props> = (props) => {
  const { name, value, prefixUrl, iconUrl } = props;
  const [isImageError, setIsImageError] = useState(false);
  const to = prefixUrl ? createSocialLink(prefixUrl, value) : value;

  const placeholder = (
    <UiTypography.Footnote style={{ borderRadius: 32 }} className={styles.socialLink}>
      <UiTruncateMarkup truncate>{capitalizeFirstLetter(name)}</UiTruncateMarkup>
    </UiTypography.Footnote>
  );

  return (
    <Link to={to} target="_blank">
      {(!iconUrl || isImageError) && placeholder}
      {iconUrl && !isImageError && (
        <UiImage
          width={SIZE}
          height={SIZE}
          src={iconUrl}
          placeholder={null}
          onError={() => setIsImageError(true)}
        />
      )}
    </Link>
  );
};
