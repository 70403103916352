import { UiRow, UiInput, UiList, UiListProps, UiSpace } from '@vkph/ui';
import React, { ChangeEvent, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { UserProfileModel } from '@vkph/common/types/models';
import { createArrayMock, getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';

import { LinkListItem } from '../link-list-item';

const MOCKS_COUNT = 3;

export type UserListActor = Pick<UserProfileModel, 'id' | 'avatar' | 'fullName' | 'position'>;

interface Props extends UiListProps<UserListActor> {
  users: UserListActor[];
  onSearch?: (query: string) => void;
  loading?: boolean;
}

export const UserList: FC<Props> = (props) => {
  const { users, loading, onSearch, ...rest } = props;

  const onInputChangeDebounced = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    onSearch?.(e.target.value);
  }, 500);

  return (
    <UiSpace size={20} full direction="vertical">
      {onSearch && (
        <UiRow style={{ padding: '0 24px' }}>
          <UiInput.Search size="large" placeholder="Поиск" onChange={onInputChangeDebounced} />
        </UiRow>
      )}
      {loading && createArrayMock(MOCKS_COUNT, (_, key) => <LinkListItem.Skeleton key={key} />)}
      {!loading && (
        <UiList
          {...rest}
          split={false}
          dataSource={users}
          renderItem={(user) => {
            return (
              <UiList.Item noStyle>
                <LinkListItem
                  to={getRoutePath(RouteNames.Profile, { id: user.id })}
                  avatarProps={{ src: user.avatar }}
                  title={getFullNameWithoutPatronymic(user.fullName)}
                  subtitle={user.position?.name}
                />
              </UiList.Item>
            );
          }}
        />
      )}
    </UiSpace>
  );
};
