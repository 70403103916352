// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6e2957c5_poll-group{display:flex;flex-direction:column}._6e2957c5_poll-group label[class~=ant-radio-wrapper]:not(:last-child),._6e2957c5_poll-group label[class~=ant-checkbox-wrapper]:not(:last-child){margin-bottom:8px}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/poll/poll-group/PollGroup.scss"],"names":[],"mappings":"AACA,sBAEE,YAAA,CACA,qBAAA,CAGE,iJAEE,iBAPE","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.poll-group {\n  $gap: 8px;\n  display: flex;\n  flex-direction: column;\n\n  :global {\n    label[class~='ant-radio-wrapper']:not(:last-child),\n    label[class~='ant-checkbox-wrapper']:not(:last-child) {\n      margin-bottom: $gap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-group": "_6e2957c5_poll-group",
	"pollGroup": "_6e2957c5_poll-group"
};
export default ___CSS_LOADER_EXPORT___;
