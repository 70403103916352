import { UiIcon, UiIconLabelProps } from '@vkph/ui';
import React, { FC } from 'react';

import { numberAbbreviator } from '@vkph/common/utils';
import CommentSvg from '@vkph/ui/svg/comment.svg';

export interface ReactionsCommentsProps extends Pick<UiIconLabelProps, 'style'> {
  comments: number;
}

export const ReactionsComments: FC<ReactionsCommentsProps> = (props) => {
  const { comments, style } = props;

  return <UiIcon.Label component={CommentSvg} label={numberAbbreviator(comments)} style={style} />;
};
