import { UiButton, UiIcon, UiImage, message } from '@vkph/ui';
import React, { FC } from 'react';

import { useClipboard } from '@vkph/common/hooks';
import { getHrefByPathname, isValidURL } from '@vkph/common/utils';
import ClearSvg from '@vkph/ui/svg/clear.svg';
import { downloadFileByURL } from '@vkph/ui/utils';

import { ImageListImage, ImageListProps } from '..';
import styles from '../ImageList.scss';

interface Props extends Omit<ImageListProps, 'images'> {
  image: ImageListImage;
}

export const ImageListItem: FC<Props> = (props) => {
  const { image, onDelete } = props;
  const { url, id } = image;
  const imageSrc = isValidURL(url) ? url : getHrefByPathname(url);

  const [onShare] = useClipboard(imageSrc, { onSuccess: () => message.success('Ссылка  скопирована') });
  const onDownload = () => {
    message.info('Загрузка началась');

    downloadFileByURL(imageSrc)
      .then((name) => message.success(`Файл "${name}" загружен`))
      .catch(() => message.error('Ошибка загрузки'));
  };

  return (
    <div key={image.id} className={styles.imageList__imageWrapper}>
      <UiImage
        preview={{
          src: imageSrc,
          customize: { onDownload, onShare },
        }}
        width="100%"
        height="100%"
        src={image.url}
      />
      {onDelete && (
        <UiButton
          type="link-secondary"
          className={styles.imageList__delete}
          onClick={() => onDelete(id)}
          icon={<UiIcon width={20} height={20} component={ClearSvg} />}
        />
      )}
    </div>
  );
};
