import {
  UiFile,
  UiFileDirection,
  UiFileProps,
  UiCol,
  UiRow,
  UiIcon,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { FileStorageEntryType } from '@vkph/common/types/models';
import { getFullName, numberAbbreviator, getTotalSumByKey } from '@vkph/common/utils';
import CommentAltSvg from '@vkph/ui/svg/comment-alt.svg';
import LikeSvg from '@vkph/ui/svg/like.svg';
import ViewSvg from '@vkph/ui/svg/view.svg';

import { File } from '../../../file';
import { FileListItemProps } from '../FileListItem';

type Props = Pick<FileListItemProps, 'file' | 'actions'> & UiFileProps;

export const FileListItemRow: FC<Props> = (props) => {
  const { file, actions = [], ...fileProps } = props;
  const { objectViewsCount, commentsCount, reactions, createdBy } = file;
  const reactionsCount = useMemo(() => getTotalSumByKey(reactions, 'reactionCount'), [reactions]);
  const author = createdBy ? getFullName(createdBy) : 'Автор неизвестен';
  const isFolder = file.type === FileStorageEntryType.Folder;

  return (
    <UiRow style={{ width: '100%' }} align="middle" gutter={[16, 0]}>
      <UiCol span={20} xl={12} xxl={9}>
        <UiFile direction={UiFileDirection.Horizontal} {...fileProps} />
      </UiCol>
      <UiCol span={0} xl={8} xxl={6}>
        <UiTypography.Text strong>
          <UiTruncateMarkup truncate tooltipProps={{ title: author }}>
            {author}
          </UiTruncateMarkup>
        </UiTypography.Text>
      </UiCol>
      {!isFolder && (
        <>
          <UiCol span={0} xxl={2}>
            <UiIcon.Label component={ViewSvg}>{numberAbbreviator(objectViewsCount)}</UiIcon.Label>
          </UiCol>
          <UiCol span={0} xxl={2}>
            <UiIcon.Label component={LikeSvg}>{numberAbbreviator(reactionsCount)}</UiIcon.Label>
          </UiCol>
          <UiCol span={0} xxl={2}>
            <UiIcon.Label component={CommentAltSvg}>{numberAbbreviator(commentsCount)}</UiIcon.Label>
          </UiCol>
        </>
      )}
      <UiCol span={4} xxl={isFolder ? 9 : 3}>
        <UiRow justify="end">
          <File.Actions actions={actions} />
        </UiRow>
      </UiCol>
    </UiRow>
  );
};
