import { UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import { ProjectsStageModel } from '@vkph/common/types/models';

import {
  getAllMonthsOfInterval,
  getCountDaysForYearsInInterval,
  getStagesInterval,
} from '../../../helpers/gantt-date-helper';
import { DisplayOptionsFull } from '../../GanttFull';
import styles from './GanttFullHeaderYears.scss';

const LAST_MONTH = 11;

interface Props {
  displayOptions: DisplayOptionsFull;
  stages: ProjectsStageModel[];
}

export const GanttFullHeaderYears: FC<Props> = (props) => {
  const { displayOptions, stages } = props;
  const { daySize } = displayOptions;
  const { timeStart, timeEnd } = getStagesInterval(stages);
  const months = getAllMonthsOfInterval(timeStart, timeEnd);
  const years = getCountDaysForYearsInInterval(months);

  return (
    <div className={styles.ganttFullHeaderYears}>
      <div className={styles.ganttFullHeaderYears__years}>
        {years.map(({ year, days }) => (
          <div
            key={year}
            className={styles.ganttFullHeaderYears__yearsItem}
            style={{ minWidth: days * daySize }}
          >
            <UiTypography.Title level={4}>{year}</UiTypography.Title>
          </div>
        ))}
      </div>
      <div className={styles.ganttFullHeaderYears__months}>
        {months.map(({ id, name, days, month }) => (
          <div
            key={id}
            className={classNames(styles.ganttFullHeaderYears__monthsItem, {
              [styles.ganttFullHeaderYears__months_last]: month === LAST_MONTH,
            })}
            style={{ minWidth: days * daySize }}
          >
            <UiTypography.Title level={4}>{name}</UiTypography.Title>
          </div>
        ))}
      </div>
    </div>
  );
};
