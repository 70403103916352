import { UiBadge, UiCol, UiRow, UiIcon, UiSpace, UiSpinner } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useState, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getTasksStorage } from '@vkph/common/store/tasks';
import { BaseTimeInterval, ProjectsStageModel } from '@vkph/common/types/models';
import { variables } from '@vkph/ui/providers/theme';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';

import { DisplayOptions } from '../../compact/GanttCompact';
import {
  getOffsetInDays,
  getReferenceTimeMonthsOfInterval,
  getReferenceTimeWeeksOfInterval,
} from '../../helpers/gantt-date-helper';
import { GanttRangeTooltip } from '../../range-tooltip/GanttRangeTooltip';
import { GanttViewType, ganttFilterStore } from '../../store';
import { GanttFullTasks } from '../tasks/GanttFullTasks';
import styles from './GanttFullStage.scss';

interface GanttFullStageProps extends ProjectsStageModel {
  daySize: DisplayOptions['daySize'];
  stagesInterval: Partial<BaseTimeInterval>;
}

export const GanttFullStage: FC<GanttFullStageProps> = (props) => {
  const { daySize, stagesInterval, ...stage } = props;
  const { title, color, timeStart, timeEnd } = stage;
  const { timeStart: stagesTimeStart, timeEnd: stagesTimeEnd } = stagesInterval;
  const [isExpanded, setIsExpanded] = useState(false);

  const { viewType, ...filterParams } = useStore(ganttFilterStore.store);

  const tasksStorages = useMemo(getTasksStorage, [stage.id]);
  const { data: tasksData, loading: isTasksLoading } = useAbstractStorage(tasksStorages.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { stageId: stage.id, ...filterParams },
    cancelPendingRequestOnUnmount: true,
  });

  const isActive = (type: GanttViewType) => type === viewType;
  const isTasksExist = tasksData.length > 0;
  const referenceTime = isActive(GanttViewType.Year)
    ? getReferenceTimeMonthsOfInterval(stagesTimeStart, stagesTimeEnd)
    : getReferenceTimeWeeksOfInterval(stagesTimeStart);
  const intervalWidth = getOffsetInDays(timeStart, timeEnd) * daySize;
  const startPosition = getOffsetInDays(referenceTime, timeStart) * daySize;

  return (
    <>
      <UiRow
        gutter={[0, 8]}
        onClick={() => setIsExpanded((prev) => !prev)}
        style={{
          width: intervalWidth,
          marginLeft: startPosition,
          ...(isTasksExist && { cursor: 'pointer' }),
        }}
      >
        <UiCol span={24}>
          <UiSpinner spinning={isTasksLoading}>
            <UiSpace>
              <UiBadge text={title} count={tasksData.length} reverse showZero />
              {isTasksExist && (
                <UiIcon
                  style={{
                    display: 'flex',
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    color: variables.themePalette.colorIcon,
                  }}
                  component={() => <ArrowExpandSvg />}
                />
              )}
            </UiSpace>
          </UiSpinner>
        </UiCol>
        <GanttRangeTooltip timeStart={timeStart} timeEnd={timeEnd} compact>
          <UiCol
            span={24}
            className={styles.ganttFullStage__timeline}
            style={{
              backgroundColor: color,
            }}
          />
        </GanttRangeTooltip>
      </UiRow>
      {isTasksExist && isExpanded && (
        <GanttFullTasks
          daySize={daySize}
          referenceTime={referenceTime}
          stage={stage}
          storages={tasksStorages}
        />
      )}
    </>
  );
};
