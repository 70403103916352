import { UiAvatarSize, UiSkeleton, UiSpace, UiSpaceProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { CSSProperties, FC } from 'react';

import { UiUserInfoProps } from '../../UiUser';

export type UiUserInfoSkeletonProps = {
  className?: UiUserInfoProps['className'];
  size?: UiAvatarSize;
  style?: CSSProperties;
  align?: UiSpaceProps['align'];
};

export const UiUserInfoSkeleton: FC<UiUserInfoSkeletonProps> = (props) => {
  const { style, className, size = UiAvatarSize.S, align = 'center' } = props;

  const skeletonHeight = size <= UiAvatarSize.XS ? 12 : 15;

  return (
    <UiSpace style={style} className={classNames(className)} align={align}>
      <UiSkeleton loading circle width={size} height={size} />
      <UiSpace direction="vertical" size={0}>
        <UiSkeleton loading width={200} height={skeletonHeight} style={{ marginBottom: 2 }} />
        <UiSkeleton loading width={130} height={skeletonHeight} />
      </UiSpace>
    </UiSpace>
  );
};
