import { UiBreadcrumb, UiBreadcrumbProps } from '@vkph/ui';
import React, { FC } from 'react';

import { useBreadcrumbItemsHistory } from '../../hooks';

export const BreadcrumbHistory: FC<Omit<UiBreadcrumbProps, 'itemRender'>> = (props) => {
  const { prefixCls } = props;
  const { itemRender } = useBreadcrumbItemsHistory({ prefixCls });

  return <UiBreadcrumb {...props} itemRender={itemRender} />;
};
