import React, { FC, KeyboardEventHandler, PropsWithChildren, useRef } from 'react';

import { FileToUploadModel } from '@vkph/common/types/models';
import { checkFileMimeType, FileUploadAccepts, readFileToStringPromise } from '@vkph/common/utils';

type Props = {
  onAddFiles: (files: FileToUploadModel[]) => void;
  multiple?: boolean;
  className?: string;
  accept?: FileUploadAccepts;
};

// TODO подумать над тем, как объединить с UiUploader
export const FilePicker: FC<PropsWithChildren<Props>> = (props) => {
  const { accept = FileUploadAccepts.All, children, onAddFiles, multiple = false, className } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const onTriggerClick = () => inputRef.current?.click();
  const onTriggerKeyPress: KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      onTriggerClick();
    }
  };

  const handleAddFiles = (files: FileList | null) => {
    if (files && files.length) {
      Promise.all(
        Array.from(files)
          .filter((file) => checkFileMimeType(accept, file.type))
          .map((file) => readFileToStringPromise(file, true)),
      ).then(onAddFiles);
    }
  };

  return (
    <div className={className}>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          handleAddFiles(e.target.files);
        }}
      />
      <div tabIndex={-1} role="button" onKeyPress={onTriggerKeyPress} onClick={onTriggerClick}>
        {children}
      </div>
    </div>
  );
};
