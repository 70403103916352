import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { ProjectsStageModel } from '@vkph/common/types/models';

import { isAllMonthsOfInterval } from '../../guard/gantt-guard';
import {
  getAllMonthsOfInterval,
  getAllWeeksOfInterval,
  getStagesInterval,
} from '../../helpers/gantt-date-helper';
import { ganttFilterStore, GanttViewType } from '../../store';
import { DisplayOptionsFull } from '../GanttFull';
import { GanttFullStage } from '../stage/GanttFullStage';
import styles from './GanttFullContent.scss';

const DAYS_IN_WEEK = 7;

interface Props {
  displayOptions: DisplayOptionsFull;
  stages: ProjectsStageModel[];
}

export const GanttFullContent: FC<Props> = (props) => {
  const { stages, displayOptions } = props;
  const { daySize, scrollHeight, headerHeight } = displayOptions;
  const stagesInterval = getStagesInterval(stages);
  const { timeStart, timeEnd } = stagesInterval;
  const { viewType } = useStore(ganttFilterStore.store);

  const isActive = (type: GanttViewType) => type === viewType;

  const intervalItems = isActive(GanttViewType.Year)
    ? getAllMonthsOfInterval(timeStart, timeEnd)
    : getAllWeeksOfInterval(timeStart, timeEnd);

  return (
    <div className={styles.ganttFullContent__container} style={{ height: `calc(100% - ${headerHeight}px)` }}>
      <div className={styles.ganttFullContent__background} style={{ minHeight: scrollHeight - headerHeight }}>
        {intervalItems.map((month) => {
          const minWidth = (isAllMonthsOfInterval(month) ? month.days : DAYS_IN_WEEK) * daySize;

          return (
            <div key={month.id} className={styles.ganttFullContent__backgroundStages} style={{ minWidth }} />
          );
        })}
      </div>
      <div className={styles.ganttFullContent__content}>
        {stages.map((item) => (
          <GanttFullStage daySize={daySize} stagesInterval={stagesInterval} key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};
