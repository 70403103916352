import { UiIcon, UiTruncateMarkup, UiCheckboxOptionType } from '@vkph/ui';
import classnames from 'classnames';
import React, { FC } from 'react';

import { SidebarType } from '../menu/SidebarMenu';
import styles from './SidebarMenuItem.scss';

export type SidebarItem = UiCheckboxOptionType & {
  iconLeft?: SvgrComponent;
  iconRight?: SvgrComponent;
};

type SidebarMenuItemProps = {
  item: SidebarItem;
  type: SidebarType;
};

export type SidebarMenuItemComponent = FC<SidebarMenuItemProps>;

export const SidebarMenuItem: FC<SidebarMenuItemProps> = (props) => {
  const { item, type } = props;
  const { label, disabled = false, iconLeft, iconRight } = item;

  return (
    <div
      className={classnames(styles.sidebarMenuItem, {
        [styles.sidebarMenuItem_compact]: type === SidebarType.Compact,
        [styles.sidebarMenuItem_disabled]: disabled,
      })}
    >
      {iconLeft && (
        <UiIcon.Circle
          className={styles.sidebarMenuItem__leftIcon}
          type="primary"
          component={iconLeft}
          width={20}
          height={20}
        />
      )}

      <div className={styles.sidebarMenuItem__main}>
        <div className={styles.sidebarMenuItem__title}>
          <UiTruncateMarkup lines={2} truncate>
            {label}
          </UiTruncateMarkup>
          {iconRight && (
            <UiIcon
              className={styles.sidebarMenuItem__rightIcon}
              component={iconRight}
              width={20}
              height={20}
            />
          )}
        </div>
      </div>
    </div>
  );
};
