import { UiCol, UiList } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';

export type FileActionsProps = {
  actions: ReactNode[];
};

export const FileActions: FC<FileActionsProps> = (props) => {
  const { actions } = props;

  return (
    <UiList grid={{ gutter: 16 }} dataSource={actions} renderItem={(action) => <UiCol>{action}</UiCol>} />
  );
};
