import { BasicPostAttachmentModel, PostAttachmentTypes } from '@vkph/common/types/models';
import { hashMapMaker } from '@vkph/common/utils';

const attachmentGroupsPriority = [
  PostAttachmentTypes.Poll,
  PostAttachmentTypes.Image,
  PostAttachmentTypes.File,
  PostAttachmentTypes.Event,
];

export type PostBodyAttachmentsGroup = {
  attachmentType: PostAttachmentTypes;
  attachments: BasicPostAttachmentModel[];
};

export const groupAttachments = (attachments: BasicPostAttachmentModel[]): PostBodyAttachmentsGroup[] => {
  const attachmentsByType = hashMapMaker(attachments, 'attachmentType', true);

  return attachmentGroupsPriority.map((attachmentType) => ({
    attachmentType,
    attachments: attachmentsByType[attachmentType] || [],
  }));
};
