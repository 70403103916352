import { UiAvatarSize, UiCell, UiOptionDataExtended } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { useState } from 'react';

import { useAbstractStorage, UseAbstractStorageParams } from '@vkph/common/hooks';
import { useProfileStorage } from '@vkph/common/store/profile/hooks';
import { AutoCompleteListStorage } from '@vkph/common/types/autoCompleteList';
import { UserModel } from '@vkph/common/types/models';

import { SearchOptionComplete, SearchOptionCompleteType, SearchOptionItem } from '../search-option-complete';
import { UserAvatar } from '../user-avatar';

export interface SearchUserFieldOptionItem extends SearchOptionItem {
  value: string;
}

interface SearchUserFieldBaseParam extends Record<string, unknown> {
  query?: string;
}

interface SearchUserFieldProps<SP>
  extends Omit<SearchOptionCompleteType, 'value'>,
    UseAbstractStorageParams<UiOptionDataExtended<UserModel>[], UiOptionDataExtended<UserModel>[], SP> {
  value?: SearchUserFieldOptionItem;
  storage: AutoCompleteListStorage;
  normalizeQueryParam?: (query: string) => SP;
}

export const SearchUserField = <SP extends SearchUserFieldBaseParam>(props: SearchUserFieldProps<SP>) => {
  const {
    value,
    storage,
    notFoundContent,
    autoFetchParams,
    autoFetchAndRefetch = true,
    resetStoreOnUnmount = true,
    cancelPendingRequestOnUnmount = true,
    normalizeQueryParam = (query) => ({ query }),
    ...restProps
  } = props;
  const [searchQuery, setSearchQuery] = useState('');

  const { fetchFx: fetchList } = useAbstractStorage(storage, {
    autoFetchAndRefetch,
    cancelPendingRequestOnUnmount,
    autoFetchParams,
    resetStoreOnUnmount,
    resetStoreOnChangeParams: { searchQuery },
  });

  const { data: profile } = useProfileStorage({ id: value?.value, deps: [value] });
  const onUpdate = (query: string) => fetchList({ ...normalizeQueryParam(query), ...autoFetchParams });

  const options = useStoreMap(storage.store, ({ data }) => {
    return data.map((item) => ({
      value: item.value,
      label: (
        <UiCell
          title={item.label}
          avatar={<UserAvatar size={UiAvatarSize.S} src={item?.data?.smallAvatar} />}
        />
      ),
      name: item.label,
    }));
  });

  return (
    <SearchOptionComplete
      placeholder="Выбрать"
      size="large"
      options={options}
      value={value}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      updateOptionListHandler={onUpdate}
      notFoundContent={notFoundContent}
      prefix={<UserAvatar size={UiAvatarSize.XS} src={profile?.smallAvatar} isActive={profile?.isActive} />}
      {...restProps}
    />
  );
};
