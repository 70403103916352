import { UiTooltip, UiTooltipProps, UiButton, UiButtonProps, UiIcon, UiFormItemProps } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import SortingDownSVG from '@vkph/ui/svg/sorting-down.svg';
import SortingUpSVG from '@vkph/ui/svg/sorting-up.svg';

export interface OrderSwitcherProps
  extends Omit<UiButtonProps, 'onChange' | 'value' | 'onClick'>,
    Pick<UiFormItemProps<boolean>, 'value'> {
  onChange?: (value: boolean) => void;
  tooltipTitle?: UiTooltipProps['title'];
}

// TODO:: Удалить и поменять места использования после создания UI-компонента ToggleButton (B2BCORE-7878)
export const OrderSwitcher: FC<OrderSwitcherProps> = (props) => {
  const { onChange, value = false, tooltipTitle, icon, type = 'link', ...restProps } = props;

  const iconSVG = value ? SortingDownSVG : SortingUpSVG;
  const iconComponent = useMemo(
    () => icon || <UiIcon component={iconSVG} width={20} height={20} />,
    [icon, iconSVG],
  );

  const onBtnClick = () => onChange?.(!value);

  const button = (
    <UiButton {...restProps} disabledFocus onClick={onBtnClick} type={type} icon={iconComponent} />
  );

  return (
    <>
      {tooltipTitle && <UiTooltip title={tooltipTitle}>{button}</UiTooltip>}
      {!tooltipTitle && button}
    </>
  );
};
