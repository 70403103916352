import {
  UiButton,
  UiButtonProps,
  UiButtonDecoratorProps,
  UiFullscreen,
  UiCol,
  UiRow,
  UiIcon,
  UiIconProps,
  UiList,
  UiOverlay,
  UiTypography,
  UiTypographyTextTypes,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { ChangeEventHandler, FC, MouseEventHandler, useState, useRef, PropsWithChildren } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { AbsolutePlacement, useAbsolutePlacementShift, useFullscreen, useSpace } from '@vkph/ui/hooks';
import ExitFullscreenSvg from '@vkph/ui/svg/exit-fullscreen.svg';
import FullscreenSvg from '@vkph/ui/svg/fullscreen.svg';
import MuteSvg from '@vkph/ui/svg/mute.svg';
import PauseSvg from '@vkph/ui/svg/pause.svg';
import PlaySvg from '@vkph/ui/svg/play.svg';
import VolumeSvg from '@vkph/ui/svg/volume.svg';

import styles from './VideoPlayer.scss';

interface VideoPlayerAction extends Pick<UiButtonProps, 'disabled'> {
  onClick: () => void;
  icon: UiIconProps['component'];
}

interface PlayerPropsAdditional extends Pick<UiButtonDecoratorProps, 'onClick'> {
  containerWidth?: number;
  containerHeight?: number;
  isThumbnail?: boolean;
  actions?: VideoPlayerAction[];
  playButtonWidth?: number;
  playButtonHeight?: number;
}

type PlayerStateAdditional = {
  mutedVolume: number;
};

export type VideoPlayerProps = PropsWithChildren<ReactPlayerProps & PlayerPropsAdditional>;
type VideoPlayerState = ReactPlayerProps & PlayerStateAdditional;

const DEFAULT_PLAYER_STATE: VideoPlayerState = {
  played: 0,
  loaded: 0,
  pip: false,
  loop: false,
  duration: 0,
  volume: 0.8,
  light: false,
  muted: false,
  seeking: false,
  playing: false,
  controls: false,
  mutedVolume: 0.8,
  playbackRate: 1.0,
};

const getDuration = (seconds: number) => {
  const pad = (value: number) => {
    return `0${value}`.slice(-2);
  };

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }

  return `${mm}:${ss}`;
};

export const VideoPlayer: FC<VideoPlayerProps> = (props) => {
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const [playerState, setPlayerState] = useState<VideoPlayerState>(DEFAULT_PLAYER_STATE);
  const fullscreen = useFullscreen();
  const { isEnabled, isAvailable, onToggle } = fullscreen;
  const { spaceXL, spaceS } = useSpace();
  const { placementCls, placementStyles } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.Center,
  });

  const { duration, played, playing, volume, muted, seeking } = playerState;
  const {
    url,
    isThumbnail,
    containerWidth,
    containerHeight,
    onClick,
    actions,
    playButtonHeight = 60,
    playButtonWidth = 60,
  } = props;

  const onDuration = (value: number) => {
    setPlayerState((prevState) => ({ ...prevState, duration: value }));
  };

  const onSeekChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;

    setPlayerState((prevState) => ({ ...prevState, played: Number(value) }));
  };

  const onSeekMouseDown = () => {
    setPlayerState((prevState) => ({ ...prevState, seeking: true }));
  };

  const onSeekMouseUp: MouseEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.currentTarget;

    reactPlayerRef?.current?.seekTo(Number(value));
    setPlayerState((prevState) => ({ ...prevState, seeking: false }));
  };

  const onProgress: ReactPlayerProps['onProgress'] = (progress) => {
    if (!seeking) {
      const { played: currentPlayed, loaded: currentLoaded = 0 } = progress;

      setPlayerState((prevState) => ({
        ...prevState,
        played: currentPlayed,
        loaded: currentLoaded,
      }));
    }
  };

  const onTogglePlay = () => {
    setPlayerState((prevState) => ({ ...prevState, playing: !prevState.playing }));
  };

  const onChangeVolume: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    const numberValue = Number(value);

    setPlayerState((prevState) => ({
      ...prevState,
      volume: numberValue,
      muted: numberValue === 0,
      mutedVolume: numberValue,
    }));
  };

  const onToggleMute = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      muted: !prevState.muted,
      volume: !prevState.muted ? 0 : prevState.mutedVolume || DEFAULT_PLAYER_STATE.mutedVolume,
      mutedVolume: prevState.mutedVolume,
    }));
  };

  const defaultButtonProps: UiButtonProps = {
    disabledFocus: true,
    type: 'link',
    size: 'small',
  };

  const defaultIconProps: UiIconProps = {
    color: 'white',
    width: 30,
    height: 30,
  };

  return (
    <UiFullscreen
      fullscreen={fullscreen}
      onClick={onClick}
      className={classNames(styles.videoPlayer, [
        {
          [styles.videoPlayer_cover]: isThumbnail,
          'react-player-container': !isThumbnail,
        },
      ])}
    >
      <UiOverlay
        style={{ width: 'auto' }}
        overlayStyles={{
          background: playing ? 'transparent' : 'rgba(0,0,0,.5)',
        }}
        content={
          <div style={{ width: containerWidth, height: containerHeight }}>
            {!playing && (
              <UiButton
                type="primary"
                style={{
                  ...placementStyles,
                  pointerEvents: isThumbnail ? 'none' : 'all',
                  width: playButtonWidth,
                  height: playButtonHeight,
                }}
                disabledFocus
                disabled={!onClick}
                icon={
                  <UiIcon component={PlaySvg} width={playButtonWidth / 2} height={playButtonHeight / 2} />
                }
                className={classNames(styles.videoPlayer__playButton, placementCls)}
                onClick={onTogglePlay}
              />
            )}
            {!isThumbnail && (
              <UiRow wrap={false} align="middle" className={styles.videoPlayer__controls}>
                <UiCol flex="1 1 50%">
                  <div className={styles.videoPlayer__playback}>
                    <UiButton
                      {...defaultButtonProps}
                      onClick={onTogglePlay}
                      icon={<UiIcon {...defaultIconProps} component={playing ? PauseSvg : PlaySvg} />}
                    />
                    <input
                      type="range"
                      className={styles.videoPlayer__slider}
                      step="any"
                      value={played}
                      min={0}
                      max={0.999999}
                      onMouseDown={onSeekMouseDown}
                      onMouseUp={onSeekMouseUp}
                      onChange={onSeekChange}
                      style={{
                        backgroundSize: `${played * 100}% 100%`,
                      }}
                    />
                    <UiTypography.Text
                      className={styles.videoPlayer__duration}
                      type={UiTypographyTextTypes.Invert}
                    >
                      {getDuration(duration * (1 - played))}
                    </UiTypography.Text>
                  </div>
                </UiCol>
                <UiCol flex="1 1 10%">
                  <div className={styles.videoPlayer__volume}>
                    <UiButton
                      {...defaultButtonProps}
                      onClick={onToggleMute}
                      icon={<UiIcon {...defaultIconProps} component={muted ? MuteSvg : VolumeSvg} />}
                    />
                    <input
                      type="range"
                      step="any"
                      min={0}
                      max={1}
                      onChange={onChangeVolume}
                      value={volume}
                      className={classNames(styles.videoPlayer__slider)}
                      style={{
                        backgroundSize: `${(volume || 0) * 100}% 100%`,
                      }}
                    />
                  </div>
                </UiCol>
                <UiCol flex="0 0 auto">
                  <div className={styles.videoPlayer__actions}>
                    {actions?.length && actions.length > 0 && (
                      <UiList
                        grid={{ column: actions.length, gutter: [spaceXL, spaceXL] }}
                        dataSource={actions}
                        renderItem={(action) => {
                          const { icon, ...buttonProps } = action;

                          return (
                            <UiButton
                              {...defaultButtonProps}
                              {...buttonProps}
                              style={{ marginLeft: spaceS }}
                              icon={<UiIcon {...defaultIconProps} component={icon} />}
                            />
                          );
                        }}
                      />
                    )}
                    {isAvailable && (
                      <UiButton
                        {...defaultButtonProps}
                        onClick={onToggle}
                        icon={
                          <UiIcon
                            {...defaultIconProps}
                            component={isEnabled ? ExitFullscreenSvg : FullscreenSvg}
                          />
                        }
                      />
                    )}
                  </div>
                </UiCol>
              </UiRow>
            )}
          </div>
        }
      >
        <ReactPlayer
          ref={reactPlayerRef}
          playing={playing}
          played={played}
          volume={volume}
          muted={muted}
          url={url}
          width={isEnabled ? '100%' : containerWidth}
          height={isEnabled ? '100%' : containerHeight}
          onDuration={onDuration}
          onProgress={onProgress}
          onError={(e) => console.warn('onError', e)}
        />
      </UiOverlay>
    </UiFullscreen>
  );
};
