import { UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';

import { ProjectsStageModel } from '@vkph/common/types/models';

import { getOffsetInDays } from '../../helpers/gantt-date-helper';
import { GanttRangeTooltip } from '../../range-tooltip/GanttRangeTooltip';
import { DisplayOptions } from '../GanttCompact';
import styles from './GanttContentStage.scss';

const MINIMAL_TITLE_WIDTH = 40;

interface GanttContentStageProps extends ProjectsStageModel {
  daySize: number;
  year: DisplayOptions['year'];
}

export const GanttContentStage: FC<GanttContentStageProps> = (props) => {
  const { daySize, year, title, color, timeStart, timeEnd } = props;

  const referenceTime = new Date(year, 0, 1).toDateString();
  const intervalWidth = getOffsetInDays(timeStart, timeEnd) * daySize;
  const startPosition = getOffsetInDays(referenceTime, timeStart) * daySize;
  const isShowTitle = intervalWidth > MINIMAL_TITLE_WIDTH;

  return (
    <GanttRangeTooltip timeStart={timeStart} timeEnd={timeEnd} title={title}>
      <div
        className={styles.ganttContentStage__title}
        style={{
          backgroundColor: color,
          width: intervalWidth,
          marginLeft: startPosition,
        }}
      >
        {isShowTitle && <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>}
      </div>
    </GanttRangeTooltip>
  );
};
