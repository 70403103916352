import React, { FC } from 'react';

import { Markdown } from '../../markdown';
import styles from './EditorViewer.scss';

type EditorViewerProps = {
  html: string;
};

export const EditorViewer: FC<EditorViewerProps> = (props) => {
  const { html } = props;

  return (
    <Markdown htmlMarkup className={styles.editorViewer}>
      {html}
    </Markdown>
  );
};
