import { UiButton, UiButtonProps, UiIcon, message } from '@vkph/ui';
import React from 'react';

import { useClipboard } from '@vkph/common/hooks';
import CopySvg from '@vkph/ui/svg/copy.svg';

interface ClipboardButtonProps extends Omit<UiButtonProps, 'value' | 'onClick'> {
  value: string;
  successMessage?: string;
  successDuration?: number;
}

export const ClipboardButton: React.FC<ClipboardButtonProps> = React.memo(
  ({ value, successMessage = 'Скопировано', ...buttonProps }) => {
    const [setCopied] = useClipboard(value, { onSuccess: () => message.success(successMessage) });

    return (
      <UiButton
        type="link-secondary"
        onClick={setCopied}
        icon={<UiIcon component={CopySvg} width={20} height={20} />}
        {...buttonProps}
      />
    );
  },
);
