import {
  getSizeGutterBySizeFullName,
  SizeGutterPostfix,
  UiConfigProviderSizeContext,
  UiConfigProviderSizeType,
  UiCol,
} from '@vkph/ui';
import React, { FC, PropsWithChildren, useContext } from 'react';

import { usePaddingStyle } from '@vkph/ui/hooks';

import { AVATAR_OFFSET, AVATAR_SIZE } from '../contants';
import { NotificationBodyFooter } from './footer/NotificationBodyFooter';

export type NotificationBodyPropsType = {
  isSpacing?: boolean;
  size?: UiConfigProviderSizeType | number;
};

export type NotificationBodyProps = PropsWithChildren<NotificationBodyPropsType>;

export type NotificationBodyComposition = {
  Footer: typeof NotificationBodyFooter;
};

export type NotificationBodyComponent = FC<NotificationBodyProps> & NotificationBodyComposition;

type SizeNameMap<T> = Record<SizeGutterPostfix, T>;

const gutterSizes: SizeNameMap<number> = { sm: 4, md: 8, lg: 12 };

export const NotificationBody: NotificationBodyComponent = (props) => {
  const { children, isSpacing = true, size: propsSize } = props;
  const isPropSize = propsSize || propsSize === 0;

  const providerSize = useContext(UiConfigProviderSizeContext);
  const sizeName = isPropSize && typeof propsSize !== 'number' ? propsSize : providerSize;
  const sizeGutterName = getSizeGutterBySizeFullName(sizeName || 'middle');
  const spaceSize = isPropSize && typeof propsSize === 'number' ? propsSize : gutterSizes[sizeGutterName];
  const bodyPadding = usePaddingStyle([spaceSize, 0, 0]);

  return (
    <UiCol
      style={{
        ...bodyPadding,
        marginLeft: isSpacing ? AVATAR_SIZE + AVATAR_OFFSET : 0,
      }}
    >
      {children}
    </UiCol>
  );
};

NotificationBody.Footer = NotificationBodyFooter;
