import React, { FC } from 'react';

import { UiUser, UiUserInfoProps } from '../../user';
import { AVATAR_SIZE } from '../contants';

export type NotificationHeaderProps = UiUserInfoProps;

export const NotificationHeader: FC<NotificationHeaderProps> = (props) => {
  const { title, subtitle, avatarProps, isActive, to } = props;

  return (
    <UiUser.Info
      title={title}
      subtitle={subtitle}
      isActive={isActive}
      avatarProps={{
        ...avatarProps,
        size: AVATAR_SIZE,
      }}
      to={to}
    />
  );
};
