import type { UiFieldData } from '@vkph/ui';
import { AxiosError } from 'axios';
import humps from 'humps';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import { flattenObject } from '@vkph/common/utils';

export const getErrorsResponseFieldData = (error: AxiosError): UiFieldData[] => {
  const fieldData: UiFieldData[] = [];
  const formErrors = error?.response?.data;

  if (formErrors) {
    forEach(formErrors, (errors, key) => {
      if (isArray(errors) && isString(errors[0])) {
        fieldData.push({
          name: humps.camelize(key),
          errors,
        });
      } else if (typeof errors === 'object') {
        const [path, value] = Object.entries(flattenObject(errors, key))[0];

        if (path) {
          fieldData.push({
            name: path.split('.'),
            errors: [`${value}`],
          });
        }
      } else if (isString(errors)) {
        fieldData.push({
          name: humps.camelize(key),
          errors: [errors],
        });
      }
    });
  }

  return fieldData;
};
