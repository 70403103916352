import { UiCard } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { ContactLink } from '@vkph/common/types/models';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { MainContactItem, MainContactsList } from '../contacts-list/main';
import { OtherContactsList } from '../contacts-list/others';
import { ExternalLinkIcon, SocialLinksList } from '../contacts-list/social';

type Props = {
  mainContacts: MainContactItem[];
  otherContacts: ContactLink[];
  socialLinks: (ContactLink | ExternalLinkContact)[];
  messengerIcons?: Map<string, string>;
  externalLinkIcons?: Map<string, ExternalLinkIcon>;
};

export const ContactsInfoDesktop: FC<PropsWithChildren<Props>> = (props) => {
  const { mainContacts, otherContacts, socialLinks, messengerIcons, externalLinkIcons, children } = props;
  const { spaceXL } = useSpace();
  const cardContentPadding = usePaddingStyle([0, spaceXL]);

  return (
    <>
      <UiCard.Content style={cardContentPadding}>
        {mainContacts.length > 0 && <MainContactsList constactsItems={mainContacts} />}
        {otherContacts.length > 0 && (
          <OtherContactsList contactsItems={otherContacts} messengerIcons={messengerIcons} />
        )}
        {socialLinks.length > 0 && (
          <SocialLinksList socialLinksItems={socialLinks} externalLinkIcons={externalLinkIcons} />
        )}
      </UiCard.Content>
      {children}
    </>
  );
};
