/* eslint-disable @typescript-eslint/no-explicit-any */
import { Plugin, icons, type Editor } from '@ckeditor/ckeditor5-core';
import {
  ButtonView,
  DropdownButtonView,
  Model,
  createDropdown,
  addListToDropdown,
  type ListDropdownItemDefinition,
} from '@ckeditor/ckeditor5-ui';
import { CKEditorError, Collection, type Locale } from '@ckeditor/ckeditor5-utils';

import type { VideoResizeOption } from '../videoconfig';
import type { ResizeVideoCommand } from './resizevideocommand';
import { VideoResizeEditing } from './videoresizeediting';

const RESIZE_ICONS = {
  small: icons.objectSizeSmall,
  medium: icons.objectSizeMedium,
  large: icons.objectSizeLarge,
  original: icons.objectSizeFull,
};

function getIsOnButtonCallback(value: string | null): (commandValue: unknown) => boolean {
  return (commandValue: unknown): boolean => {
    const objectCommandValue = commandValue as null | { width: string | null };

    if (value === null && objectCommandValue === value) {
      return true;
    }

    return objectCommandValue !== null && objectCommandValue.width === value;
  };
}

export class VideoResizeButtons extends Plugin {
  public static get requires() {
    return [VideoResizeEditing] as const;
  }

  public static get pluginName() {
    return 'VideoResizeButtons' as const;
  }

  private readonly resizeUnit: 'px' | '%';

  constructor(editor: Editor) {
    super(editor);

    this.resizeUnit = editor.config.get('video.resizeUnit')!;
  }

  public init(): void {
    const { editor } = this;
    const options = editor.config.get('video.resizeOptions')!;
    const command: ResizeVideoCommand = editor.commands.get('resizeVideo')!;

    this.bind('isEnabled').to(command);

    for (const option of options) {
      this.registerVideoResizeButton(option);
    }

    this.registerVideoResizeDropdown(options);
  }

  private registerVideoResizeButton(option: VideoResizeOption): void {
    const { editor } = this;
    const { name, value, icon } = option;
    const optionValueWithUnit = value ? value + this.resizeUnit : null;

    editor.ui.componentFactory.add(name, (locale) => {
      const button = new ButtonView(locale);
      const command: ResizeVideoCommand = editor.commands.get('resizeVideo')!;
      const labelText = this.getOptionLabelValue(option, true);

      if (!RESIZE_ICONS[icon as keyof typeof RESIZE_ICONS]) {
        throw new CKEditorError('videoresizebuttons-missing-icon', editor, option);
      }

      button.set({
        label: labelText,
        icon: RESIZE_ICONS[icon as keyof typeof RESIZE_ICONS],
        tooltip: labelText,
        isToggleable: true,
      });

      button.bind('isEnabled').to(this);
      button.bind('isOn').to(command, 'value', getIsOnButtonCallback(optionValueWithUnit));

      this.listenTo(button, 'execute', () => {
        editor.execute('resizeVideo', { width: optionValueWithUnit });
      });

      return button;
    });
  }

  private registerVideoResizeDropdown(options: Array<VideoResizeOption>): void {
    const { editor } = this;
    const originalSizeOption = options.find((option) => !option.value)!;

    const componentCreator = (locale: Locale) => {
      const command: ResizeVideoCommand = editor.commands.get('resizeVideo')!;
      const dropdownView = createDropdown(locale, DropdownButtonView);
      const dropdownButton: typeof dropdownView.buttonView & { commandValue?: string | null } =
        dropdownView.buttonView;
      const accessibleLabel = 'Изменить размер видео';

      dropdownButton.set({
        tooltip: accessibleLabel,
        commandValue: originalSizeOption.value,
        icon: RESIZE_ICONS.medium,
        isToggleable: true,
        label: this.getOptionLabelValue(originalSizeOption),
        withText: true,
        class: 'ck-resize-video-button',
        ariaLabel: accessibleLabel,
        ariaLabelledBy: undefined,
      });

      dropdownButton.bind('label').to(command, 'value', (commandValue) => {
        if (commandValue && commandValue.width) {
          return commandValue.width;
        }

        return this.getOptionLabelValue(originalSizeOption);
      });
      dropdownView.bind('isEnabled').to(this);

      addListToDropdown(dropdownView, () => this.getResizeDropdownListItemDefinitions(options, command), {
        ariaLabel: 'Список размеров',
        role: 'menu',
      });

      this.listenTo(dropdownView, 'execute', (evt) => {
        editor.execute((evt.source as any).commandName, { width: (evt.source as any).commandValue });
        editor.editing.view.focus();
      });

      return dropdownView;
    };

    editor.ui.componentFactory.add('resizeVideo', componentCreator);
    editor.ui.componentFactory.add('videoResize', componentCreator);
  }

  private getOptionLabelValue(option: VideoResizeOption, forTooltip = false): string {
    if (option.label) {
      return option.label;
    }

    if (forTooltip) {
      if (option.value) {
        return `Изменить размер видео до ${option.value + this.resizeUnit}`;
      }

      return 'Вернить размер видео к оригинальному';
    }

    if (option.value) {
      return option.value + this.resizeUnit;
    }

    return 'Оригинальный';
  }

  private getResizeDropdownListItemDefinitions(
    options: Array<VideoResizeOption>,
    command: ResizeVideoCommand,
  ): Collection<ListDropdownItemDefinition> {
    const itemDefinitions = new Collection<ListDropdownItemDefinition>();

    // eslint-disable-next-line array-callback-return
    options.map((option) => {
      const optionValueWithUnit = option.value ? option.value + this.resizeUnit : null;
      const definition: ListDropdownItemDefinition = {
        type: 'button',
        model: new Model({
          commandName: 'resizeVideo',
          commandValue: optionValueWithUnit,
          label: this.getOptionLabelValue(option),
          role: 'menuitemradio',
          withText: true,
          icon: null,
        }),
      };

      definition.model.bind('isOn').to(command, 'value', getIsOnButtonCallback(optionValueWithUnit));

      itemDefinitions.add(definition);
    });

    return itemDefinitions;
  }
}
