import { UiAvatar, UiAvatarSize, UiCol, UiRow, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { closeGlobalModal, openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { GetTasksStorage } from '@vkph/common/store/tasks';
import { ProjectsStageModel, ProjectsTasksModel } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

import { DisplayOptions } from '../../../compact/GanttCompact';
import { getOffsetInDays } from '../../../helpers/gantt-date-helper';
import { GanttRangeTooltip } from '../../../range-tooltip/GanttRangeTooltip';
import styles from './GanttFullTasksItem.scss';

const MIN_TITLE_WIDTH = 400;

interface Props {
  task: ProjectsTasksModel;
  referenceTime: string;
  daySize: DisplayOptions['daySize'];
  stage: ProjectsStageModel;
  storages: GetTasksStorage;
}

export const GanttFullTasksItem: FC<Props> = (props) => {
  const { referenceTime, task, daySize, stage, storages } = props;
  const { color } = stage;
  const { workStart, workEnd, assigneeProfile } = task;
  const intervalWidth = getOffsetInDays(workStart, workEnd) * daySize;
  const startPosition = getOffsetInDays(referenceTime, workStart) * daySize;

  const onOpenTaskPreview = () => {
    openGlobalModal(GlobalModalNames.TaskPreview, {
      taskId: task.id,
      onSuccess: storages.storage.refetchWithLastParams,
      onClose: () => closeGlobalModal(GlobalModalNames.TaskPreview),
    });
  };

  return (
    <UiRow
      wrap={false}
      gutter={[16, 0]}
      style={{
        marginLeft: startPosition,
      }}
    >
      <GanttRangeTooltip timeStart={workStart} timeEnd={workEnd} compact>
        <UiCol
          className={styles.ganttFullTasksItem__timeline}
          style={{ backgroundColor: color, minWidth: intervalWidth }}
        />
      </GanttRangeTooltip>
      <UiCol style={{ minWidth: MIN_TITLE_WIDTH }}>
        <UiSpace size={4} style={{ lineHeight: 0, paddingTop: 4 }} wrap={false}>
          <Link to={getRoutePath(RouteNames.Profile, { id: assigneeProfile.id })}>
            <UiAvatar size={UiAvatarSize.XS} src={assigneeProfile.smallAvatar} />
          </Link>
          <UiSpace direction="vertical" size={2} style={{ lineHeight: 0 }} wrap={false}>
            <UiTypography.Link
              type="secondary"
              onClick={onOpenTaskPreview}
              className={styles.ganttFullTasksItem__key}
            >
              {task.key}
            </UiTypography.Link>
            <UiTypography.Footnote>
              <UiTruncateMarkup truncate tooltipProps={{ title: task.title }}>
                {task.title}
              </UiTruncateMarkup>
            </UiTypography.Footnote>
          </UiSpace>
        </UiSpace>
      </UiCol>
    </UiRow>
  );
};
