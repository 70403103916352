import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { GetTasksStorage } from '@vkph/common/store/tasks';
import { ProjectsStageModel } from '@vkph/common/types/models';

import { DisplayOptions } from '../../compact/GanttCompact';
import { GanttFullTasksItem } from './item/GanttFullTasksItem';

interface Props {
  stage: ProjectsStageModel;
  referenceTime: string;
  daySize: DisplayOptions['daySize'];
  storages: GetTasksStorage;
}

export const GanttFullTasks: FC<Props> = (props) => {
  const { storages, ...rest } = props;
  const { data: tasksData } = useStore(storages.storage.store);

  return (
    <>
      {tasksData.map((task) => (
        <GanttFullTasksItem key={task.id} task={task} storages={storages} {...rest} />
      ))}
    </>
  );
};
