import { UiInput, UiPhoneInput, UiIcon } from '@vkph/ui';
import classNames from 'classnames';
import React from 'react';

import { SurveyFreeAnswerFormatType } from '@vkph/common/types/models';
import ContactMailSvg from '@vkph/ui/svg/contact-mail.svg';

import styles from './FreeAnswer.scss';
import { ProfilePicker } from './profile-picker/ProfilePicker';

export type FreeAnswerProps = {
  formatString?: SurveyFreeAnswerFormatType;
  disabled?: boolean;
  className?: string;
};

export const FreeAnswer: React.FC<FreeAnswerProps> = (props) => {
  const { formatString: type, disabled, className, ...rest } = props;

  if (type === SurveyFreeAnswerFormatType.Number) {
    return (
      <UiInput
        type="number"
        disabled={disabled}
        className={classNames(className, styles.freeAnswer_base)}
        {...rest}
      />
    );
  }

  if (type === SurveyFreeAnswerFormatType.Phone) {
    return (
      <UiPhoneInput
        disabled={disabled}
        className={classNames(className, styles.freeAnswer_base, styles.freeAnswer_limited)}
        {...rest}
      />
    );
  }

  if (type === SurveyFreeAnswerFormatType.Employee) {
    return (
      <ProfilePicker
        className={classNames(className, styles.freeAnswer_base, styles.freeAnswer_limited)}
        disabled={disabled}
        {...rest}
      />
    );
  }

  if (type === SurveyFreeAnswerFormatType.Email) {
    return (
      <UiInput
        type="email"
        disabled={disabled}
        placeholder="example@email.com"
        prefix={<UiIcon width={20} height={20} component={ContactMailSvg} />}
        className={classNames(
          className,
          styles.freeAnswer_base,
          styles.freeAnswer_limited,
          styles.freeAnswer_email,
        )}
        {...rest}
      />
    );
  }

  return (
    <UiInput
      type="text"
      disabled={disabled}
      className={classNames(className, styles.freeAnswer_base)}
      {...rest}
    />
  );
};
