import { UiIcon, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import TeamSvg from '@vkph/ui/svg/team.svg';

type CountProps = {
  count: number;
};

export const MeetingRoomCount: FC<CountProps> = (props) => {
  const { count } = props;

  return <UiIcon.Label component={TeamSvg} type={UiTypographyTextTypes.Secondary} label={String(count)} />;
};
