import { useBreakpoint, UiCol, UiRow, UiRowProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { isNumberType } from '@vkph/common/types/guards';
import { ReactionModel } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';

import { ReactionsComments, ReactionsCommentsProps } from './comments/ReactionsComments';
import { ReactionHandler, ReactionsLikes } from './likes/ReactionsLikes';
import { ReactionsViews, ReactionsViewsProps } from './views/ReactionsViews';

type ReactionsComposition = {
  Likes: typeof ReactionsLikes;
  Views: typeof ReactionsViews;
  Comments: typeof ReactionsComments;
};

interface ReactionsLikesProps {
  reactions: ReactionModel[];
  disabled?: boolean;
  onReaction?: ReactionHandler;
}

type ReactionsPropsBase = Partial<ReactionsLikesProps> &
  Partial<ReactionsViewsProps> &
  Partial<ReactionsCommentsProps>;

interface ReactionsProps extends ReactionsPropsBase {
  stretch?: boolean;
}

export const Reactions: ReactionsComposition & FC<PropsWithChildren<ReactionsProps>> = (props) => {
  const { views, comments, reactions, onReaction, stretch, style, children } = props;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceXL } = useSpace();

  const isShowViews = isNumberType(views);
  const isShowComments = isNumberType(comments);
  const isStretchWithViews = stretch && isShowViews;
  const rowProps: UiRowProps = {
    gutter: isStretchWithViews ? spaceXL : spaceM,
    justify: isStretchWithViews ? 'space-between' : 'start',
    style,
  };

  return (
    <UiRow {...rowProps} wrap={false}>
      <UiCol flex="1 0 auto">
        <UiRow gutter={16} wrap={false}>
          {reactions && (
            <UiCol>
              <Reactions.Likes reactions={reactions || []} onReaction={onReaction} />
            </UiCol>
          )}
          {isShowComments && isLayoutLarge && (
            <UiCol>
              <Reactions.Comments comments={comments} />
            </UiCol>
          )}
          {isLayoutLarge && children && <UiCol>{children}</UiCol>}
        </UiRow>
      </UiCol>
      {isShowViews && (
        <UiCol>
          <Reactions.Views views={views} />
        </UiCol>
      )}
    </UiRow>
  );
};

Reactions.Likes = ReactionsLikes;
Reactions.Comments = ReactionsComments;
Reactions.Views = ReactionsViews;
