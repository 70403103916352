import { UiSkeleton } from '@vkph/ui';
import React, { FC } from 'react';

export const SurveyPreviewSkeleton: FC = () => {
  return (
    <>
      <UiSkeleton height={432} width={780} />
      <UiSkeleton height={36} width={780} />
      <UiSkeleton count={3} height={780} />
    </>
  );
};
