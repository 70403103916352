// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._608a8025_ck-content video._608a8025_video_resized{height:auto}._608a8025_ck-content ._608a8025_video._608a8025_video_resized{max-width:100%;display:block;box-sizing:border-box}._608a8025_ck-content ._608a8025_video._608a8025_video_resized video{width:100%}._608a8025_ck-content ._608a8025_video._608a8025_video_resized>figcaption{display:block}[dir=ltr] ._608a8025_ck._608a8025_ck-button._608a8025_ck-button_with-text._608a8025_ck-resize-video-button ._608a8025_ck-button__icon{margin-right:var(--ck-spacing-standard)}[dir=rtl] ._608a8025_ck._608a8025_ck-button._608a8025_ck-button_with-text._608a8025_ck-resize-video-button ._608a8025_ck-button__icon{margin-left:var(--ck-spacing-standard)}._608a8025_ck._608a8025_ck-dropdown ._608a8025_ck-button._608a8025_ck-resize-video-button ._608a8025_ck-button__label{width:4em}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/editor/plugins/video/lib/theme/videoresize.scss"],"names":[],"mappings":"AACA,oDACE,WAAA,CAGF,+DACE,cAAA,CACA,aAAA,CACA,qBAAA,CAEA,qEACE,UAAA,CAGF,0EACE,aAAA,CAIJ,sIACE,uCAAA,CAGF,sIACE,sCAAA,CAGF,sHACE,SAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ck-content video.video_resized {\n  height: auto;\n}\n\n.ck-content .video.video_resized {\n  max-width: 100%;\n  display: block;\n  box-sizing: border-box;\n\n  & video {\n    width: 100%;\n  }\n\n  & > figcaption {\n    display: block;\n  }\n}\n\n[dir='ltr'] .ck.ck-button.ck-button_with-text.ck-resize-video-button .ck-button__icon {\n  margin-right: var(--ck-spacing-standard);\n}\n\n[dir='rtl'] .ck.ck-button.ck-button_with-text.ck-resize-video-button .ck-button__icon {\n  margin-left: var(--ck-spacing-standard);\n}\n\n.ck.ck-dropdown .ck-button.ck-resize-video-button .ck-button__label {\n  width: 4em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ck-content": "_608a8025_ck-content",
	"ckContent": "_608a8025_ck-content",
	"video_resized": "_608a8025_video_resized",
	"video": "_608a8025_video",
	"ck": "_608a8025_ck",
	"ck-button": "_608a8025_ck-button",
	"ckButton": "_608a8025_ck-button",
	"ck-button_with-text": "_608a8025_ck-button_with-text",
	"ckButton_withText": "_608a8025_ck-button_with-text",
	"ck-resize-video-button": "_608a8025_ck-resize-video-button",
	"ckResizeVideoButton": "_608a8025_ck-resize-video-button",
	"ck-button__icon": "_608a8025_ck-button__icon",
	"ckButton__icon": "_608a8025_ck-button__icon",
	"ck-dropdown": "_608a8025_ck-dropdown",
	"ckDropdown": "_608a8025_ck-dropdown",
	"ck-button__label": "_608a8025_ck-button__label",
	"ckButton__label": "_608a8025_ck-button__label"
};
export default ___CSS_LOADER_EXPORT___;
