import { UiSkeleton } from '@vkph/ui';
import React from 'react';

import styles from '../item/NewsListItem.scss';

export const NewsListItemLoading = () => {
  return (
    <div className={styles.newsListItem}>
      <div className={styles.newsListItem__imageWrapper}>
        <UiSkeleton loading height={60} width={90} />
      </div>

      <div className={styles.newsListItem__body}>
        <UiSkeleton loading height={18} width={230} count={2} />
        <UiSkeleton loading height={14} width={85} />
      </div>
    </div>
  );
};
