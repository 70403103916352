import { UiDivider, UiSpace, UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';

import { BlogFullModel } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

import { RouterLink } from '../../../router-link';

type Props = {
  blogs: BlogFullModel[];
};

type BlogLinkProps = {
  blog: BlogFullModel;
};

export const PostHeaderBlogLink: FC<Props> = (props) => {
  const { blogs } = props;

  const BlogLink: FC<BlogLinkProps> = (prop) => {
    const { blog } = prop;

    return (
      <UiSpace size={0} align="center">
        <UiDivider.Dot type="secondary" />
        <RouterLink
          type="secondary"
          underline
          to={getRoutePath(RouteNames.GroupView, { id: blog.slug || blog.id })}
        >
          <UiTruncateMarkup truncate>{blog.name}</UiTruncateMarkup>
        </RouterLink>
      </UiSpace>
    );
  };

  return (
    <>
      {blogs.map((blog) => (
        <BlogLink key={blog.id} blog={blog} />
      ))}
    </>
  );
};
