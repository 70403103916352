import { UiAvatarSize, UiTypography, UiTypographyTextType, UiTruncateMarkup } from '@vkph/ui';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { UserModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';

import { UserAvatar } from '../user-avatar';
import styles from './UserLinkCell.scss';

type Props = {
  user: UserModel;
  size?: number;
  getFullName?: (user: UserModel) => string;
  strong?: boolean;
  type?: UiTypographyTextType;
  lines?: number;
  nameStyles?: CSSProperties;
};

/** TODO: необходимо объединить с LinkListItem и перенести в перенести в ui/table */
export const UserLinkCell: React.FC<Props> = (props) => {
  const {
    user,
    size = UiAvatarSize.XS,
    getFullName = getFullNameWithoutPatronymic,
    strong = false,
    type,
    lines,
    nameStyles,
  } = props;
  const name = getFullName(user);
  const isActive = user.keycloakUser?.isActive || user.isActive;

  return (
    <Link
      to={getRoutePath(RouteNames.Profile, { id: user.keycloakId || user.keycloakUser?.id || user.id })}
      className={styles.userLinkCell}
    >
      <UserAvatar size={size} src={user.avatar} isActive={isActive} />
      <UiTypography.Text style={nameStyles} type={type} className={styles.userLinkCell__name} strong={strong}>
        <UiTruncateMarkup lines={lines} truncate={Boolean(lines)}>
          {name}
        </UiTruncateMarkup>
      </UiTypography.Text>
    </Link>
  );
};
