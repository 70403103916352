import { UiIcon, UiButton, UiSelect, UiSelectProps, UiOptionData } from '@vkph/ui';
import React, { FC } from 'react';

import ClearSvg from '@vkph/ui/svg/clear.svg';

interface AutoCompleteListItemFieldProps extends UiSelectProps<UiOptionData> {
  onRemove: () => void;
  onSearchClear: () => void;
}

export const AutoCompleteListItemField: FC<AutoCompleteListItemFieldProps> = (props) => {
  const { onSearchClear, onRemove, onChange, optionLabelProp, ...restProps } = props;
  const onSelect = (value: UiOptionData, option: UiOptionData) => {
    onChange?.(value, option);
    onSearchClear();
  };

  const removeHandler = () => {
    onRemove();
    onSearchClear();
  };

  return (
    <>
      <UiSelect
        {...restProps}
        showSearch
        labelInValue
        allowClear={false}
        filterOption={false}
        optionLabelProp="label"
        onSelect={onSelect}
        onChange={onChange}
      />
      <UiButton
        size="middle"
        type="link-secondary"
        onClick={removeHandler}
        icon={<UiIcon component={ClearSvg} width={20} height={20} />}
      />
    </>
  );
};
