import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';

type Props = {
  fileName: string;
  children?: ReactNode;
};

const PAGE_WIDTH = 210;

export const PDFExportCanvas = forwardRef(({ fileName, children }: React.PropsWithRef<Props>, ref) => {
  const generatePDF = async () => {
    const container = document.getElementById(fileName);
    const htmlContainers = container?.querySelectorAll(':scope > div');

    const doc = new JsPDF('p', 'mm');

    if (htmlContainers) {
      const makeImage = async (i: number) => {
        const canvas = await html2canvas(htmlContainers[i] as HTMLElement);
        const imgData = canvas.toDataURL('image/png');

        const imgWidth = PAGE_WIDTH;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const position = 0;

        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);

        if (i + 1 === htmlContainers.length) {
          doc.save(`${fileName}.pdf`);
        } else {
          doc.addPage();
        }
      };

      for (let i = 0; i < htmlContainers.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await makeImage(i);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    generatePDF,
  }));

  return <div id={fileName}>{children}</div>;
});
