import { UiPopover, UiPopoverProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { ReactionsTypesModel } from '@vkph/common/types/models';
import { variables } from '@vkph/ui/providers/theme';

import { ReactionHandler } from '../ReactionsLikes';
import { ReactionsLikesIcon } from '../icon/ReactionsLikesIcon';
import styles from './ReactionsLikesPopover.scss';

interface Props extends Omit<UiPopoverProps, 'content' | 'overlayClassName'> {
  reactionsData: ReactionsTypesModel[];
  onReact: ReactionHandler;
}

const POPOVER_ICON_SIZE = 28;

export const ReactionsLikesPopover: FC<PropsWithChildren<Props>> = (props) => {
  const { reactionsData, onReact, ...popoverProps } = props;

  const popoverContent = (
    <div className={styles.reactionsPopover__content}>
      {reactionsData.map(({ iconName, id }) => (
        <ReactionsLikesIcon
          key={id}
          id={id}
          iconName={iconName}
          onReact={onReact}
          size={POPOVER_ICON_SIZE}
          className={styles.reactionsPopover__icon}
        />
      ))}
    </div>
  );

  return (
    <UiPopover
      {...popoverProps}
      color={variables.themePalette.black}
      overlayClassName={styles.reactionsPopover}
      content={popoverContent}
    />
  );
};
