// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a2e9f622_survey-divider[class]{margin:20px 0}.a2e9f622_survey-divider[class] .ant-divider-inner-text[class]{padding:0;width:28px}.a2e9f622_survey-divider[class] .ant-divider-inner-text[class]>button{width:28px;height:28px;padding:0}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/survey/survey-divider/SurveyDivider.scss"],"names":[],"mappings":"AACA,gCAIE,aAAA,CAGE,+DACE,SAAA,CACA,UARS,CAUT,sEACE,UAXO,CAYP,WAZO,CAaP,SAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.survey-divider[class] {\n  $buttonSize: 28px;\n  $baseMargin: 20px;\n\n  margin: $baseMargin 0;\n\n  :global {\n    .ant-divider-inner-text[class] {\n      padding: 0;\n      width: $buttonSize;\n\n      > button {\n        width: $buttonSize;\n        height: $buttonSize;\n        padding: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"survey-divider": "a2e9f622_survey-divider",
	"surveyDivider": "a2e9f622_survey-divider"
};
export default ___CSS_LOADER_EXPORT___;
