import { UiList } from '@vkph/ui';
import React, { FC } from 'react';

import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import { EmployeeContactsLink } from '../../contact-link/EmployeeContactsLink';

export type MainContactItem = {
  value?: string;
  label?: string;
  icon?: SvgrComponent;
  href?: string;
};

type Props = {
  constactsItems: MainContactItem[];
};

export const MainContactsList: FC<Props> = (props) => {
  const { constactsItems } = props;

  const { spaceXS } = useSpace();
  const listItemPadding = usePaddingStyle([spaceXS, 0]);

  return (
    <UiList
      split={false}
      grid={{ column: 1 }}
      dataSource={constactsItems}
      renderItem={({ value, label, ...item }) =>
        value &&
        label && (
          <UiList.Item style={{ margin: 0, ...listItemPadding }}>
            <EmployeeContactsLink value={value} label={label} {...item} />
          </UiList.Item>
        )
      }
    />
  );
};
