import { UiImage, UiList } from '@vkph/ui';
import React, { FC } from 'react';

import { ContactLink } from '@vkph/common/types/models';
import { regexpPatterns } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import LinkSvg from '@vkph/ui/svg/link.svg';

import { formatPhoneNumberRu } from '../../EmployeeContacts';
import { EmployeeContactsLink } from '../../contact-link/EmployeeContactsLink';

type Props = {
  contactsItems: ContactLink[];
  messengerIcons?: Map<string, string>;
};

export const OtherContactsList: FC<Props> = (props) => {
  const { contactsItems, messengerIcons } = props;
  const { spaceXS } = useSpace();
  const listItemPadding = usePaddingStyle([spaceXS, 0]);

  return (
    <UiList
      split={false}
      grid={{ column: 1 }}
      dataSource={contactsItems}
      renderItem={({ value, recordId }) =>
        value && (
          <UiList.Item style={{ margin: 0, ...listItemPadding }}>
            <EmployeeContactsLink
              value={value}
              icon={undefined}
              label={formatPhoneNumberRu(value)}
              href={regexpPatterns.phoneNumber.test(value) ? `tel:${value}` : undefined}
            >
              <UiImage
                width={20}
                height={20}
                src={messengerIcons?.get(recordId)}
                wrapperStyle={{ background: 'transparent' }}
                placeholder={<EmployeeContactsLink.Icon component={LinkSvg} />}
              />
            </EmployeeContactsLink>
          </UiList.Item>
        )
      }
    />
  );
};
