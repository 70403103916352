import round from 'lodash/round';
import React, { ReactEventHandler, useState, useCallback, FC } from 'react';
import ReactCrop, { Crop, PercentCrop, ReactCropProps } from 'react-image-crop';

import { FileToUploadModel } from '@vkph/common/types/models';

import { getImageDimensions } from '../../upload-dragger-area';
import styles from '../CropImageArea.scss';
import { FigureSettings, PositionParams, FigureType } from '../typings';

export interface CropImageAreaFigureProps extends Omit<ReactCropProps, 'onChange' | 'src'> {
  minWidth: number;
  minHeight: number;
  imageFile: FileToUploadModel;
  figureType: FigureType;
  figureSettings: FigureSettings;
}

const imageStyles: React.CSSProperties = { width: '100%' };

const getCenterCropCirclePosition = (position: PositionParams): Crop => {
  const isPortrait = position.imgHeight > position.imgWidth;
  const width = isPortrait ? 100 : (100 * position.imgHeight) / position.imgWidth;
  const height = isPortrait ? (100 * position.imgWidth) / position.imgHeight : 100;

  return {
    unit: '%',
    width,
    height,
    x: (100 - width) / 2,
    y: (100 - height) / 2,
  };
};

const getCenterCropRectanglePosition = (position: PositionParams): Crop => {
  let height = 0;
  let width = 0;

  if (position.imgWidth > position.imgHeight * position.aspect) {
    width = ((position.imgHeight * position.aspect) / position.imgWidth) * 100;
    height = 100;
  } else {
    height = (position.imgWidth / (position.aspect * position.imgHeight)) * 100;
    width = 100;
  }

  const x = Math.max((100 - width) / 2, 0);
  const y = Math.max((100 - height) / 2, 0);

  return { x, y, height, width, unit: '%' };
};

export const CropImageAreaFigure: FC<CropImageAreaFigureProps> = (props) => {
  const { figureSettings, minWidth, minHeight, imageFile, figureType, disabled } = props;
  const isCircle = figureType === FigureType.Circle;
  const [crop, setCrop] = useState<Crop>();
  const [minCropWidth, setMinCropWidth] = useState(0);
  const [minCropHeight, setMinCropHeight] = useState(0);

  const normalizeCropData = async (percentCrop: PercentCrop): Promise<PercentCrop> => {
    const { x: cropX = 0, y: cropY = 0, width: cropWidth = 0, height: cropHeight = 0 } = percentCrop;
    const { width: imageWidth, height: imageHeight } = await getImageDimensions(imageFile.data);

    const x = round(imageWidth * parseFloat((cropX / 100).toFixed(4)));
    const y = round(imageHeight * parseFloat((cropY / 100).toFixed(4)));
    const width = round(imageWidth * parseFloat((cropWidth / 100).toFixed(4)));
    const height = round(imageHeight * parseFloat((cropHeight / 100).toFixed(4)));

    return { x, y, width, height, unit: '%' };
  };

  const onImageLoaded: ReactEventHandler<HTMLImageElement> = (e) => {
    const img = e.target as HTMLImageElement;
    const figureParams = {
      aspect: figureSettings.aspect,
      imgWidth: img.offsetWidth,
      imgHeight: img.offsetHeight,
    };
    const centerCropPosition = isCircle
      ? getCenterCropCirclePosition(figureParams)
      : getCenterCropRectanglePosition(figureParams);

    setCrop(centerCropPosition);

    setMinCropWidth(Math.ceil((minWidth * img.offsetWidth) / img.naturalWidth));
    setMinCropHeight(Math.ceil((minHeight * img.offsetHeight) / img.naturalHeight));

    normalizeCropData({ ...centerCropPosition, unit: '%' }).then(figureSettings.setSettings);
  };

  const onCropChange = useCallback(
    (_: Crop, percentCrop: PercentCrop) => {
      if (percentCrop.width !== 0 && percentCrop.height !== 0 && figureSettings.setSettings) {
        setCrop({ ...percentCrop, unit: '%' });

        normalizeCropData(percentCrop).then(figureSettings.setSettings);
      }
    },
    [imageFile],
  );

  return (
    <ReactCrop
      crop={crop}
      disabled={disabled}
      aspect={figureSettings.aspect}
      circularCrop={isCircle}
      className={styles.reactCropArea__cropPreview}
      onChange={onCropChange}
      keepSelection
      minWidth={minCropWidth}
      minHeight={minCropHeight}
    >
      <img alt="crop preview" style={imageStyles} onLoad={onImageLoaded} src={imageFile.data || ''} />
    </ReactCrop>
  );
};
