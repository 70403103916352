import { stringify } from 'query-string';
import React, { FC } from 'react';
import { useHref } from 'react-router-dom';

import { isNewsPost } from '@vkph/common/types/guards';
import { PostModel } from '@vkph/common/types/models';
import { getHrefByPathname } from '@vkph/common/utils';

import { isSomePostBlogsNegativeKey } from '../../../../utils';
import { Reactions, ReactionHandler } from '../../../reactions';
import { ShareButton } from '../../../share-button';

type Props = {
  post: PostModel;
  onReaction?: ReactionHandler;
};

export const PostBodyReactions: FC<Props> = (props) => {
  const { post, onReaction } = props;

  const routerHref = useHref({
    search: stringify({
      postId: post.id,
      postType: post.type,
    }),
  });
  const shareHref = getHrefByPathname(routerHref);

  const isReactedDisabledPostSettings = post?.settings && !post.settings.isReacted;
  const isReactedDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isReacted');
  const isCommentsDisabledPostSettings = post?.settings && !post.settings.isComments;
  const isCommentsDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isComments');
  const isCommentsDisabled = isCommentsDisabledPostSettings || isCommentsDisabledPostBlogs;
  const isReactionsDisabled = isReactedDisabledPostSettings || isReactedDisabledPostBlogs;
  const isActiveAuthor = Boolean(post.author.isActive || post.author.keycloakUser?.isActive);

  const views = isNewsPost(post) ? post.viewsCount : undefined;
  const comments = isCommentsDisabled ? undefined : post.commentsCount;
  const reactions = isReactionsDisabled ? undefined : post.reactions;

  return (
    <Reactions
      reactions={reactions}
      comments={comments}
      views={views}
      {...(isActiveAuthor && { onReaction })}
      stretch={isNewsPost(post)}
    >
      <ShareButton value={shareHref} />
    </Reactions>
  );
};
