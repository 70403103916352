import { UiCheckbox, UiCheckboxValueType, UiFileDirection, UiList, UiListProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { useLocalStorage } from '@vkph/common/hooks';
import { GetFileStorageListStorage } from '@vkph/common/store/filestorage';
import { HistoryActionType } from '@vkph/common/types/history';
import { FileStorageListEntryModel } from '@vkph/common/types/models';

import { PaginatedList, PaginatedListProps } from '../paginated-list';
import { FileStorageListItem, FileStorageListItemProps } from './item/FileStorageListItem';

export type FileStorageListItemSelect = {
  selectedItemKeys: UiCheckboxValueType[];
  onChange: (newSelectedItemKeys: UiCheckboxValueType[]) => void;
};

export type FileStorageListProps = Pick<PaginatedListProps, 'storage' | 'pageSize' | 'query'> &
  Omit<FileStorageListItemProps, 'item' | 'file'>;

export const FileStorageList: FC<PropsWithChildren<FileStorageListProps>> = (props) => {
  const { storage, pageSize, query, fileSelect, ...itemProps } = props;
  const [fileListManagerDirection] = useLocalStorage<UiFileDirection>(
    'fileListManagerDirection',
    UiFileDirection.Horizontal,
  );

  const listGrid: Record<UiFileDirection, UiListProps<FileStorageListEntryModel>['grid']> = {
    [UiFileDirection.Vertical]: { column: 2, xl: 3, xxl: 4, gutter: 16 },
    [UiFileDirection.Horizontal]: undefined,
  };

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <PaginatedList<GetFileStorageListStorage['storage']>
      storage={storage}
      pageSize={pageSize}
      query={query}
      historyAction={HistoryActionType.Replace}
      size="small"
    >
      {({ data, isLoading }) => (
        <UiCheckbox.Group
          value={fileSelect?.selectedItemKeys}
          onChange={(selectedRowsKeys) => fileSelect?.onChange?.(selectedRowsKeys)}
          style={{ width: '100%' }}
        >
          <UiList
            split={false}
            loading={isLoading}
            grid={listGrid[fileListManagerDirection]}
            style={{
              width: '100%',
              padding:
                fileListManagerDirection === UiFileDirection.Vertical ? '0 24px 16px 24px' : '0 0 16px 0',
            }}
            dataSource={data}
            renderItem={(file) => {
              return (
                <FileStorageListItem
                  {...itemProps}
                  file={file}
                  fileSelect={fileSelect}
                  direction={fileListManagerDirection}
                />
              );
            }}
          />
        </UiCheckbox.Group>
      )}
    </PaginatedList>
  );
};
