import { UiIcon, UiTypography, UiTypographySymbolName } from '@vkph/ui';
import React, { FC } from 'react';

import TaskPriorityHighSvg from '@vkph/ui/svg/task-priority-high.svg';
import TaskPriorityLowSvg from '@vkph/ui/svg/task-priority-low.svg';
import TaskPriorityMediumSvg from '@vkph/ui/svg/task-priority-medium.svg';

import styles from './TaskPriority.scss';

enum Priority {
  High = 1,
  Medium,
  Low,
}

const priorityLabel = {
  [Priority.Low]: 'Низкий',
  [Priority.Medium]: 'Средний',
  [Priority.High]: 'Высокий',
};

const priorityIcon = {
  [Priority.Low]: TaskPriorityLowSvg,
  [Priority.Medium]: TaskPriorityMediumSvg,
  [Priority.High]: TaskPriorityHighSvg,
};

interface TaskPriorityProps {
  level: Priority;
}

export const TaskPriority: FC<TaskPriorityProps> = (props) => {
  const { level } = props;

  const icon = priorityIcon[level];
  const label = priorityLabel[level];

  if (!label) {
    return <UiTypography.Symbol name={UiTypographySymbolName.EmDash} />;
  }

  return (
    <div className={styles.taskPriority}>
      <UiIcon component={icon} width={20} height={20} className={styles.taskPriority__icon} />
      {label}
    </div>
  );
};
