import { CalendarCardTask, CalendarCardTaskPropsComponent } from './card-task/CalendarCardTask';
import { CalendarCell, CalendarCellPropsComponent } from './cell/CalendarCell';
import {
  CalendarCreatingSection,
  CalendarCreatingSectionPropsComponent,
} from './creating-section/CalendarCreatingSection';
import { CalendarHeading, CalendarHeadingPropsComponent } from './heading/CalendarHeading';
import { CalendarTimeCell, CalendarTimeCellPropsComponent } from './time-cell/CalendarTimeCell';

type CalendarComposition = {
  Heading: CalendarHeadingPropsComponent;
  CardTask: CalendarCardTaskPropsComponent;
  Cell: CalendarCellPropsComponent;
  TimeCell: CalendarTimeCellPropsComponent;
  CreatingSection: CalendarCreatingSectionPropsComponent;
};

export const Calendar: CalendarComposition = {
  Heading: CalendarHeading,
  CardTask: CalendarCardTask,
  Cell: CalendarCell,
  TimeCell: CalendarTimeCell,
  CreatingSection: CalendarCreatingSection,
};
