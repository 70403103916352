import { UiEmpty, UiEmptyFeedProps, UiCol, UiIcon, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { getRoutePath, RouteNames } from '@vkph/common/utils';
import Sun404Svg from '@vkph/ui/svg/sun404.svg';

export const PageNotFound: FC<UiEmptyFeedProps> = (props) => {
  const { icon, emptyMessage, bordered = true, ...rest } = props;

  return (
    <UiCol style={{ marginTop: 190 }} span={12} offset={6}>
      <UiEmpty.Feed
        icon={icon || <UiIcon width={214} height={104} component={Sun404Svg} />}
        emptyMessage={{
          header: emptyMessage?.header || 'Страница не найдена',
          description: emptyMessage?.description || (
            <UiTypography.Text type="secondary">
              Вернитесь на&nbsp;
              <Link to={getRoutePath(RouteNames.Home)}>Главную</Link>
            </UiTypography.Text>
          ),
        }}
        bordered={bordered}
        {...rest}
      />
    </UiCol>
  );
};
