import { UiConfigContext, UiTruncateMarkup, UiBreadcrumbProps } from '@vkph/ui';
import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

export type UseBreadcrumbItemsHistory = Pick<UiBreadcrumbProps, 'itemRender'>;
export type UseBreadcrumbItemsHistoryParams = Pick<UiBreadcrumbProps, 'prefixCls'>;

export const useBreadcrumbItemsHistory = (params?: UseBreadcrumbItemsHistoryParams) => {
  const customizePrefixCls = params?.prefixCls;
  const { getPrefixCls } = useContext(UiConfigContext);
  const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);

  const itemRender: UseBreadcrumbItemsHistory['itemRender'] = (currentRoute, _, items) => {
    const { title, href = '', path, className: itemClassName } = currentRoute;
    const isEmpty = !path;
    const isLast = path && path === items[items.length - 1]?.path;

    if (isLast || isEmpty) {
      return (
        <UiTruncateMarkup truncate lines={1} className={itemClassName}>
          {title}
        </UiTruncateMarkup>
      );
    }

    return (
      <Link to={`${href}${path}`} className={classNames(`${prefixCls}-link`, itemClassName)}>
        {title}
      </Link>
    );
  };

  return useMemo<UseBreadcrumbItemsHistory>(() => {
    return { itemRender };
  }, [prefixCls]);
};
