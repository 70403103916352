import React, { FC, PropsWithChildren } from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import styles from '../CropImageArea.scss';

export const CropImageAreaWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return <div className={styles.reactCropAreaWrapper}>{children}</div>;
};
