import { UiOptionData, UiForm, UiFormInstance } from '@vkph/ui';
import merge from 'lodash/merge';
import React, { forwardRef, useMemo } from 'react';

import { EditorValue } from '@vkph/common/types/editor';
import { EntityContentType, PostAttachmentModel, PostTextModel } from '@vkph/common/types/models';
import { generateTagFieldOptions, parseTextPostContent } from '@vkph/common/utils';

import { useEditorMentionsConfig } from '../../../../hooks';
import { CkEditorConfig, Editor, EditorMentionType } from '../../../editor';
import { TagsField } from '../../../tags-field';

export type PostFormFields = {
  body: EditorValue;
  tags: UiOptionData[];
  attachments: PostAttachmentModel[];
};

type Props = {
  post?: PostTextModel;
  onSubmit: (fields: PostFormFields) => void;
  editorExtraConfig?: Partial<CkEditorConfig>;
};

export const PostBodyForm = forwardRef<UiFormInstance, Props>((props, ref) => {
  const { post, onSubmit, editorExtraConfig } = props;
  const [form] = UiForm.useForm<PostFormFields>();
  const { editorMentionsConfig } = useEditorMentionsConfig([EditorMentionType.User]);

  const initialValue = useMemo<Partial<PostFormFields>>(() => {
    if (post) {
      const { title, tags } = post;
      const postContentParced = parseTextPostContent(post);
      const postContentParcedWithLegacyTitle = `<h3>${title}</h3>${postContentParced}`;
      const data = title ? postContentParcedWithLegacyTitle : postContentParced;

      return {
        body: {
          data,
          version: EntityContentType.CKEditor5,
        },
        tags: generateTagFieldOptions(tags),
      };
    }

    return {};
  }, [post]);

  return (
    <UiForm layout="vertical" initialValues={initialValue} form={form} ref={ref} onFinish={onSubmit}>
      <UiForm.Item name="body" noStyle>
        <Editor config={merge(editorMentionsConfig, editorExtraConfig)} />
      </UiForm.Item>
      <UiForm.Item style={{ margin: '8px 0 0 0' }} name="tags" label="Теги">
        <TagsField />
      </UiForm.Item>
    </UiForm>
  );
});
