import {
  UiButton,
  UiDivider,
  UiFileDirection,
  UiIcon,
  message,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import React, { FC, ReactNode, useMemo } from 'react';

import { FileStorageEntryType, FileStorageListEntryModel } from '@vkph/common/types/models';
import { declension, filesDeclension, getFormattedDate } from '@vkph/common/utils';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import { downloadFileByURL, getFormattedFileSize } from '@vkph/ui/utils';

import { FileProps, UiFileActionsProps } from '../../file';
import { FileListItemColumn } from './column/FileListItemColumn';
import { FileListItemRow } from './row/FileListItemRow';

export interface FileListItemProps extends Pick<FileProps, 'direction'>, Pick<UiFileActionsProps, 'actions'> {
  file: FileStorageListEntryModel;
}

export const FileListItem: FC<FileListItemProps> = (props) => {
  const { direction = UiFileDirection.Horizontal, file, actions = [], ...restProps } = props;
  const { size: fileSize, createdAt, name, shortName, file: fileUrl } = file;
  const isFolder = file.type === FileStorageEntryType.Folder;

  const title = useMemo(() => {
    return (
      <UiTypography.Text strong>
        <UiTruncateMarkup truncate lines={1}>
          {name}
        </UiTruncateMarkup>
      </UiTypography.Text>
    );
  }, [name, fileUrl]);

  const subtitle = useMemo<ReactNode>(() => {
    return (
      <UiTypography.Text type="secondary">
        {isFolder && `${file.filesCount} ${declension(file.filesCount, filesDeclension)}`}
        {!isFolder && (
          <>
            {getFormattedFileSize(fileSize)}
            <UiDivider.Dot type="secondary" />
            {createdAt ? getFormattedDate(createdAt, 'dd.MM.yyyy') : ''}
          </>
        )}
      </UiTypography.Text>
    );
  }, [fileSize, file, createdAt]);

  const fileActions = useMemo<ReactNode[]>(() => {
    if (fileUrl && !isFolder) {
      return [
        <UiButton
          key="download"
          disabledFocus
          type="link-secondary"
          icon={<UiIcon component={DownloadSvg} width={20} height={20} />}
          onClick={(e) => {
            e.stopPropagation();
            message.info('Загрузка началась');

            downloadFileByURL(fileUrl, shortName)
              .then(() => message.success(`Файл ${shortName} загружен`))
              .catch(() => message.error('Ошибка загрузки'));
          }}
        />,
        ...actions,
      ];
    }

    return actions;
  }, [fileUrl, actions]);

  const FileListDirectionItem =
    direction === UiFileDirection.Horizontal ? FileListItemRow : FileListItemColumn;

  return (
    <FileListDirectionItem
      {...restProps}
      title={title}
      subtitle={subtitle}
      fileName={name}
      actions={fileActions}
      file={file}
      isFolder={isFolder}
    />
  );
};
