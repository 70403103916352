import { UiFloatButton, UiIcon } from '@vkph/ui';
import React from 'react';

import { useSpace } from '@vkph/ui/hooks';
import ArrowUpSvg from '@vkph/ui/svg/arrow-up.svg';

export const ScrollToTopButton = () => {
  const { spaceL, space3XL } = useSpace();

  return (
    <UiFloatButton.BackTop
      style={{ bottom: space3XL, right: spaceL }}
      type="primary"
      icon={<UiIcon component={ArrowUpSvg} width={20} height={20} />}
    />
  );
};
