import { UiList, UiSpace, UiSpaceProps } from '@vkph/ui';
import React, { FC } from 'react';

import { AlphabetPointers, AlphabetPointersProps } from './alphabet-pointers/AlphabetPointers';
import { Alphabets } from './constants';

export type { AlphabetSymbol } from './alphabet-pointers';

export interface AlphabetListProps
  extends Pick<UiSpaceProps, 'style'>,
    Pick<AlphabetPointersProps, 'value' | 'onChange'> {
  alphabets: Alphabets[];
}

export const AlphabetList: FC<AlphabetListProps> = (props) => {
  const { onChange, alphabets, style, value } = props;

  return (
    <UiSpace full direction="vertical" size={0} style={style}>
      <UiList
        split={false}
        itemLayout="horizontal"
        dataSource={alphabets}
        renderItem={(alphabet) => (
          <UiList.Item noStyle>
            <AlphabetPointers data={alphabet} onChange={onChange} value={value} />
          </UiList.Item>
        )}
      />
    </UiSpace>
  );
};
