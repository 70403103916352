import { UiTypography } from '@vkph/ui';
import React from 'react';

import styles from '../CropImageArea.scss';

type CropImageAreaTitleProps = {
  title: string;
};

export const CropImageAreaTitle: React.FC<CropImageAreaTitleProps> = (props) => {
  const { title } = props;

  return <UiTypography.Text className={styles.reactCropArea__title}>{title}</UiTypography.Text>;
};
