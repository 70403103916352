import { AxiosError } from 'axios';

import { flattenObject } from '@vkph/common/utils';

export const getFirstErrorResponseMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: AxiosError<Record<string, any>>,
  defaultError: string,
): string => {
  const [, value] = e.response?.data ? Object.entries(flattenObject(e.response.data))[0] : [];

  return typeof value === 'string' ? value : defaultError;
};
