import { Plugin } from '@ckeditor/ckeditor5-core';
import { FileDialogButtonView } from '@ckeditor/ckeditor5-upload';
import type { Locale } from '@ckeditor/ckeditor5-utils';
import { UiIcon } from '@vkph/ui';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import AddVideoSVG from '@vkph/ui/svg/add-video.svg';

import type { UploadVideoCommand } from './uploadvideocommand';
import { createVideoTypeRegExp } from './utils';

export class VideoUploadUI extends Plugin {
  public static get pluginName() {
    return 'VideoUploadUI' as const;
  }

  public init(): void {
    const { editor } = this;
    const componentCreator = (locale: Locale) => {
      const view = new FileDialogButtonView(locale);
      const command: UploadVideoCommand = editor.commands.get('uploadVideo')!;
      const videoTypes = editor.config.get('video.upload.types')!;
      const videoTypesRegExp = createVideoTypeRegExp(videoTypes);

      view.set({
        acceptedType: videoTypes.map((type) => `video/${type}`).join(','),
        allowMultipleFiles: true,
      });

      view.buttonView.set({
        label: 'Вставить видео',
        icon: renderToStaticMarkup(<UiIcon component={AddVideoSVG} width={20} height={20} />),
        tooltip: true,
      });

      view.buttonView.bind('isEnabled').to(command);

      view.on('done', (_, files: FileList) => {
        const videosToUpload = Array.from(files).filter((file) => videoTypesRegExp.test(file.type));

        if (videosToUpload.length) {
          editor.execute('uploadVideo', { file: videosToUpload });

          editor.editing.view.focus();
        }
      });

      return view;
    };

    editor.ui.componentFactory.add('uploadVideo', componentCreator);
    editor.ui.componentFactory.add('videoUpload', componentCreator);
  }
}
