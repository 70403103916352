import { UiPaginationProps, UiTableProps } from '@vkph/ui';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { useState, useMemo } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

type OnChangePagination = NonNullable<UiPaginationProps['onChange'] | UiTableProps['onChange']>;
type UsePaginationBaseParams = {
  onChange?: OnChangePagination;
};

export type UsePaginationParams = Partial<UiPaginationProps & UsePaginationBaseParams>;

const DEFAULT_PAGINATION: UsePaginationParams = {
  total: 0,
  current: 1,
  pageSize: 10,
  hideOnSinglePage: true,
};

export const usePagination = (params: UsePaginationParams = DEFAULT_PAGINATION) => {
  const [pagination, setPagination] = useState<UsePaginationParams>({
    ...DEFAULT_PAGINATION,
    ...params,
  });

  const onChangePagination = (...args: Parameters<OnChangePagination>) => {
    const [paginationConfig] = args;

    if (isObject(paginationConfig)) {
      setPagination({ ...pagination, ...paginationConfig });
    }

    const [pageNumber, pageSize] = args;

    if (isNumber(pageNumber) && isNumber(pageSize)) {
      setPagination({ ...pagination, current: pageNumber, pageSize });
    }
  };

  const onResetPage = () => setPagination({ ...pagination, current: 1 });

  const pageParams = useMemo(
    () => ({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
    }),
    [pagination.current, pagination.pageSize],
  );

  useDeepCompareEffect(() => setPagination({ ...pagination, ...params }), [params]);

  return {
    pagination: { ...pagination, onChange: onChangePagination },
    pageParams,
    onResetPage,
  };
};

export type UsePagination = ReturnType<typeof usePagination>;
