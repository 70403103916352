import {
  UiButton,
  UiDivider,
  UiFileDirection,
  UiFileProps,
  UiColProps,
  UiRowProps,
  UiIcon,
  message,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import { FileStorageEntryType, FileStorageListEntry } from '@vkph/common/types/models';
import { declension, filesDeclension, getFormattedDate } from '@vkph/common/utils';
import DownloadSvg from '@vkph/ui/svg/download.svg';
import { downloadFileByURL, getFormattedFileSize } from '@vkph/ui/utils';

import { FileActions } from './actions/FileActions';
import { FileColumn } from './column/FileColumn';
import { FileRow } from './row/FileRow';

type FileComposition = {
  Actions: typeof FileActions;
};

export interface FileProps
  extends Omit<UiFileProps, 'title' | 'subtitle'>,
    Pick<UiRowProps, 'style' | 'justify'>,
    Pick<UiColProps, 'flex'> {
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode[] | null;
  file: FileStorageListEntry;
  stretch?: boolean;
}

export type FileComponent = FC<PropsWithChildren<FileProps>> & FileComposition;

export const File: FileComponent = (props) => {
  const {
    actions: actionsProp = [],
    file,
    subtitle: subtitleProp,
    title: titleProp,
    direction = UiFileDirection.Horizontal,
    ...restProps
  } = props;
  const { size: fileSize, createdAt, name, shortName } = file;
  const isFolder = file.type === FileStorageEntryType.Folder;
  const isVertical = direction === UiFileDirection.Vertical;
  const fileUrl = file.file;

  const title = useMemo(() => {
    if (titleProp) {
      return titleProp;
    }

    const titleContent = (
      <UiTruncateMarkup truncate lines={1}>
        {name}
      </UiTruncateMarkup>
    );

    if (fileUrl && !isVertical) {
      return (
        <UiTypography.Link href={fileUrl} target="_blank">
          {titleContent}
        </UiTypography.Link>
      );
    }

    return <UiTypography.Text strong>{titleContent}</UiTypography.Text>;
  }, [name, titleProp, fileUrl, isVertical]);

  const subtitle = useMemo<ReactNode>(() => {
    if (subtitleProp) {
      return subtitleProp;
    }

    const fileSubtitle = (
      <>
        {getFormattedFileSize(fileSize)}
        <UiDivider.Dot type="secondary" />
        {createdAt && getFormattedDate(createdAt, 'dd.MM.yyyy')}
      </>
    );

    const folderSubtitle = isFolder && `${file.filesCount} ${declension(file.foldersCount, filesDeclension)}`;

    return <UiTypography.Text type="secondary">{isFolder ? folderSubtitle : fileSubtitle}</UiTypography.Text>;
  }, [fileSize, file, createdAt, subtitleProp]);

  const actions = useMemo<ReactNode[]>(() => {
    if (actionsProp === null) {
      return [];
    }

    if (fileUrl) {
      return [
        <UiButton
          key="download"
          disabledFocus
          type="link-secondary"
          icon={<UiIcon component={DownloadSvg} width={20} height={20} />}
          onClick={(e) => {
            e.stopPropagation();
            message.info('Загрузка началась');

            downloadFileByURL(fileUrl, shortName)
              .then(() => message.success(`Файл ${shortName} загружен`))
              .catch(() => message.error('Ошибка загрузки'));
          }}
        />,
        ...actionsProp,
      ];
    }

    return actionsProp;
  }, [fileUrl, actionsProp]);

  const FileComponent = isVertical ? FileColumn : FileRow;

  return (
    <FileComponent
      isFolder={isFolder}
      title={title}
      subtitle={subtitle}
      file={file}
      actions={actions}
      {...restProps}
    />
  );
};

File.Actions = FileActions;
