import { UiCardProps, UiCard, UiEmpty, UiSkeleton, useBreakpoint } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo, Suspense } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { getContactsDictsStorage } from '@vkph/common/store/contacts';
import { getProfileContactsStorage } from '@vkph/common/store/profile';
import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { DictMatchTypes } from '@vkph/common/types/api';
import { ContactDictKinds, ContactLink, UserIdModel } from '@vkph/common/types/models';
import { formatPhoneNumber, PhoneMask, RegExpPattern } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';

import {
  EmployeeContactType,
  EmployeeContactsLink,
  EmployeeContactsLinkIcons,
} from './contact-link/EmployeeContactsLink';

const ContactsInfoDesktop = React.lazy(() => import('./desktop/ContactsInfoDesktopModule'));
const ContactsInfoMobile = React.lazy(() => import('./mobile/ContactsInfoMobileModule'));

type EmployeeContactsComponent = {
  Link: typeof EmployeeContactsLink;
};

export type EmployeeContactsProps = {
  userId: UserIdModel;
  style?: UiCardProps['style'];
};

const CONTACTS_MOCK = <UiSkeleton count={5} loading height={20} width="100%" style={{ marginBottom: 8 }} />;

export const formatPhoneNumberRu = (value?: string) =>
  formatPhoneNumber(value, RegExpPattern.InternationalRu, PhoneMask.InternationalRu);

export const EmployeeContacts: EmployeeContactsComponent & FC<PropsWithChildren<EmployeeContactsProps>> = (
  props,
) => {
  const { children, style, userId } = props;
  const { config } = useSettingsConfig();
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceS, spaceXL, spaceM } = useSpace();
  const headerPadding = usePaddingStyle(isLayoutLarge ? [spaceXL] : [spaceS, spaceM, 0]);
  const cardContentPadding = usePaddingStyle(isLayoutLarge ? [0, spaceXL] : [0, spaceM]);

  const profileContactsStorage = useMemo(() => getProfileContactsStorage({ userId }), [userId]);
  const {
    messengerDictsStorage,
    messengerDictsOptionsStore,
    externalLinkDictsStorage,
    externalLinkDictsOptionsStore,
  } = useMemo(getContactsDictsStorage, []);

  useAbstractStorage(messengerDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.Messenger } },
  });

  useAbstractStorage(externalLinkDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.ExternalLink } },
  });

  const {
    data: contactsData,
    loading: isContactsLoading,
    error: contactsError,
  } = useAbstractStorage(profileContactsStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
  });

  const messengerDictOptions = useStore(messengerDictsOptionsStore);
  const messengerIconByRecordId = useMemo(
    () => new Map(messengerDictOptions.map(({ value: recordId, icon }) => [recordId, icon])),
    [messengerDictOptions],
  );

  const externalLinkDictsOptions = useStore(externalLinkDictsOptionsStore);
  const externalLinkIconByRecordId = useMemo(
    () => new Map(externalLinkDictsOptions.map(({ recordId, icon, prefix }) => [recordId, { icon, prefix }])),
    [externalLinkDictsOptions],
  );

  const {
    workPhone,
    mobilePhone,
    workEmail,
    workPhoneExtension,
    personalEmail,
    otherLinks = [],
    externalLinks = [],
    otherContacts = [],
  } = contactsData || {};

  const socialLinks = useMemo<Array<ExternalLinkContact | ContactLink>>(
    () => [...externalLinks, ...otherLinks],
    [externalLinks, otherLinks],
  );

  const hasContacts = workPhone || mobilePhone || workEmail || personalEmail;
  const hasOtherContacts = otherContacts.length > 0;
  const hasSocialLinks = socialLinks.length > 0;

  const mainContactsArray = [
    {
      value: workPhone,
      href: `tel:${workPhone}`,
      label: formatPhoneNumberRu(workPhone),
      icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkPhone],
    },
    ...(config.layouts?.profileWorkPhoneExtension?.value
      ? [
          {
            value: workPhoneExtension,
            label: workPhoneExtension,
            icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkPhone],
          },
        ]
      : []),
    {
      value: mobilePhone,
      href: `tel:${mobilePhone}`,
      label: formatPhoneNumberRu(mobilePhone),
      icon: EmployeeContactsLinkIcons[EmployeeContactType.MobilePhone],
    },
    {
      value: workEmail,
      label: workEmail,
      href: `mailto:${workEmail}`,
      icon: EmployeeContactsLinkIcons[EmployeeContactType.WorkEmail],
    },
    {
      value: personalEmail,
      label: personalEmail,
      href: `mailto:${personalEmail}`,
      icon: EmployeeContactsLinkIcons[EmployeeContactType.PersonalEmail],
    },
  ];
  const mainContacts = useMemo(() => mainContactsArray.filter(({ value }) => value), [contactsData]);

  const contactsInfoProps = {
    mainContacts,
    otherContacts,
    socialLinks,
    messengerIcons: messengerIconByRecordId,
    externalLinkIcons: externalLinkIconByRecordId,
  };

  return (
    <UiCard
      emptyPadding
      style={{ paddingBottom: isLayoutLarge ? spaceXL : spaceM, ...style }}
      size={isLayoutLarge ? 'small' : 'default'}
    >
      <UiCard.Header style={headerPadding}>
        <UiCard.Header.Title>Контакты</UiCard.Header.Title>
      </UiCard.Header>

      <UiCard.Content style={cardContentPadding}>
        {contactsError && (
          <UiEmpty.Feed emptyMessage={{ header: 'Ошибка', description: 'Невозможно загрузить контакты' }} />
        )}

        {!hasContacts && !hasOtherContacts && !hasSocialLinks && !isContactsLoading && !contactsError && (
          <UiEmpty.Feed emptyMessage={{ header: 'Контакты не заполнены' }} />
        )}

        {isContactsLoading && CONTACTS_MOCK}
      </UiCard.Content>

      {isLayoutLarge && (hasContacts || hasOtherContacts || hasSocialLinks) && (
        <Suspense fallback={CONTACTS_MOCK}>
          <ContactsInfoDesktop {...contactsInfoProps} />
          {children}
        </Suspense>
      )}

      {!isLayoutLarge && (hasContacts || hasOtherContacts || hasSocialLinks) && (
        <Suspense fallback={CONTACTS_MOCK}>
          <ContactsInfoMobile {...contactsInfoProps}>{children}</ContactsInfoMobile>
        </Suspense>
      )}
    </UiCard>
  );
};

EmployeeContacts.Link = EmployeeContactsLink;
