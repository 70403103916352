import { UiImageBackground, UiImageBackgroundProps } from '@vkph/ui';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import patternAchievePng from '../../../assets/images/pattern-achieve.png';
import styles from './AchievementPlaceholder.scss';

export interface AchievementPlaceholderProps extends Omit<UiImageBackgroundProps, 'url'> {
  borderRadius?: CSSProperties['borderRadius'];
}

export const AchievementPlaceholder: FC<PropsWithChildren<AchievementPlaceholderProps>> = (props) => {
  const { children, borderRadius = 4 } = props;

  return (
    <UiImageBackground
      url={patternAchievePng}
      style={{ borderRadius }}
      className={styles.achievementPlaceholder}
    >
      {children}
    </UiImageBackground>
  );
};
