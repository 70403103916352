import { UiRow, UiCol, UiSkeleton, UiCard } from '@vkph/ui';
import React, { FC } from 'react';

import { useSpace } from '@vkph/ui/hooks';

export const MeetingRoomSkeleton: FC = () => {
  const { spaceM } = useSpace();

  return (
    <UiCard size="default" emptyPadding>
      <UiCard.Content>
        <UiRow gutter={spaceM}>
          <UiCol>
            <UiSkeleton loading width={96} height={96} />
          </UiCol>
          <UiCol>
            <UiSkeleton loading width={128} height={12} />
            <UiSkeleton loading width={88} height={10} />
          </UiCol>
        </UiRow>
      </UiCard.Content>
    </UiCard>
  );
};
