import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import mergeWith from 'lodash/mergeWith';
import React, { FC, useMemo } from 'react';

import { EditorValue } from '@vkph/common/types/editor';
import { EntityContentType } from '@vkph/common/types/models';

import { CkEditorConfig, editorConfiguration } from './config/editor-config';
import { EditorViewer } from './viewer/EditorViewer';

import './Editor.scss';

export interface EditorProps {
  value?: EditorValue;
  onChange?: (value: EditorValue) => void;
  config?: EditorConfig;
}

type EditorComposition = {
  Viewer: typeof EditorViewer;
};

type EditorComponent = FC<EditorProps> & EditorComposition;

export const Editor: EditorComponent = (props) => {
  const { value, onChange, config } = props;

  const editorArrayMergeCustomizer = (oldValue: unknown, newValue: unknown) => {
    return Array.isArray(oldValue) ? newValue : undefined;
  };

  const mergedConfig = useMemo<CkEditorConfig>(() => {
    return mergeWith({}, editorConfiguration, config, editorArrayMergeCustomizer);
  }, [config]);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={mergedConfig}
      data={value?.data}
      onChange={(_, editor) => {
        const data: EditorValue = {
          data: editor.getData(),
          version: EntityContentType.CKEditor5,
        };

        onChange?.(data);
      }}
    />
  );
};

Editor.Viewer = EditorViewer;
