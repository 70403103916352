import { UiCol, UiSkeleton, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { LayoutContentHeaderSize } from '../LayoutContentHeader';
import styles from '../LayoutContentHeader.scss';

interface Props {
  size: LayoutContentHeaderSize;
}

export const LayoutContentHeaderSkeleton: FC<Props> = (props) => {
  const { size } = props;
  const isHeaderSize = (headerSize: LayoutContentHeaderSize) => size === headerSize;

  return (
    <UiSpace size={isHeaderSize(LayoutContentHeaderSize.Small) ? 12 : 20} align="center">
      <UiSkeleton circle loading width={64} height={64} />
      <UiCol className={styles.layoutContentHeader__name}>
        <UiSkeleton loading width={80} height={20} />
        <UiSkeleton loading width={250} height={isHeaderSize(LayoutContentHeaderSize.Small) ? 20 : 32} />
      </UiCol>
    </UiSpace>
  );
};
