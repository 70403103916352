import {
  UiAvatar,
  UiAvatarProps,
  UiAvatarSize,
  UiCol,
  UiRow,
  UiRowProps,
  UiTypography,
  UiTypographyTitleProps,
  UiSpace,
  getSizeGutterBySizeFullName,
  SizeGutterPostfix,
  UiConfigProviderSizeContext,
  UiConfigProviderSizeType,
  UiTruncateMarkup,
} from '@vkph/ui';
import React, { useContext, useMemo, ReactNode, FC } from 'react';
import { Link } from 'react-router-dom';

import { UserModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames } from '@vkph/common/utils';

import { UserProfileInfoBase } from './base/UserProfileInfoBase';

export type GetFullName = (user: UserModel) => string;
export interface UserProfileInfoProps extends Omit<UiRowProps, 'size'> {
  user: UserModel;
  size?: UiConfigProviderSizeType | number;
  getFullName?: GetFullName;
  isOnline?: boolean;
  after?: ReactNode;
  avatarProps?: UiAvatarProps;
  fullNameProps?: UiTypographyTitleProps;
}

type SizeNameMap<T> = Record<SizeGutterPostfix, T>;

const gutterSizes: SizeNameMap<number> = { sm: 8, md: 12, lg: 16 };
const avatarSizes: SizeNameMap<UiAvatarSize> = {
  sm: UiAvatarSize.S,
  md: UiAvatarSize.M,
  lg: UiAvatarSize.XL,
};
const titleSizes: SizeNameMap<UiTypographyTitleProps['level']> = {
  sm: 5,
  md: 4,
  lg: 3,
};

export type UserProfileInfoComposition = {
  Base: typeof UserProfileInfoBase;
};

export const UserProfileInfo: FC<UserProfileInfoProps> & UserProfileInfoComposition = (props) => {
  const {
    size: propsSize,
    user,
    children,
    avatarProps,
    fullNameProps,
    isOnline,
    after,
    getFullName = getFullNameWithoutPatronymic,
    ...spaceWrapperProps
  } = props;
  const providerSize = useContext(UiConfigProviderSizeContext);
  const sizeName = propsSize && typeof propsSize !== 'number' ? propsSize : providerSize;
  const sizeGutterName = getSizeGutterBySizeFullName(sizeName || 'middle');

  const avatarSize = avatarSizes[sizeGutterName];
  const titleSize = titleSizes[sizeGutterName];
  const spaceSize = useMemo<number>(() => {
    if (propsSize && typeof propsSize === 'number') {
      return propsSize;
    }

    return gutterSizes[sizeGutterName];
  }, [propsSize, sizeGutterName]);

  const profileRoute = getRoutePath(RouteNames.Profile, { id: user.id });

  return (
    <UiRow gutter={0} {...spaceWrapperProps} wrap={false}>
      <Link to={profileRoute}>
        <UiAvatar size={avatarSize} src={user.smallAvatar} withBadge={isOnline} {...avatarProps} />
      </Link>
      <UiCol style={{ marginLeft: spaceSize }}>
        <Link to={profileRoute}>
          <UiTypography.Title level={titleSize} style={{ marginBottom: 0 }} {...fullNameProps}>
            <UiTruncateMarkup truncate>{getFullName(user)}</UiTruncateMarkup>
          </UiTypography.Title>
        </Link>
        {children}
      </UiCol>
      {after && <UiSpace style={{ marginLeft: 'auto' }}>{after}</UiSpace>}
    </UiRow>
  );
};

UserProfileInfo.Base = UserProfileInfoBase;
