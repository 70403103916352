import { PollButton, PollButtonTypes } from './poll-button/PollButton';
import { PollButtonInput } from './poll-button/input/PollButtonInput';
import { PollGroup } from './poll-group/PollGroup';

export interface PollComposition {
  Button: typeof PollButton;
  ButtonTypes: typeof PollButtonTypes;
  ButtonInput: typeof PollButtonInput;
  Group: typeof PollGroup;
}
export const Poll: PollComposition = {
  ButtonTypes: PollButtonTypes,

  Button: PollButton,
  ButtonInput: PollButtonInput,
  Group: PollGroup,
  // Poll.Progress = PollProgress; // TODO: объединить и переместить PollAnswersWrapper из group-frontend
};
