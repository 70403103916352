// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3016673f_auto-complete-list .ant-row.ant-form-item{width:100%}._3016673f_auto-complete-list .ant-form-item-control-input-content{display:flex}._3016673f_auto-complete-list .ant-form-item-control-input-content .ant-btn.ant-btn-icon-only{margin:auto 0 auto 16px}._3016673f_auto-complete-list .ant-select-selection-search>input:focus{box-shadow:none}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/auto-complete-list-field/AutoCompleteListField.scss"],"names":[],"mappings":"AAGI,qDACE,UAAA,CAGF,mEACE,YAAA,CAEA,8FACE,uBAAA,CAIJ,uEACE,eAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.auto-complete-list {\n  :global {\n    .ant-row.ant-form-item {\n      width: 100%;\n    }\n\n    .ant-form-item-control-input-content {\n      display: flex;\n\n      .ant-btn.ant-btn-icon-only {\n        margin: auto 0 auto 16px;\n      }\n    }\n\n    .ant-select-selection-search > input:focus {\n      box-shadow: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auto-complete-list": "_3016673f_auto-complete-list",
	"autoCompleteList": "_3016673f_auto-complete-list"
};
export default ___CSS_LOADER_EXPORT___;
