import { ToolbarConfigItem } from '@ckeditor/ckeditor5-core';

export const mergeEditorToolbar = (toolbars: ToolbarConfigItem[][]): ToolbarConfigItem[] => {
  const result = [];

  for (const [index, toolbar] of toolbars.entries()) {
    result.push(...toolbar);

    if (index < toolbars.length - 1) {
      result.push('|');
    }
  }

  return result;
};
