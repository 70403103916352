import { Command } from '@ckeditor/ckeditor5-core';
import { Notification } from '@ckeditor/ckeditor5-ui';
import { FileLoader, FileRepository } from '@ckeditor/ckeditor5-upload';

import { dataFileIdAttributeModelName } from './const';
import { FileUploadProgress } from './progress/FileUploadProgress';

export class FileUploadCommand extends Command {
  refresh() {
    this.isEnabled = true;
  }

  execute(options: { file: File[] }) {
    const { editor } = this;

    const fileRepository = editor.plugins.get(FileRepository);
    const filesToUpload = options.file;

    // eslint-disable-next-line no-restricted-syntax
    for (const file of filesToUpload) {
      const loader = fileRepository.createLoader(file);

      // Do not throw when upload adapter is not set. FileRepository will log an error anyway.
      if (!loader) {
        return;
      }

      this.readAndUpload(loader, file);
    }
  }

  readAndUpload(loader: FileLoader, file: File) {
    const { editor } = this;
    const { model } = editor;
    const fileRepository = editor.plugins.get(FileRepository);
    const notification = editor.plugins.get(Notification);

    const clean = () => {
      fileRepository.destroyLoader(loader);
    };

    loader
      .read()
      .then(() => {
        FileUploadProgress.createUiBar(editor, {
          uploadId: loader.id,
          name: file.name,
        });
        return loader.upload();
      })
      .then((data) => {
        const { urls, fileId } = data;

        model.change((writer) => {
          const linkedText = writer.createText(file.name, {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            linkHref: urls.default,
            [dataFileIdAttributeModelName]: fileId,
          });

          model.insertContent(linkedText);

          if (linkedText.parent) {
            writer.setSelection(linkedText, 'after');
          }

          editor.execute('enter');

          clean();
        });
      })
      .catch((error) => {
        if (loader.status !== 'error' && loader.status !== 'aborted') {
          throw error;
        }

        if (loader.status === 'error' && error) {
          notification.showWarning(error, {
            title: 'Ошибка загрузки файла',
            namespace: 'upload',
          });
        }

        clean();
      });
  }
}
