import { UiIcon, UiList, UiSpace, UiTruncateMarkup, UiTypography, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import { UserProfileModel } from '@vkph/common/types/models';
import { variables } from '@vkph/ui/providers/theme';
import CaseSvg from '@vkph/ui/svg/case.svg';
import OutStaffSvg from '@vkph/ui/svg/outstaff.svg';

import { colorTextInvertTooltipStyle } from '../../tooltip/UserProfileTooltip';

export type UserProfileInfoBaseProps = {
  user: Pick<UserProfileModel, 'position' | 'timezone' | 'remoteEmployee' | 'memberOf'>;
};

export const UserProfileInfoBase: FC<UserProfileInfoBaseProps> = (props) => {
  const { user } = props;
  const { position, timezone, remoteEmployee, memberOf } = user;

  return (
    <UiSpace direction="vertical" align="start" size={8}>
      {position && <UiTypography.Footnote type="secondary">{position.name}</UiTypography.Footnote>}

      {timezone && (
        <UiTypography.Text underline strong style={colorTextInvertTooltipStyle}>
          {timezone}
        </UiTypography.Text>
      )}

      {remoteEmployee && (
        <UiIcon.Label
          strong
          width={20}
          height={20}
          component={OutStaffSvg}
          label="Внешний сотрудник"
          color={variables.themePalette.colorNegative}
          type={UiTypographyTextTypes.Danger}
        />
      )}

      {memberOf && memberOf.length > 0 && (
        <UiSpace size={8} align="start">
          <UiIcon component={CaseSvg} width={20} height={20} color={variables.themePalette.colorIcon} />
          <UiList
            dataSource={Array.isArray(memberOf) ? memberOf : [memberOf]}
            renderItem={(member) => (
              <UiList.Item style={{ padding: 0, marginBottom: 8 }}>
                <UiTypography.Link underline style={colorTextInvertTooltipStyle}>
                  <UiTruncateMarkup truncate>{member}</UiTruncateMarkup>
                </UiTypography.Link>
              </UiList.Item>
            )}
          />
        </UiSpace>
      )}
    </UiSpace>
  );
};
