import classNames from 'classnames';
import React from 'react';

import { UserAvatarModel } from '@vkph/common/types/models';
import { UiMultiSelectItemProps } from '@vkph/ui/components/multi-select';
import { BaseDataType } from '@vkph/ui/types/option';

import { UserAvatar } from '../user-avatar';
import styles from './MultiSelectAvatarLabel.scss';

export interface MultiSelectAvatarLabelDataType
  extends BaseDataType,
    Partial<Pick<UserAvatarModel, 'avatar'>> {
  isRejected?: boolean;
  isActive?: boolean;
}

export type MultiSelectAvatarLabelProps = UiMultiSelectItemProps<string, MultiSelectAvatarLabelDataType>;

export const MultiSelectAvatarLabel = (props: MultiSelectAvatarLabelProps) => {
  const { data, isTransparent } = props;
  const { avatar, selectedLabel, isRejected, isActive } = data;

  return (
    <>
      <UserAvatar
        src={avatar}
        size={24}
        isActive={isActive}
        className={styles.multiSelectAvatarLabel__avatar}
      />
      <span
        className={classNames(styles.multiSelectAvatarLabel__label, {
          [styles.multiSelectAvatarLabel__label_transparent]: isTransparent,
          [styles.multiSelectAvatarLabel__label_rejected]: isRejected,
        })}
      >
        {selectedLabel}
      </span>
    </>
  );
};
