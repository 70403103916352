import {
  UiButton,
  UiCol,
  UiFormItemProps,
  UiIcon,
  UiImage,
  UiTypography,
  UiUploadRequestOption,
  isUiUploadOriginFile,
  message,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { MouseEvent, TouchEvent, useMemo } from 'react';

import {
  FileStorageApiVersions,
  uploadFileStorageAttachmentEffectFactory,
} from '@vkph/common/store/filestorage';
import {
  GlobalModalNames,
  GlobalModalsStorePayloads,
  closeGlobalModal,
  openGlobalModal,
} from '@vkph/common/store/global-modals';
import { FileStorageEntryId, FileStorageEntryUrl, PreloadedFileModel } from '@vkph/common/types/models';
import {
  FileUploadAccepts,
  checkFileSizeUploadEffect,
  getErrorResponseMessage,
  readFileToStringPromise,
} from '@vkph/common/utils';
import CloseSVG from '@vkph/ui/svg/close.svg';

import { FigureType } from '../../../crop-image-area';
import { UploadDraggerArea, getImageDimensions } from '../../../upload-dragger-area';
import styles from './ImageAnswer.scss';

export type SurveyImageAnswerFormValue = { imageUrl: FileStorageEntryUrl; imageId: FileStorageEntryId };

const IMAGE_CROP_TEXT_DATA = {
  rulesTitle: '',
  rulesList: [],
  cropTitle: 'Выберите область',
};

const CROP_ASPECT = 15 / 10;

export interface ImageAnswerProps extends UiFormItemProps<SurveyImageAnswerFormValue> {
  onRemove?: () => void;
  index?: number;
}

const IMAGE_MIN_WIDTH = 350;
const IMAGE_MIN_HEIGHT = 274;

type ImageUploadCropProps = GlobalModalsStorePayloads[GlobalModalNames.ImageUploadCrop]['payload'];

export const ImageAnswer: React.FC<ImageAnswerProps> = (props) => {
  const { value, index: answerShowIndex, onChange, onRemove } = props;
  const uploadAttachmentEffect = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>(FileStorageApiVersions.v3);
  }, []);
  const isLoadingImage = useStore(uploadAttachmentEffect.pending);

  const onSaveImage: ImageUploadCropProps['onChange'] = ({ file, id }) => {
    onChange?.({ imageId: id, imageUrl: file });
  };

  const onRemoveImage = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    onRemove?.();
  };

  const onOpenCropImageModal = (imageValue: ImageUploadCropProps['value']) => {
    openGlobalModal(GlobalModalNames.ImageUploadCrop, {
      title: 'Обрезка изображения',
      description: IMAGE_CROP_TEXT_DATA,
      minWidth: IMAGE_MIN_WIDTH,
      minHeight: IMAGE_MIN_HEIGHT,
      onChange: onSaveImage,
      onClose: () => closeGlobalModal(GlobalModalNames.ImageUploadCrop),
      figureType: FigureType.Rectangle,
      aspect: CROP_ASPECT,
      value: imageValue,
    });
  };

  const onUploadAttachment = async ({ file }: UiUploadRequestOption) => {
    try {
      if (!isUiUploadOriginFile(file)) {
        throw new Error('Неверный формат файла');
      }

      const [imageToUpload, previewImage] = await Promise.all([
        checkFileSizeUploadEffect({ file, maxSize: 10 }),
        readFileToStringPromise(file, true),
      ]);

      const { width, height } = await getImageDimensions(previewImage.data);

      if (width < IMAGE_MIN_WIDTH || height < IMAGE_MIN_HEIGHT) {
        throw new Error(`Минимальный размер фото ${IMAGE_MIN_WIDTH} × ${IMAGE_MIN_HEIGHT} пикселей.`);
      }

      const { storageObject, fileUrl } = await uploadAttachmentEffect(imageToUpload);

      return onOpenCropImageModal({
        previewImage,
        uploadImage: { id: storageObject, file: fileUrl },
      });
    } catch (e) {
      return message.error(getErrorResponseMessage(e, 'Не удалось загрузить изображение'));
    }
  };

  return (
    <>
      {value && (
        <UiCol className={styles.imageAnswer} style={{ width: IMAGE_MIN_WIDTH }}>
          {/* TODO:: Добавить Drag&Drop (B2BCORE-3282) */}
          <div className={styles.imageAnswer__controls}>
            <UiButton
              size="small"
              type="action"
              icon={<UiIcon component={CloseSVG} width={20} height={20} />}
              onClick={onRemoveImage}
            />
          </div>

          <UiImage height={IMAGE_MIN_HEIGHT} src={value.imageUrl} />
          <UiTypography.Text
            strong
            className={styles.imageAnswer__title}
          >{`Изображение ${answerShowIndex}`}</UiTypography.Text>
        </UiCol>
      )}

      {!value && (
        <UiCol style={{ width: IMAGE_MIN_WIDTH, height: IMAGE_MIN_HEIGHT }}>
          <UploadDraggerArea
            onUploadAttachment={onUploadAttachment}
            loading={isLoadingImage}
            multiple={false}
            accept={FileUploadAccepts.ImagePngJpg}
            wrapperClassName={styles.imageAnswer__dragDropArea}
          />
        </UiCol>
      )}
    </>
  );
};
