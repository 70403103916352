import { UiButton, UiIcon, UiSpace } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { isBlogPost, isMicropost } from '@vkph/common/types/guards';
import {
  BlogModel,
  BlogModerationType,
  PermissionsV2Enum,
  PostModel,
  PostStatuses,
} from '@vkph/common/types/models';
import { useTheme } from '@vkph/ui/providers/theme';
import DeleteSvg from '@vkph/ui/svg/delete.svg';
import EditSvg from '@vkph/ui/svg/edit.svg';
import FavoriteAddSvg from '@vkph/ui/svg/favorite-add.svg';
import FavoriteClearSvg from '@vkph/ui/svg/favorite-clear.svg';
import PinSvg from '@vkph/ui/svg/pin.svg';
import UnpinSvg from '@vkph/ui/svg/unpin.svg';

import { ActionsDropdown, ActionsDropdownItem } from '../../../actions-dropdown';

export type PostAction = () => void;
export type PostSwitchAction = (flag: boolean) => void;

type PostHeaderActionsProps = {
  post: PostModel;
  blog?: BlogModel;
  visible?: boolean;
  onEdit?: PostAction;
  onDelete?: PostAction;
  onPin?: PostSwitchAction;
  onFavorite?: PostSwitchAction;
};

export const PostHeaderActions: FC<PostHeaderActionsProps> = (props) => {
  const { visible = true, post, onEdit, onDelete, onFavorite, onPin, blog } = props;

  const { superAdmin } = useCurrentProfile();
  const [{ variables: themeVariables }] = useTheme();

  const isBlogModerationType = (type: BlogModerationType) => blog?.moderationType === type;
  const isPostStatus = (status: PostStatuses) => status === post.flag;

  const dropdownActions: ActionsDropdownItem[] = useMemo(() => {
    const isMicropostPost = isMicropost(post);
    const actions: ActionsDropdownItem[] = [];

    let hasDeletePermission =
      post?.permissionsV2?.[
        isMicropostPost ? PermissionsV2Enum.MicropostDelete : PermissionsV2Enum.EntryDelete
      ];
    let hasEditPermission =
      post?.permissionsV2?.[
        isMicropostPost ? PermissionsV2Enum.MicropostUpdate : PermissionsV2Enum.EntryUpdate
      ];
    const hasPinPermission =
      blog?.permissionsV2?.[PermissionsV2Enum.PinPosts] && isPostStatus(PostStatuses.FlagPublished);

    if (isBlogModerationType(BlogModerationType.Premoderation)) {
      hasEditPermission =
        (hasEditPermission && isPostStatus(PostStatuses.AwaitingApprove)) ||
        Boolean(blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts]);
      hasDeletePermission =
        (hasDeletePermission && isPostStatus(PostStatuses.AwaitingApprove)) ||
        Boolean(blog?.permissionsV2?.[PermissionsV2Enum.ModeratePosts]);
    }

    const postEditPeriodInHours =
      isMicropostPost && post.updatePeriodMinutes ? post.updatePeriodMinutes / 60 : null;
    const canEdit = superAdmin || hasEditPermission;

    if (postEditPeriodInHours && !canEdit) {
      actions.push({
        label: `Редактировать пост можно в течение ${postEditPeriodInHours}ч. после публикации`,
        disabled: true,
      });
    }

    if (onEdit && canEdit) {
      actions.push({
        label: 'Редактировать',
        onClick: onEdit,
        icon: EditSvg,
      });
    }

    if (onPin && isBlogPost(post) && hasPinPermission) {
      actions.push({
        label: post.isPinned ? 'Открепить' : 'Закрепить',
        onClick: () => onPin(!post.isPinned),
        icon: post.isPinned ? UnpinSvg : PinSvg,
      });
    }

    if (onDelete && hasDeletePermission) {
      actions.push({ label: 'Удалить', onClick: onDelete, icon: DeleteSvg });
    }

    return actions;
  }, [superAdmin, blog?.permissionsV2, post, onEdit, onDelete]);

  const isFavoriteVisible =
    (isBlogPost(post) && isPostStatus(PostStatuses.FlagPublished)) || isMicropost(post);

  return (
    <UiSpace size={16} align="start">
      {isFavoriteVisible && (
        <UiButton
          type="link-secondary"
          disabledFocus
          onClick={() => onFavorite?.(!post.favorite)}
          icon={
            <UiIcon
              component={post.favorite ? FavoriteClearSvg : FavoriteAddSvg}
              width={20}
              height={20}
              color={post.favorite ? themeVariables.colorBrand32 : undefined}
            />
          }
        />
      )}
      {visible && (
        <ActionsDropdown
          items={dropdownActions}
          overlayStyle={{ maxWidth: 220 }}
          disabled={!dropdownActions.length}
        />
      )}
    </UiSpace>
  );
};
