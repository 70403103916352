import { UiSpace, UiTypography, UiCollapse, UiCollapsePanelProps } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { useLocalStorage } from '@vkph/common/hooks';

export interface CollapseStoredProps extends Omit<UiCollapsePanelProps, 'key'> {
  name: string;
  initialValue?: boolean;
}

export const CollapseStored: FC<PropsWithChildren<CollapseStoredProps>> = (props) => {
  const { name, initialValue, children, header, extra, ...restProps } = props;
  const localStorageKey = `CollapseStored_${name}`;
  const [isExpanded, setIsExpanded] = useLocalStorage(localStorageKey, initialValue);

  const onChange = () => setIsExpanded(!isExpanded);

  const CollapseHeader: FC = () => {
    return (
      <UiSpace size={8}>
        {extra}
        <UiTypography.Text strong>{header}</UiTypography.Text>
      </UiSpace>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-key
    <UiCollapse
      ghost
      defaultActiveKey={isExpanded ? localStorageKey : undefined}
      onChange={onChange}
      expandIconPosition="end"
    >
      <UiCollapse.Panel
        {...restProps}
        extraPosition="start"
        extra={<CollapseHeader />}
        header={null}
        key={localStorageKey}
        basePadding
        unsetActive
        unsetHover
      >
        {children}
      </UiCollapse.Panel>
    </UiCollapse>
  );
};
