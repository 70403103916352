import { ReactionModel, ReactionsCountInfo, ReactionsTypesModel } from '@vkph/common/types/models';

export const getTotalReactions = (reactions: ReactionModel[], reactionsData: ReactionsTypesModel[]) => {
  const predictedFn = (acc: ReactionsCountInfo[], item: ReactionsTypesModel) => {
    return [
      ...acc,
      {
        id: item.id,
        count: reactions?.find((r) => r.reactionId === item.id)?.reactionCount || 0,
      },
    ];
  };

  return reactionsData.reduce(predictedFn, []);
};
