import {
  UiRow,
  UiIcon,
  UiTypography,
  UiTypographyTextTypes,
  UiSpace,
  UiTruncateMarkup,
  UiAvatar,
  UiAvatarProps,
  BadgeColors,
  UiBadge,
  UiBadgeProps,
  getSizeGutterBySizeFullName,
  SizeGutterPostfix,
  UiConfigProviderSizeContext,
  UiConfigProviderSizeType,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { CSSProperties, FC, PropsWithChildren, useContext } from 'react';

import { useAbsolutePlacementShift, AbsolutePlacement } from '@vkph/ui/hooks';
import Appreaction3Svg from '@vkph/ui/svg/appreaction-3.svg';
import VoteUpSvg from '@vkph/ui/svg/vote-up.svg';

import { AchievementCoins, AchievementCoinsProps } from '../coins/AchievementCoins';
import styles from './AchievementIcon.scss';

type SizeNameMap<T> = Record<SizeGutterPostfix, T>;

export interface AchievementIconProps extends Pick<UiAvatarProps, 'style' | 'src'> {
  name?: string;
  disabled?: boolean;
  isSelected?: boolean;
  type: 'badge' | 'thank';
  size?: UiConfigProviderSizeType;
  badge?: UiBadgeProps['count'];
  badgeProps?: UiBadgeProps;
  coins?: AchievementCoinsProps['label'];
  coinsProps?: AchievementCoinsProps;
}

const avatarSizes: SizeNameMap<number> = { sm: 72, md: 80, lg: 120 };
const avatarPlaceholderSizes: SizeNameMap<number> = { sm: 48, md: 54, lg: 72 };
const nameStyles: CSSProperties = { display: 'block', textAlign: 'center' };

export const AchievementIcon: FC<PropsWithChildren<AchievementIconProps>> = (props) => {
  const { type, src, size, isSelected, disabled, ...additionalProps } = props;
  const { children: users, name, badge, badgeProps, coins, coinsProps } = additionalProps;

  const providerSize = useContext(UiConfigProviderSizeContext);
  const sizeName = size || providerSize || 'large';
  const sizeGutterName = getSizeGutterBySizeFullName(sizeName);
  const avatarSize = avatarSizes[sizeGutterName];
  const placeholderSize = avatarPlaceholderSizes[sizeGutterName];

  const hasCoins = Boolean(coins);
  const hasUsers = Boolean(users);

  const avatarStyles = classNames({
    [styles.achievementIcon_disabled]: disabled,
  });

  const { placementCls, placementStyles } = useAbsolutePlacementShift({
    placement: AbsolutePlacement.TopCenter,
  });
  const hasUsersAvatarStyles = classNames(
    {
      [styles.achievementIcon_selected]: isSelected,
    },
    hasUsers && placementCls,
  );

  const mergedBadgeProps = {
    showZero: true,
    color: disabled ? BadgeColors.Grey : BadgeColors.Primary,
    ...badgeProps,
  };

  const icon = type === 'badge' ? Appreaction3Svg : VoteUpSvg;
  const placeholderIcon = <UiIcon component={icon} width={placeholderSize} height={placeholderSize} />;
  const coinsIcon = <AchievementCoins type={UiTypographyTextTypes.Primary} {...coinsProps} label={coins} />;
  const avatarIcon = (
    <UiRow style={{ height: avatarSize, ...(hasUsers && placementStyles) }} className={hasUsersAvatarStyles}>
      <UiBadge {...mergedBadgeProps} count={badge} className={styles.achievementIcon__badge_center}>
        <UiAvatar src={src} size={avatarSize} icon={placeholderIcon} className={avatarStyles} />
      </UiBadge>
    </UiRow>
  );

  const SpaceNamedCoins: FC<PropsWithChildren> = (parentProps) => {
    const { children } = parentProps;
    const hasNameOrCoins = name || hasCoins;
    const spaceSize = hasCoins ? 16 : 12;

    return (
      <UiSpace align="center" direction="vertical" size={hasNameOrCoins ? spaceSize : 0}>
        {children}

        {!name && hasCoins && coinsIcon}
        {name && (
          <UiTypography.Text strong type="secondary" style={{ ...nameStyles, width: avatarSize }}>
            <UiTruncateMarkup truncate lines={2}>
              {name}
            </UiTruncateMarkup>
          </UiTypography.Text>
        )}
      </UiSpace>
    );
  };

  return (
    <div className={styles.achievementIcon}>
      {hasUsers && avatarIcon}

      <SpaceNamedCoins>
        {!hasUsers && avatarIcon}
        {hasUsers && (
          <UiSpace size={88} style={{ height: avatarSize }}>
            {users}
          </UiSpace>
        )}
      </SpaceNamedCoins>
    </div>
  );
};
