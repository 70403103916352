import ReactionCry from '@vkph/ui/svg/reaction-cry.svg';
import ReactionHeartEyes from '@vkph/ui/svg/reaction-heart-eyes.svg';
import ReactionLike from '@vkph/ui/svg/reaction-like.svg';
import ReactionOpenMouth from '@vkph/ui/svg/reaction-open-mouth.svg';
import ReactionRage from '@vkph/ui/svg/reaction-rage.svg';
import ReactionSatisfied from '@vkph/ui/svg/reaction-satisfied.svg';

export const reactionsIcons = [
  {
    name: 'like',
    icon: ReactionLike,
  },
  {
    name: 'heart-eyes',
    icon: ReactionHeartEyes,
  },
  {
    name: 'satisfied',
    icon: ReactionSatisfied,
  },
  {
    name: 'open-mouth',
    icon: ReactionOpenMouth,
  },
  {
    name: 'cry',
    icon: ReactionCry,
  },
  {
    name: 'rage',
    icon: ReactionRage,
  },
];
