import { type Editor, icons, type PluginCollection } from '@ckeditor/ckeditor5-core';
import { logWarning } from '@ckeditor/ckeditor5-utils';

import type {
  VideoStyleConfig,
  VideoStyleDropdownDefinition,
  VideoStyleOptionDefinition,
} from '../videoconfig';

function warnInvalidStyle(info: object): void {
  logWarning('video-style-configuration-definition-invalid', info);
}

const { objectFullWidth, objectLeft, objectRight, objectCenter, objectBlockLeft, objectBlockRight } = icons;

export const DEFAULT_OPTIONS: Record<string, VideoStyleOptionDefinition> = {
  get alignLeft() {
    return {
      name: 'alignLeft',
      title: 'Выравнивание по левому краю',
      icon: objectLeft,
      modelElements: ['videoBlock'],
      className: 'video-style-align-left',
    };
  },

  get alignBlockLeft() {
    return {
      name: 'alignBlockLeft',
      title: 'Выравнивание по левому краю',
      icon: objectBlockLeft,
      modelElements: ['videoBlock'],
      className: 'video-style-block-align-left',
    };
  },

  get alignCenter() {
    return {
      name: 'alignCenter',
      title: 'Выравнивание по центру',
      icon: objectCenter,
      modelElements: ['videoBlock'],
      className: 'video-style-align-center',
    };
  },

  get alignRight() {
    return {
      name: 'alignRight',
      title: 'Выравнивание по правому краю',
      icon: objectRight,
      modelElements: ['videoBlock'],
      className: 'video-style-align-right',
    };
  },

  get alignBlockRight() {
    return {
      name: 'alignBlockRight',
      title: 'Выравнивание по правому краю',
      icon: objectBlockRight,
      modelElements: ['videoBlock'],
      className: 'video-style-block-align-right',
    };
  },

  get block() {
    return {
      name: 'block',
      title: 'Выравнивание по центру',
      icon: objectCenter,
      modelElements: ['videoBlock'],
      isDefault: true,
    };
  },

  get side() {
    return {
      name: 'side',
      title: 'Боковое видео',
      icon: objectRight,
      modelElements: ['videoBlock'],
      className: 'video-style-side',
    };
  },
};

export const DEFAULT_ICONS: Record<string, string> = {
  full: objectFullWidth,
  left: objectBlockLeft,
  right: objectBlockRight,
  center: objectCenter,
  inlineLeft: objectLeft,
  inlineRight: objectRight,
};

export const DEFAULT_DROPDOWN_DEFINITIONS: Array<VideoStyleDropdownDefinition> = [
  {
    name: 'videoStyle:wrapText',
    title: 'Обтекать текст',
    defaultItem: 'videoStyle:alignLeft',
    items: ['videoStyle:alignLeft', 'videoStyle:alignRight'],
  },
  {
    name: 'videoStyle:breakText',
    title: 'Разрывать текст',
    defaultItem: 'videoStyle:block',
    items: ['videoStyle:alignBlockLeft', 'videoStyle:block', 'videoStyle:alignBlockRight'],
  },
];

function isValidOption(
  option: VideoStyleOptionDefinition,
  { isBlockPluginLoaded }: { isBlockPluginLoaded: boolean },
): boolean {
  const { modelElements, name } = option;

  if (!modelElements || !modelElements.length || !name) {
    warnInvalidStyle({ style: option });

    return false;
  }

  const supportedElements = [isBlockPluginLoaded ? 'videoBlock' : null];

  if (!modelElements.some((elementName) => supportedElements.includes(elementName))) {
    logWarning('video-style-missing-dependency', {
      style: option,
      missingPlugins: modelElements.map(() => 'VideoBlockEditing'),
    });

    return false;
  }

  return true;
}

function extendStyle(
  source: VideoStyleOptionDefinition,
  style: Partial<VideoStyleOptionDefinition>,
): VideoStyleOptionDefinition {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extendedStyle: Record<string, any> = { ...style };

  for (const prop in source) {
    if (!Object.prototype.hasOwnProperty.call(style, prop)) {
      extendedStyle[prop] = source[prop as keyof typeof source];
    }
  }

  return extendedStyle as VideoStyleOptionDefinition;
}

function normalizeDefinition(
  definition: string | (Partial<VideoStyleOptionDefinition> & { name: string }),
): VideoStyleOptionDefinition {
  if (typeof definition === 'string') {
    if (!DEFAULT_OPTIONS[definition]) {
      // eslint-disable-next-line no-param-reassign
      definition = { name: definition };
    } else {
      // eslint-disable-next-line no-param-reassign
      definition = { ...DEFAULT_OPTIONS[definition] };
    }
  } else {
    // eslint-disable-next-line no-param-reassign
    definition = extendStyle(DEFAULT_OPTIONS[definition.name], definition);
  }

  if (typeof definition.icon === 'string') {
    // eslint-disable-next-line no-param-reassign
    definition.icon = DEFAULT_ICONS[definition.icon] || definition.icon;
  }

  return definition as VideoStyleOptionDefinition;
}

function normalizeStyles(config: {
  isBlockPluginLoaded: boolean;
  configuredStyles: VideoStyleConfig;
}): Array<VideoStyleOptionDefinition> {
  const configuredStyles = config.configuredStyles.options || [];

  return configuredStyles
    .map((arrangement) => normalizeDefinition(arrangement))
    .filter((arrangement) => isValidOption(arrangement, config));
}

function getDefaultStylesConfiguration(isBlockPluginLoaded: boolean): VideoStyleConfig {
  if (isBlockPluginLoaded) {
    return {
      options: [
        'alignLeft',
        'alignRight',
        'alignCenter',
        'alignBlockLeft',
        'alignBlockRight',
        'block',
        'side',
      ],
    };
  }

  return {};
}

function getDefaultDropdownDefinitions(
  pluginCollection: PluginCollection<Editor>,
): Array<VideoStyleDropdownDefinition> {
  if (pluginCollection.has('VideoBlockEditing')) {
    return [...DEFAULT_DROPDOWN_DEFINITIONS];
  }

  return [];
}

// eslint-disable-next-line import/no-default-export
export default {
  normalizeStyles,
  getDefaultStylesConfiguration,
  getDefaultDropdownDefinitions,
  warnInvalidStyle,
  DEFAULT_OPTIONS,
  DEFAULT_ICONS,
  DEFAULT_DROPDOWN_DEFINITIONS,
};
