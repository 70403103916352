import { UiSkeleton, UiSkeletonProps, UiSpace, UiSpaceProps } from '@vkph/ui';
import React, { FC, CSSProperties } from 'react';

export interface UserProfileSkeletonProps extends UiSpaceProps {
  count?: UiSkeletonProps['count'];
  width?: CSSProperties['width'];
}

export const UserProfileSkeleton: FC<UserProfileSkeletonProps> = (props) => {
  const { count = 2, width = 300, style, ...spaceProps } = props;

  return (
    <UiSpace size={16} style={{ padding: 20, ...style }} align="start" {...spaceProps}>
      <UiSkeleton loading circle width={64} height={64} />
      <UiSkeleton loading count={count} width={width} height={20} />
    </UiSpace>
  );
};
