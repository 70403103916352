import { UiIcon, UiIconLabelProps } from '@vkph/ui';
import React, { FC } from 'react';

import { numberAbbreviator } from '@vkph/common/utils';
import ViewSvg from '@vkph/ui/svg/view.svg';

export interface ReactionsViewsProps extends Pick<UiIconLabelProps, 'style'> {
  views: number;
}

export const ReactionsViews: FC<ReactionsViewsProps> = (props) => {
  const { views, style } = props;

  return <UiIcon.Label label={numberAbbreviator(views)} component={ViewSvg} style={style} />;
};
