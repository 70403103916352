// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8c6bf83c_image-grid{box-sizing:content-box}._8c6bf83c_image-grid__row{display:inline-block}._8c6bf83c_image-grid__row div:first-of-type{margin-left:0}._8c6bf83c_image-grid__row div:last-of-type{margin-right:0}", "",{"version":3,"sources":["webpack://./../../libs/components/src/components/image-grid/ImageGrid.scss"],"names":[],"mappings":"AACA,sBAEE,sBAAA,CAEA,2BACE,oBAAA,CAEA,6CACE,aAAA,CAGF,4CACE,cAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.image-grid {\n  // NOTE: Fixes a bug with scroll moving content\n  box-sizing: content-box;\n\n  &__row {\n    display: inline-block;\n\n    & div:first-of-type {\n      margin-left: 0;\n    }\n\n    & div:last-of-type {\n      margin-right: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-grid": "_8c6bf83c_image-grid",
	"imageGrid": "_8c6bf83c_image-grid",
	"image-grid__row": "_8c6bf83c_image-grid__row",
	"imageGrid__row": "_8c6bf83c_image-grid__row"
};
export default ___CSS_LOADER_EXPORT___;
