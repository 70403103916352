import { UiRow } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';
import { animated, useTransition } from 'react-spring';

type ReactionsLikesAnimatedItem = { icon: ReactNode; key: string; width: string | number };

type Props = {
  items: ReactionsLikesAnimatedItem[];
  rateBtnSize: number | string;
};

export const ReactionsLikesAnimatedIcons: FC<Props> = (props) => {
  const { items, rateBtnSize } = props;

  const transitions = useTransition(items, {
    keys: (item) => item.key,
    initial: { transform: `translateX(0)`, opacity: 1 },
    update: { transform: `translateX(${rateBtnSize})`, opacity: 1 },
    leave: { transform: `translateX(${rateBtnSize})`, opacity: 0, width: 0 },
    config: { mass: 1, tension: 250, friction: 20 },
  });

  return (
    <UiRow style={{ marginLeft: 4 }}>
      {transitions((style, item) => (
        <animated.div key={item.key} style={style}>
          {item.icon}
        </animated.div>
      ))}
    </UiRow>
  );
};
