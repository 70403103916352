import { UiButton, UiIcon, UiImage, UiImageProps } from '@vkph/ui';
import React from 'react';

import ClearSVG from '@vkph/ui/svg/clear.svg';

import styles from './ImageActions.scss';

export interface ImageActionsProps extends UiImageProps {
  onClear?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
  wrapperClassName?: string;
}

export const ImageActions: React.FC<ImageActionsProps> = (props) => {
  const { onClear, children, wrapperClassName, ...args } = props;

  const { width, height } = args;

  return (
    <div className={wrapperClassName} style={{ width, height, position: 'relative' }}>
      <UiImage {...args} />
      <span className={styles.imageActions}>
        {children}
        {onClear && (
          <UiButton
            size="small"
            type="action"
            onClick={onClear}
            icon={<UiIcon width={20} height={20} component={ClearSVG} />}
          />
        )}
      </span>
    </div>
  );
};
