import { UiPagination, UiPaginationProps } from '@vkph/ui';
import qs from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useAbstractStorage } from '@vkph/common/hooks';
import { PaginationParams } from '@vkph/common/types/api';
import { HistoryActionType } from '@vkph/common/types/history';
import { AbstractStorage } from '@vkph/common/utils';
import { scrollPageTop } from '@vkph/ui/utils/scrollPageTop';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DefaultAbstractStorage = AbstractStorage<any, any, any, PaginationParams>;

export interface PaginatedListProps<Storage extends DefaultAbstractStorage = DefaultAbstractStorage>
  extends UiPaginationProps {
  storage: Storage;
  children: ({
    data,
    isLoading,
  }: {
    data: ReturnType<Storage['store']['getState']>['data'];
    isLoading: boolean;
  }) => React.ReactElement;
  query?: Omit<Parameters<Storage['fetchEffect']>[0], 'pageNumber' | 'pageSize'>;
  paginationClassName?: string;
  historyAction?: HistoryActionType;
  isAutoScrolling?: boolean;
}

const DEFAULT_PAGE_SIZE = 8;

export const PaginatedList = <T extends DefaultAbstractStorage>(props: PaginatedListProps<T>) => {
  const {
    storage,
    query = {},
    children,
    pageSize = DEFAULT_PAGE_SIZE,
    paginationClassName,
    historyAction = HistoryActionType.Push,
    isAutoScrolling = true,
    ...rest
  } = props;

  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();

  const parsedSearch = useMemo(() => qs.parse(search), [search]);

  useDeepCompareEffect(() => {
    navigate(
      {
        pathname,
        hash,
        search: qs.stringify({
          ...parsedSearch,
          p: 1,
        }),
      },
      { replace: historyAction === HistoryActionType.Replace },
    );
  }, [query]);

  const currentPage = useMemo(() => {
    const { p } = qs.parse(search);

    return Number(p) || 1;
  }, [search]);

  const {
    data,
    loading: isLoading,
    fetchFx,
    pagination,
  } = useAbstractStorage(storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { ...query, pageNumber: currentPage, pageSize },
    cancelPendingRequestOnUnmount: true,
  });
  const total = pagination.count;

  useEffect(() => {
    return () => {
      if (isLoading && isAutoScrolling) {
        scrollPageTop();
      }
    };
  }, [isLoading, isAutoScrolling]);

  const handlePageChange = useCallback(
    (value: number) => {
      navigate(
        {
          pathname,
          hash,
          search: qs.stringify({
            ...qs.parse(search),
            p: value.toString(),
          }),
        },
        { replace: true },
      );
    },
    [pathname, search, hash],
  );

  useEffect(
    () =>
      fetchFx.fail.watch(({ error }): void => {
        const { response } = error;

        if (response?.status === 404 && currentPage !== 1) {
          navigate({
            pathname,
            hash,
            search: qs.stringify({
              ...qs.parse(search),
              p: 1,
            }),
          });
        }
      }),
    [currentPage, search, fetchFx, pathname, hash],
  );

  return (
    <div>
      {children({ data, isLoading })}
      <UiPagination
        className={paginationClassName}
        onChange={handlePageChange}
        hideOnSinglePage
        current={currentPage}
        pageSize={Number(pageSize)}
        showSizeChanger={false}
        total={total}
        {...rest}
      />
    </div>
  );
};
