import { UiButton, UiFile, UiList } from '@vkph/ui';
import React, { FC, CSSProperties, useEffect, useMemo, useState } from 'react';

import { FileStorageObjectModel } from '@vkph/common/types/models';
import { declension, filesDeclension } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { File } from '../file';

interface Props {
  maxCollapsedCount?: number;
  compact?: boolean;
  fileStorageObjects: FileStorageObjectModel[];
}

export const FileListCollapsed: FC<Props> = (props) => {
  const { maxCollapsedCount = 3, fileStorageObjects, compact } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { spaceXS } = useSpace({ unit: 'px' });

  useEffect(() => {
    setIsCollapsed(fileStorageObjects.length > maxCollapsedCount);
  }, [fileStorageObjects]);

  const restFileCount = fileStorageObjects.length - maxCollapsedCount;
  const moreButtonLabel = `еще ${restFileCount} ${declension(restFileCount, filesDeclension)}`;

  const showedItems = useMemo(
    () => fileStorageObjects.filter((_, index) => (isCollapsed ? index < maxCollapsedCount : true)),
    [fileStorageObjects, isCollapsed, maxCollapsedCount],
  );

  const baseStyles: CSSProperties = { marginBottom: 8 };

  return (
    <>
      <UiList
        dataSource={showedItems}
        renderItem={(file) =>
          compact ? (
            <UiFile.Compact style={baseStyles} url={file.file} label={file.name} />
          ) : (
            <File
              style={baseStyles}
              fileName={file.name}
              file={{ ...file, filesCount: 0, foldersCount: 0 }}
            />
          )
        }
      />
      {isCollapsed && (
        <UiButton
          type="link"
          label={moreButtonLabel}
          onClick={() => setIsCollapsed(false)}
          style={{ margin: `${spaceXS} 0` }}
        />
      )}
    </>
  );
};
