import { UiList, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { ExternalLinkContact } from '@vkph/common/store/profile/api';
import { ContactLink } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';
import LinkSvg from '@vkph/ui/svg/link.svg';

import { SocialLink } from '../../../social-link';
import { EmployeeContactsLink } from '../../contact-link/EmployeeContactsLink';

export type ExternalLinkIcon = {
  icon: string;
  prefix: string;
};

type Props = {
  socialLinksItems: (ContactLink | ExternalLinkContact)[];
  externalLinkIcons?: Map<string, ExternalLinkIcon>;
};

export const SocialLinksList: FC<Props> = (props) => {
  const { socialLinksItems, externalLinkIcons } = props;
  const { spaceS, spaceXS } = useSpace();

  return (
    <UiSpace align="start" size={spaceXS} style={{ marginTop: spaceS }}>
      <EmployeeContactsLink.Icon component={LinkSvg} height={32} />
      <UiList
        grid={{ gutter: spaceXS }}
        itemLayout="horizontal"
        dataSource={socialLinksItems}
        renderItem={({ value, name, recordId }) => {
          const { icon, prefix } = externalLinkIcons?.get(recordId) || {};

          return (
            value && (
              <UiList.Item style={{ marginBottom: spaceXS }}>
                <SocialLink value={value} name={name} iconUrl={icon} prefixUrl={prefix} />
              </UiList.Item>
            )
          );
        }}
      />
    </UiSpace>
  );
};
