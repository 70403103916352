import { UiTypography } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { SurveyQuestionSection, SurveyQuestionSectionProps } from './section/SurveyQuestionSection';

export interface SurveyQuestionProps extends SurveyQuestionSectionProps {
  title: string;
}

export const SurveyQuestion: FC<PropsWithChildren<SurveyQuestionProps>> = (componentProps) => {
  const { title, children, ...props } = componentProps;

  return (
    <SurveyQuestionSection {...props}>
      <UiTypography.Title level={1} style={{ margin: '0 0 16px' }}>
        {title}
      </UiTypography.Title>
      {children}
    </SurveyQuestionSection>
  );
};
