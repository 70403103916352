import HTMLReactParser, { attributesToProps, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { marked } from 'marked';
import React from 'react';

enum Tags {
  A = 'a',
  Br = 'br',
  P = 'p',
}

export type HTMLParserHandler = typeof HTMLReactParser;

export const optionsParsedLinksAndText: HTMLReactParserOptions = {
  replace: (domNode) => {
    const isElement = 'name' in domNode && 'attribs' in domNode;

    if (isElement && domNode.name === Tags.A) {
      const props = attributesToProps(domNode.attribs);

      return (
        <a {...props} rel="noopener noreferrer" target="_blank">
          {domToReact(domNode.children)}
        </a>
      );
    }

    if (isElement && ([Tags.P, Tags.Br] as string[]).includes(domNode.name)) {
      return <>{domToReact(domNode.children, optionsParsedLinksAndText)}</>;
    }

    return null;
  },
};

export const HTMLParser: HTMLParserHandler = (text, options = optionsParsedLinksAndText) => {
  const markedText = marked(text);

  return HTMLReactParser(markedText, options);
};
