import { UiButton, UiCard, UiIcon, UiList, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import { variables } from '@vkph/ui/providers/theme';
import ArrowFrontSVG from '@vkph/ui/svg/arrow-front.svg';

export type CardLinksProps = {
  header: { title: string; backgroundColor: string };
  icon?: SvgrComponent;
  links: { title: string; link: string }[];
};

export const CardLinks: FC<CardLinksProps> = (props) => {
  const { header, links, icon } = props;
  const headerColor = variables.themePalette.white;
  const headerIcon = icon && <UiIcon component={icon} width={20} height={20} color={headerColor} />;
  const navigate = useNavigate();
  const { spaceS, spaceM, spaceXL } = useSpace();
  const { padding } = usePaddingStyle([spaceS, spaceXL]);
  const { padding: listPadding } = usePaddingStyle([spaceM, 0]);

  const Title = (
    <UiCard.Header style={{ background: header.backgroundColor }}>
      <UiCard.Header.Title style={{ color: headerColor }} icon={headerIcon}>
        {header.title}
      </UiCard.Header.Title>
    </UiCard.Header>
  );

  return (
    <UiCard emptyPadding size="default" title={Title}>
      <UiList
        split={false}
        bordered={false}
        dataSource={links}
        style={{ padding: listPadding }}
        renderItem={(linkItem) => (
          <UiList.Item onClick={() => navigate(linkItem.link)} style={{ padding }}>
            <UiTypography.Text strong>{linkItem.title}</UiTypography.Text>
            <UiButton
              type="link-secondary"
              icon={<UiIcon component={ArrowFrontSVG} width={20} height={20} />}
            />
          </UiList.Item>
        )}
      />
    </UiCard>
  );
};
