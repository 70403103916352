import { UiCol, UiRow, UiSkeleton, UiSpace } from '@vkph/ui';
import React, { FC, CSSProperties } from 'react';

import { createArrayMock } from '@vkph/common/utils';

const MOCKS_MONTH_COUNT = 12;
const POSITION_STYLE: CSSProperties = { width: '100%', display: 'flex' };

export const GanttCompactLoading: FC = () => {
  return (
    <>
      <UiRow style={{ ...POSITION_STYLE }} justify="center" align="middle">
        {createArrayMock(MOCKS_MONTH_COUNT, (_, key) => (
          <UiCol key={key} span={2}>
            <UiSpace full direction="vertical" align="center">
              <UiSkeleton loading width={70} height={16} />
            </UiSpace>
          </UiCol>
        ))}
      </UiRow>
      <UiRow style={{ ...POSITION_STYLE, height: 'fit-content' }}>
        {[1, 4, 10].map((offset) => (
          <UiCol key={offset} span={12} offset={offset}>
            <UiSkeleton loading width="100%" height={20} />
          </UiCol>
        ))}
      </UiRow>
    </>
  );
};
