// TODO: Префикс добавляемый к id, чтобы показать что это id PULL. Задумка дизайнера и аналитика
import { UiIcon } from '@vkph/ui';
import React from 'react';

import { SurveyId } from '@vkph/common/types/models';

import { SurveyQuestionData } from './survey-answer/types';

// + добавляются нули, чтобы имя было такого вида - PULL-001, PULL-022 и т.д.
export const makeCustomId = (surveyId?: SurveyId) => `PLL-${String(surveyId).padStart(3, '0')}`;

export const mapQuestionTypeToMenu = (typeObj: SurveyQuestionData) => ({
  ...typeObj,
  key: typeObj.value,
  icon: <UiIcon component={typeObj.icon} width={20} height={20} />,
});
