import React, { FC } from 'react';

import { PostFormAttachmentId } from '@vkph/common/types/models';

import styles from './ImageList.scss';
import { ImageListItem } from './item/ImageListItem';

export type ImageListImage = {
  id: PostFormAttachmentId;
  url: string;
};

export type ImageListProps = {
  images: ImageListImage[];
  onDelete?: (id: PostFormAttachmentId) => void;
};

export const ImageList: FC<ImageListProps> = (props) => {
  const { images, onDelete } = props;

  return (
    <div className={styles.imageList}>
      {images.map((image) => (
        <ImageListItem key={image.id} image={image} onDelete={onDelete} />
      ))}
    </div>
  );
};
